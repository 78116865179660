import React, { useRef, useState } from "react";
import TextFieldGroup from "../common/TextFieldGroup";
import { Formik } from "formik";
import { addHealthAndFitness } from "../../dataServices/Services";
import { useToasts } from "react-toast-notifications";
import { categoryValidation, descriptionValidation, titleValidation } from "../../constants/Constants";
const yup = require("yup");
const validationSchema = yup.object().shape({
    title: yup.string().trim().required("Title is required"),
    description: yup.string().trim().required("Description is required"),
    category: yup.string().trim().required("Category is required"),
});
function AddHealthAndFitness({ history }) {
    const [ loading, setLoading ] = useState(false);
    const { addToast } = useToasts()
    const toastRef = useRef()


    const createHealthData = async (values) => {
        try {
            setLoading(true);
            const resourceData = {
                title: values.title.trim(),
                description: values.description.trim(),
                category: values.category.trim()
            }

            const response = await addHealthAndFitness(resourceData);

            addToast(<span ref={toastRef}
                aria-label={response.data.success}
                tabIndex={0}>{response.data.success}</span>, {
                appearance: 'success',
                autoDismiss: true,
            })
            toastRef.current.focus()
            history.push("/health-and-fitness");

        } catch (error) {
            addToast(<span ref={toastRef}
                aria-label={error.message}
                tabIndex={0}>{error.message}</span>, {
                appearance: 'error',
                autoDismiss: true,
            })
            toastRef.current.focus()
        }


    }

    return (
        <div>
            <div className="container">
                <div>
                    <div className="col-md-6 m-auto"></div>{" "}
                    <Formik
                        validationSchema={validationSchema}
                        initialValues={{ title: "", description: "", category: "" }}
                        onSubmit={createHealthData}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => (
                            <div>
                                {loading ? (
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                ) : (
                                    <div>
                                        <label tabIndex={0} htmlFor="title">
                                            Title
                                        </label>
                                        <TextFieldGroup
                                            name="title"
                                            id="title"
                                            type="text"
                                            value={values.title}
                                            onChange={handleChange}
                                            error={titleValidation(touched, errors)}
                                        />
                                        <label tabIndex={0} htmlFor="description">
                                            Description
                                        </label>
                                        <textarea
                                            name="description"
                                            id="description"
                                            type="textArea"
                                            value={values.description}
                                            onChange={handleChange}
                                            className="form-control"
                                            style={descriptionValidation(touched, errors)}
                                        ></textarea>
                                        {touched.description && errors.description && (
                                            <p aria-label={touched.description && errors.description} tabIndex={0} style={{ fontSize: '13px', color: 'red' }}>{touched.description && errors.description}</p>
                                        )}
                                        <label tabIndex={0} htmlFor="category">
                                            Category
                                        </label>
                                        <TextFieldGroup
                                            name="category"
                                            id="category"
                                            type="text"
                                            value={values.category}
                                            onChange={handleChange}
                                            error={
categoryValidation(touched, errors)
                                            }
                                        />
                                        <button
                                            type="submit"
                                            onClick={(e) => {
                                                handleSubmit();
                                            }}
                                            className="btn btn-primary"
                                        >
                                            Create Health Resource
                                        </button>{" "}
                                        <button
                                            className="btn btn-primary m-2"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                history.push("/health-and-fitness");
                                            }}
                                        >
                                            Cancel{" "}
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}

export default AddHealthAndFitness;
