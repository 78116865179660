import React, { useRef, useState } from "react";
import TextFieldGroup from "../common/TextFieldGroup";
import { Formik } from "formik";
import { addEquipmentService } from "../../dataServices/Services";
import { useToasts } from "react-toast-notifications";
import { categoryValidation, descriptionValidation, titleValidation, urlValidation } from "../../constants/Constants";
const yup = require("yup");

function AddRecommendedEquipments({ history }) {
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts()
    const toastRef = useRef()


    const validationSchema = yup.object().shape({
        title: yup.string().trim().required("Title is required"),
        description: yup.string().trim().required("Description is required"),
        category: yup.string().trim().required("Category is required"),
        url: yup.string()
            .matches(
                /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig,
                'Enter correct url!'
            )
            .required("Url is required"),
    });


    const createRecommendedEquipments = async (values) => {
        try {
            setLoading(true);
            const resourceData = {
                title: values.title.trim(),
                description: values.description.trim(),
                category: values.category.trim(),
                url: values.url.trim()
            }

            const response = await addEquipmentService(resourceData);

            addToast(<span ref={toastRef}
                aria-label={response.data.success}
                tabIndex={0}>{response.data.success}</span>, {
                appearance: 'success',
                autoDismiss: true,
            })
            toastRef.current.focus()
            history.push("/recommended-equipments");

        } catch (error) {
            addToast(<span ref={toastRef}
                aria-label={error.message}
                tabIndex={0}>{error.message}</span>, {
                appearance: 'error',
                autoDismiss: true,
            })
            toastRef.current.focus()
        }
    }

    return (
        <div>
            <div className="container">
                <div>
                    <div className="col-md-6 m-auto"></div>{" "}
                    <Formik
                        validationSchema={validationSchema}
                        initialValues={{ title: "", description: "", category: "", url: "" }}
                        onSubmit={createRecommendedEquipments}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <div>
                                    {loading ? (
                                        <div class="spinner-border text-primary" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    ) : (
                                        <div>
                                            <label tabIndex={0} htmlFor="title">
                                                Title
                                            </label>
                                            <TextFieldGroup
                                                name="title"
                                                id="title"
                                                type="text"
                                                value={values.title}
                                                onChange={handleChange}
                                                error={titleValidation(touched, errors)}
                                            />
                                            <label tabIndex={0} htmlFor="description">
                                                Description
                                            </label>
                                            <textarea
                                                name="description"
                                                id="description"
                                                type="textArea"
                                                value={values.description}
                                                onChange={handleChange}
                                                className="form-control"
                                                style={descriptionValidation(touched, errors)}
                                            ></textarea>
                                            {touched.description && errors.description && (
                                                <p aria-label={touched.description && errors.description} tabIndex={0} style={{ fontSize: '13px', color: 'red' }}>{touched.description && errors.description}</p>
                                            )}
                                            <label tabIndex={0} htmlFor="category">
                                                Category
                                            </label>
                                            <TextFieldGroup
                                                name="category"
                                                id="category"
                                                type="text"
                                                value={values.category}
                                                onChange={handleChange}
                                                error={
                                                    categoryValidation(touched, errors)
                                                }
                                            />
                                            <label tabIndex={0} htmlFor="url">
                                                Url
                                            </label>
                                            <TextFieldGroup
                                                name="url"
                                                id="url"
                                                type="text"
                                                value={values.url}
                                                aria-invalid={errors.firstName && touched.firstName ? 'true' : null}
                                                aria-describedby={errors.firstName && touched.firstName ? 'firstName-error' : null}
                                                aria-required="true"
                                                onChange={handleChange}
                                                error={urlValidation(touched, errors)}
                                            />
                                            <button
                                                type="submit"
                                                onClick={(e) => {
                                                    handleSubmit();
                                                }}
                                                className="btn btn-primary"
                                            >
                                                Create Product
                                            </button>{" "}
                                            <button
                                                className="btn btn-primary m-2"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    history.push("/recommended-equipments");
                                                }}
                                            >
                                                Cancel{" "}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            )
                        }}
                    </Formik>
                </div>
            </div>
        </div >
    );
}

export default AddRecommendedEquipments;
