/* eslint-disable react-hooks/exhaustive-deps */
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons"
import React, { useEffect, useState } from 'react'
import FilterResults from 'react-filter-search'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import '../../assets/css/classrooms.css'
import { loadClassroomsService, reorderClassRooms } from '../../dataServices/Services'
import { getClassrooms } from '../../redux/actions/classActions'

import Axios from 'axios'
import { BASE_URL } from '../../constants/Constants'
function Classroom({ getClassrooms, classes, auth }) {
	const history = useHistory()
	const [ searchValue, setsearchValue ] = useState('')
	const [ searchData, setSearchData ] = useState([])
	const [ loading, setLoading ] = useState(false)
	const [ classrooms, setClassrooms ] = useState([])
	const [ categories, setCategories ] = useState([])
	const { addToast } = useToasts()
	const fetchClassrooms = async () => {
		setLoading(true)
		let token = localStorage.getItem('token')
		if (token !== null) {
			let r = await loadClassroomsService()

			if (r.data && r.data.classrooms) {
				getClassrooms(r.data.classrooms)

				setClassrooms(r.data.classrooms)
				setLoading(false)
			}
		} else {
			setLoading(false)
			addToast('Classrooms not loaded', { appearance: 'error' })
		}
	}
	const getCategory = () => {
		let cat = []
		for (const element of classrooms) {
			if (classrooms.length > 0) {
				if (cat.indexOf(element.category.toLowerCase()) === -1) {
					cat.push(element.category.toLowerCase())
				}
			}
		}

		setCategories(cat)
	}
	useEffect(() => {
		fetchClassrooms()
	}, [])
	useEffect(() => {
		getCategory()
	}, [ classrooms ])

	const reorderClasses = async (currentOrder, newOrder) => {
		const data = {
			currentOrder:classes.classrooms[currentOrder].order,
			newOrder:classes.classrooms[newOrder].order
		}
		await reorderClassRooms(data).then((data)=>{
			fetchClassrooms()
		})
	}

	return (
		<div>
			{' '}
			<div className="top-bar">
				{' '}
				<div className="top-bar-search">
					{' '}
					<input
						type="text"
						name=""
						id=""
						value={searchValue}
						placeholder="Search"
						aria-label="Search classroom name"
						onChange={(e) => {
							setsearchValue(e.target.value)
							setSearchData(classes.classrooms)
						}}
					/>{' '}
					<select
						name=""
						id=""
						aria-label="Search classroom by category "
						value={searchValue}
						onChange={(e) => {
							setsearchValue(e.target.value)
							setSearchData(classes.classrooms)
						}}
						style={{ height: 30 }}
					>
						{categories &&
							categories.map((cat) => <option value={cat}>{cat}</option>)}
					</select>
					{searchData && (
						<FilterResults
							value={searchValue}
							data={searchData}
							renderResults={(results) => (
								<div>
									{results.map((el) => (
										<div
											onClick={(e) => {
												history.push(`/classroom/${el._id}`)
												setSearchData([])
											}}
											onKeyPress={(e) => {
												history.push(`/classroom/${el._id}`)
												setSearchData([])
											}}
										>
											<div>
												<p
													tabIndex={0}
													style={{
														border: '1px solid ',
														textAlign: 'center',
														cursor: 'pointer',
													}}
												>
													{el.title} : Category {el.category}
												</p>
											</div>
										</div>
									))}
								</div>
							)}
						/>
					)}
				</div>
			</div>
			<div className="m-4 p-4">
				<h1 tabIndex={0} style={{ display: 'block' }} aria-label="Classrooms">
					Welcome into the Classroom
				</h1>{' '}
				<p tabIndex={0}>
					Here you can learn all you need to know about different exercises,
					positions, movement patterns, and more.
				</p>
				{auth.user.isTrainer && (
					<button
						onClick={(e) => {
							history.push('/addClassroom')
						}}
						className="btn btn-primary"
					>
						Add Classroom
					</button>
				)}
				<h2 tabIndex={0}>Classrooms</h2>
				<div className="p-4 m-2">
					<div
						tabIndex={0}
						aria-label="List of classrooms"
						className="list-group"
					>
						{loading && (
							<div
								class="spinner-border text-primary ml-auto mr-auto"
								role="status"
							>
								<span class="sr-only">Loading...</span>
							</div>
						)}
						{!loading &&
							classes &&
							!auth.user.isTrainer &&
							classes.classrooms.map((c) => {
								return (
									<button
										key={c._id}
										onClick={() => {
											history.push(`/classroom/${c._id}`)
										}}
										className="btn btn-primary m-2"
										style={{
											marginTop: '5px',
											display: 'flex',
											flexWrap: 'wrap',
										}}
										tabIndex={0}
									>
										{c.title}
									</button>
								)
							})}
						{classes &&
							auth.user.isTrainer &&
							classes.classrooms.map((c, index) => {
								return (
									<div
										className="text-align-center"
										style={{ display: 'flex' }}
									>
										{' '}
										<button
											key={c._id}
											onClick={() => {
												history.push(`/classroom/${c._id}`)
											}}
											className="btn btn-primary m-2"
											aria-label={`${c.title}-position ${index+1}`}
											style={{
												marginTop: '5px',
												width: '90%',
											}}
											tabIndex={0}
										>
											{c.title}
										</button>
										{auth.user.isTrainer && (
											<>
												{index !== 0 && (
													<button
														aria-label="Move Up The Classroom"
														className="btn btn-danger m-2"
													onClick={() => reorderClasses(index, index - 1)}
													>
														<ArrowUpOutlined />
													</button>
												)}
												{index !== (classes.classrooms.length - 1) && (
													<button
														aria-label="Move Down The Classroom"
														className="btn btn-danger m-2"
													onClick={() => reorderClasses(index, index + 1)}
													>
														<ArrowDownOutlined />
													</button>
												)}
												<button
													aria-label="delete classroom"
													className="btn btn-danger m-2"
													onClick={(e) => {
														let token = localStorage.getItem('token')
														let config = {
															headers: { Authorization: 'Bearer ' + token },
														}
														Axios.delete(
															`${BASE_URL}classrooms/${c._id}`,
															config
														).then((res) => {
															console.log(res)
															if (res.status === 200) {
																alert(res.data.message)
																fetchClassrooms()
															}
														})
													}}
												>
													<i className="fa fa-trash"></i>
												</button>
											</>
										)}
										{auth.user.isTrainer && (
											<button
												aria-label="Edit classroom"
												className="btn btn-danger m-2"
												onClick={(e) => {
													history.push(`/editClassroom/${c._id}`)
												}}
											>
												<i className="fa fa-pencil"></i>
											</button>
										)}
									</div>
								)
							})}
					</div>
				</div>
			</div>
		</div>
	)
}
const mapDispatchToProps = (dispatch) => {
	return {
		getClassrooms: (classrooms) => {
			dispatch(getClassrooms(classrooms))
		},
	}
}
const mapStateToProps = (state) => ({
	classes: state.classrooms,
	auth: state.auth,
})
export default connect(mapStateToProps, mapDispatchToProps)(Classroom)
