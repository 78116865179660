import React from 'react';
import { Form, Input } from 'antd';
import { useLocation, useHistory } from "react-router-dom";
import { updateCredits } from "../../dataServices/Services";
import { useToasts } from 'react-toast-notifications';
import { requiredField, requiredLength, placeHolder, status, errorConstants, successConstants } from "../../constants/Constants";
import "../../assets/css/credits.css";

const EditCredits = () => {
    const location = useLocation();
    const { state } = location;
    const { titleToShow, descriptionToShow, _id } = state.record;
    const history = useHistory();
    const { addToast } = useToasts();



    const onFinish = async (values) => {
        try {
            let requestData = {};

            for (const key in values) {
                requestData[key] = values[key];
            }

            await updateCredits(requestData, _id);
            addToast(successConstants.pushNotification.update, {
                appearance: status.success,
                autoDismiss: true,
            });
            history.goBack();
        } catch (error) {
            addToast(errorConstants.pushNotificationError, {
                appearance: status.error,
                autoDismiss: true,
            });
        }
    };
    const formItemLayout = {
        labelCol: {
            span: 24,
            style: { textAlign: 'center' },
        },
        wrapperCol: {
            span: 24,
        },
    };

    const initialValues = {
        title: titleToShow,
        description: descriptionToShow,
    };

    return (
        <Form onFinish={onFinish} initialValues={initialValues} layout="vertical" className='container' {...formItemLayout}>
            <h2 tabIndex={0}>Update Credit</h2>
            <Form.Item label={<span tabIndex={0}>Title</span>} name="title"
                rules={[{ required: true, message: requiredField.inputTitleRequired },
                { max: 50, message: requiredLength.titleLength }]}
            >
                <Input placeholder={placeHolder.inputTitle} tabIndex={0} />
            </Form.Item>

            <Form.Item label={<span tabIndex={0}>Description</span>} name="description"
                rules={[{ required: true, message: requiredField.inputDescriptionRequired },
                { max: 1000, message: requiredLength.messageLength }
                ]}>
                <Input.TextArea placeholder={placeHolder.inputDescription} tabIndex={0} />
            </Form.Item>
            <Form.Item>
                <button type="submit" className="btn btn-primary" tabIndex={0}>
                    Update Credit
                </button>

                <button type="button" className="btn btn-primary m-2" onClick={() => history.goBack()} tabIndex={0} onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                        e.preventDefault();
                        e.target.focus();
                        e.target.click();
                    }
                }}>
                    Cancel
                </button>
            </Form.Item>
        </Form>
    );
};

export default EditCredits;