import React, { useState } from "react";
import TextFieldGroup from "../common/TextFieldGroup";
import { Formik } from "formik";
import DropzoneInput from "../input/DropzoneInput";
import { addStudio } from "../../dataServices/Services";
import { categoryValidation, descriptionValidation, titleValidation } from "../../constants/Constants";
const yup = require("yup");
const validationSchema = yup.object().shape({
  title: yup.string().trim().required("Title is required"),
  description: yup.string().trim().required("Description is required"),
  category: yup.string().trim().required("Category is required"),
});
function AddStudio({ history }) {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  return (
    <div>
      <div className="container">
        <div>
          <div className="col-md-6 m-auto"></div>{" "}
          <Formik
            validationSchema={validationSchema}
            initialValues={{ title: "", description: "", category: "" }}
            onSubmit={async (values) => {
              
              let formData = new FormData();
              if (file) {
                setLoading(true);
                formData.append("title", values.title.trim());
                formData.append("description", values.description.trim());
                formData.append("category", values.category.trim());
                formData.append("audio", file);
                let r = await addStudio(formData);
                console.log(r);
                if (r.status === 200) {
                  setLoading(false);
                  alert(r.data.message);
                  history.push("/studios");
                } else {
                  alert("Studio not added successfully");
                  setLoading(false);
                }
              } else {
                setLoading(false);
                alert("Audio is required");
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <div>
                {loading ? (
                  <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  <div>
                    <label tabIndex={0} htmlFor="title">
                      Title
                    </label>
                    <TextFieldGroup
                      name="title"
                      id="title"
                      type="text"
                      value={values.title}
                      onChange={handleChange}
                      error={titleValidation(touched, errors)}
                    />
                    <label tabIndex={0} htmlFor="description">
                      Description
                    </label>
                    <textarea
                      name="description"
                      id="description"
                      type="textArea"
                      value={values.description}
                      onChange={handleChange}
                      className="form-control"
                      style={descriptionValidation(touched, errors)}
                    ></textarea>
                    <p style={{fontSize:'13px', color:'red'}}>{touched.description && errors.description}</p>
                    <label tabIndex={0} htmlFor="category">
                      Category
                    </label>
                    <TextFieldGroup
                      name="category"
                      id="category"
                      type="text"
                      value={values.category}
                      onChange={handleChange}
                      error={
categoryValidation(touched, errors)
                      }
                    />
                    <DropzoneInput
                      onDropComplete={(file) => {
                        console.log(file);
                        setFile(file);
                      }}
                    />
                    <button
                      type="submit"
                      onClick={(e) => {
                        handleSubmit();
                      }}
                      className="btn btn-primary m-2"
                    >
                      Create Studio
                    </button>
                    <button
                      className="btn btn-primary m-2"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("/studios");
                      }}
                    >
                      Cancel{" "}
                    </button>
                  </div>
                )}
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default AddStudio;
