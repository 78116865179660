import { combineReducers } from 'redux'
import classReducer from './classReducer'
import studioReducer from './studioReducer'
import authReducer from './authReducer'
import messageReducer from './messageReducer'
import planReducer from './planReducer'
import noteReducer from './noteReducer'
import healthResourceReducer from './healthResourceReducer'
import FaqReducer from './FaqReducer'
import equipmentsReducer from './equipmentsReducer'
export default combineReducers({
	classrooms: classReducer,
	studios: studioReducer,
	auth: authReducer,
	messages: messageReducer,
	plans: planReducer,
	note: noteReducer,
	healthResource: healthResourceReducer,
	faq: FaqReducer,
	equipments: equipmentsReducer
})
