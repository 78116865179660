import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import StripeCheckout from "react-stripe-checkout";
import {
  STRIPE_MODE,
  stripeLiveKey,
  stripeTestKey
} from "../../constants/Constants";
import {
  Subscribe,
  SubscribeAnnual,
  updateUserinfo,
} from "../../dataServices/Services";
import { logoutUserAction } from "../../redux/actions/authActions";
function NotSubscribed({ auth, logoutUserAction }) {
  const [ , setLoading ] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const onToken = async (token, subscriptionType) => {
    if (auth.user.subPlatform === "web" || auth.user.subPlatform === "none") {
      if (subscriptionType === "monthly") {
        Subscribe(token).then(async (r) => {
          if (r.data && r.data.status) {
            const subscription = r.data.subscription;
            const card = token.card;
            const response = await updateUserinfo(
              auth.user.email,
              subscription,
              card
            );
            if (response.data.user) {
              alert(response.data.message);
              window.location.href = "/dashboard";
            } else {
              alert("NETWORK ERROR");
            }
          } else {
            setLoading(false);
            alert("Subscription Failed ");
          }
        });
      } else if (subscriptionType === "yearly") {
        SubscribeAnnual(token).then(async (r) => {
          if (r.data && r.data.status) {
            const subscription = r.data.subscription;
            const card = token.card;
            const response = await updateUserinfo(
              auth.user.email,
              subscription,
              card
            );
            if (response.data.user) {
              alert(response.data.message);
              window.location.href = "/dashboard";
            } else {
              alert("NETWORK ERROR");
            }
          } else {
            setLoading(false);
            alert("Subscription Failed ");
          }
        });
      } else {
        alert("Please select subscription type");
      }
    } else {
      alert("You are not permitted to perform this operation as you are  subscribed from  another platform.");
    }

  };

  return (
    <div>
      <div className="container text-center mt-1 ">
        {/* {auth.user.subPlatform === "web" || auth.user.subPlatform === "none" ? ( */}
        <div>
          <h1 tabIndex={0} aria-label="Please subscribe to our program in order to user our services.
             Please do not refresh this page unless prompted. Thanks. " className="ml-2 mr-2">
            Please subscribe to our program in order to user our services.
            Please do not refresh this page unless prompted. Thanks. {" "}
          </h1>
          {location && (location?.state?.subPlan !== "month" && location?.state?.subPlan !== "monthly") &&
            <StripeCheckout
              stripeKey={STRIPE_MODE == 'live' ?
                stripeLiveKey :
                stripeLiveKey}
              email={auth.user?.email}
              image={require("../../assets/Logo1.png")}
              token={(token) => {
                onToken(token, "monthly");
              }}
            >
              <button
                className="btn btn-primary btn-block mt-4"
                aria-label="Subscribe Monthly"
              >
                Subscribe Monthly
              </button>
            </StripeCheckout>
          }
          {location && (location?.state?.subPlan !== "year" && location?.state?.subPlan !== "yearly") && (
            <StripeCheckout
              stripeKey={STRIPE_MODE == 'live' ?
                stripeLiveKey :
                stripeLiveKey}
              email={auth.user?.email}
              image={require("../../assets/Logo1.png")}
              token={(token) => {
                onToken(token, "yearly");
              }}
            >
              <button
                className="btn btn-primary btn-block mt-4"
                aria-label="Subscribe Yearly"
              >
                Subscribe Yearly
              </button>
            </StripeCheckout>
          )}

        </div>
        {/* ) : (
          <h1 className="ml-2 mr-2">{console.log('auth.user',auth.user)}
            Cancel subscription in {auth.user.subPlatform} to Subscribe in web.
          </h1>
        )} */}
        <br></br>
        <button
          className="btn btn-primary btn-block  "
          onClick={(e) => {
            e.preventDefault();
            localStorage.removeItem("token");
            logoutUserAction();
            history.push("/");
          }}
        >
          Sign Out
        </button>
        {auth.user?.isSubscribed && (
          <button
            className="btn btn-primary btn-block mt-4"
            aria-label="Go To Profile"
            onClick={() => history.push("/profile")}
          >
            Go To Profile
          </button>
        )}

      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUserAction: () => {
      dispatch(logoutUserAction());
    },
  };
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotSubscribed);
