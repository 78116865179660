/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import TextFieldGroup from "../common/TextFieldGroup";
import { Formik } from "formik";
import { useToasts } from "react-toast-notifications";
import DropzoneInput from "../input/DropzoneInput";
import { updateClassroom, loadSingleClassroom } from "../../dataServices/Services";
import { useParams } from "react-router-dom";
import { categoryValidation, titleValidation } from "../../constants/Constants";
const yup = require("yup");
const validationSchema = yup.object().shape({
  title: yup.string().trim().required("Title is required"),
  description: yup.string().trim().required("Description is required"),
  category: yup.string().trim().required("Category is required"),
});
function EditClassroom({ history }) {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [classroom, setClassroom] = useState({});
  const { id } = useParams();
  const { addToast } = useToasts();
  const fetchClassroom = async () => {
    let r = await loadSingleClassroom(id);
    console.log(id);
    console.log(r);
    if (r.status === 200) {
      setClassroom(r.data.classroom);
      console.log(r.data.classroom);
    }
  };
  useEffect(() => {
    fetchClassroom();
  }, []);
  return (
    <div>
      <div className="container">
        <div>
          <div className="col-md-6 m-auto"></div>{" "}
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={{
              title: classroom && classroom.title,
              description: classroom && classroom.description,
              category: classroom && classroom.category,
            }}
            onSubmit={async (values) => {
              setLoading(true);
              let formData = new FormData();
              if (file !== null) {
                console.log(values.title.trim())
                formData.append("title", values.title.trim());
                formData.append("description", values.description.trim());
                formData.append("category", values.category.trim());
                formData.append("audio", file);

                let r = await updateClassroom(formData, id);
                console.log(r);
                setLoading(false);
                if (r.status === 200) {
                  alert(r.data.message);
                  addToast(r.data.message, {
                    appearance: "success",
                    autoDismiss: true,
                  });
                }
              } else if (file === null) {
                console.log(values.title.trim())
                formData.append("title", values.title.trim());
                formData.append("description", values.description.trim());
                formData.append("category", values.category.trim());
                formData.append("audio", file);

                let r = await updateClassroom(formData, id);
                console.log(r);
                if (r.status === 200) {
                  alert(r.data.message);
                  addToast(r.data.message, {
                    appearance: "success",
                    autoDismiss: true,
                  });
                }
                setLoading(false);
              } else {
                setLoading(false);
                alert("Required data misisng");
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <div>
                {loading ? (
                  <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  <div>
                    <label tabIndex={0} htmlFor="title">
                      Title
                    </label>
                    <TextFieldGroup
                      name="title"
                      id="title"
                      type="text"
                      value={values.title}
                      onChange={handleChange}
                      error={titleValidation(touched, errors)}
                    />
                    <label tabIndex={0} htmlFor="description">
                      Description
                    </label>
                    <textarea
                      name="description"
                      id="description"
                      type="textArea"
                      value={values.description}
                      onChange={handleChange}
                      className="form-control"
                    ></textarea>
                    <label tabIndex={0} htmlFor="category">
                      Category
                    </label>
                    <TextFieldGroup
                      name="category"
                      id="category"
                      type="text"
                      value={values.category}
                      onChange={handleChange}
                      error={
categoryValidation(touched, errors)
                      }
                    />
                    <DropzoneInput
                      onDropComplete={(file) => {
                        console.log(file);
                        setFile(file);
                      }}
                    />
                    <button
                      type="submit"
                      onClick={(e) => {
                        handleSubmit();
                      }}
                      className="btn btn-primary m-2"
                    >
                      Update Classroom
                    </button>{" "}
                    <button
                      className="btn btn-primary m-2"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("/classrooms");
                      }}
                    >
                      Cancel{" "}
                    </button>
                  </div>
                )}
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default EditClassroom;
