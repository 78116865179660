import React, { useRef } from 'react';
import { Form, Input } from 'antd';
import { useHistory } from "react-router-dom";
import { addCredits } from "../../dataServices/Services";
import { useToasts } from "react-toast-notifications";
import { requiredField, requiredLength, placeHolder, status, errorConstants, successConstants } from "../../constants/Constants";
import "../../assets/css/credits.css";

const AddCredits = () => {
    const history = useHistory();
    const { addToast } = useToasts();
    const toastRef = useRef();

    const onFinish = async ({ title, description }) => {
        try {
            const requestData = {
                title: title,
                description: description,
            };

            await addCredits(requestData);
            const toastRef = React.createRef();
            addToast(
            <span
                ref={toastRef}
                aria-live="polite"
            >
                {successConstants.credit.add}
            </span>,
            {
                appearance: 'success',
                autoDismiss: true,
                onDismiss: () => {
                    toastRef.current.focus();
                }
            }
        );
            history.goBack();
        } catch (error) {
            addToast(errorConstants.creditError, {
                appearance: status.error,
                autoDismiss: true,
            });
        }
    };

    const formItemLayout = {
        labelCol: {
            span: 24,
            style: { textAlign: 'center' },
        },
        wrapperCol: {
            span: 24,
        },
    };


    return (
        <Form onFinish={onFinish} layout="vertical" className='container' {...formItemLayout}>
            <h2 tabIndex={0}>Add Credit</h2>
            <Form.Item label={<span tabIndex={0}>Title</span>} name="title"
                rules={[{ required: true, message: requiredField.inputTitleRequired },
                { max: 50, message: requiredLength.titleLength }]}
            >
                <Input placeholder={placeHolder.inputTitle} tabIndex={0} />
            </Form.Item>

            <Form.Item label={<span tabIndex={0}>Description</span>} name="description"
                rules={[{ required: true, message: requiredField.inputDescriptionRequired },
                { max: 1000, message: requiredLength.messageLength }
                ]}>
                <Input.TextArea placeholder={placeHolder.inputDescription} tabIndex={0} />
            </Form.Item>

            <Form.Item>
                <button type="submit" className="btn btn-primary" tabIndex={0}>
                    Add Credit
                </button>

                <button type="button" className="btn btn-primary m-2" onClick={() => history.goBack()} tabIndex={0} onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                        e.preventDefault();
                        e.target.focus();
                        e.target.click();
                    }
                }}>
                    Cancel
                </button>
            </Form.Item>
        </Form>
    );
};

export default AddCredits;