import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { LeftOutlined } from '@ant-design/icons';
import moment from 'moment';
import "../../assets/css/pushNotification.css";

const ViewPushNotification = () => {
    const { state: { record } } = useLocation();
    const { title, message, platform, dateAndTime } = record;
    const history = useHistory();
    const [showFull, setShowFull] = useState(false);
    const messageToShow = showFull ? message : message.slice(0, 500) + (showFull ? "..." : "");

    const toggleShowFull = () => {
        setShowFull(!showFull);
    };

    return (
        <>
            <h2 tabIndex={0}>View Push Notification</h2>
            <div className="d-flex justify-content-center m-2">
                <div className="card cardBlock" tabIndex={0}>
                    <div className="card-body">
                        <div className="card-title">
                            <h5 tabIndex={0}>Title</h5>
                        </div>
                        <p tabIndex={0}>{title}</p>
                        <h5 tabIndex={0}>Message</h5>
                        <p className="card-text" tabIndex={0}>{messageToShow}</p>
                        {message.length > 500 && (
                            <button
                                className="btn btn-link"
                                type="button"
                                onClick={toggleShowFull}
                                tabIndex={0}
                                aria-label={showFull ? "Read Less" : "Read More"}
                            >
                                {showFull ? 'Read Less' : 'Read More'}
                            </button>
                        )}
                        <h5 tabIndex={0}>Platform</h5>
                        <p tabIndex={0}>{platform.join(", ")}</p>
                        <h5 tabIndex={0}>Date and Time</h5>
                        <p tabIndex={0}>{moment(dateAndTime).format('DD-MM-YYYY hh:mm:ss A')}</p>
                    </div>
                </div>
            </div>
            <button type="button" className="btn btn-primary m-2" onClick={() => history.goBack()} tabIndex={0}>
                <LeftOutlined /> Back
            </button>
        </>
    );
}

export default ViewPushNotification;
