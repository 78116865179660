/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { userVerification } from "../../dataServices/Services";
import { useHistory, useParams } from "react-router-dom";

const UserVerification = () => {
    const params = useParams()
    const textFocus = useRef();
    const history = useHistory()
    const [ isUserAlreadyVerified, setIsUserAlreadyVerified ] = useState(false)
    const [ isUserVerified, setIsUserVerified ] = useState(false)

    useEffect(() => {
        verifyUser()
    }, [])

    const verifyUser = async () => {
        try {
            const response = await userVerification(params.userId);
            if (response.status === 200) {
                setIsUserVerified(true)
                textFocus.current.focus();
                setTimeout(() => {
                    history.push("/login")
                }, 10000);
            } else {
                setIsUserAlreadyVerified(true)
                textFocus.current.focus();
            }

        } catch (error) {
            console.log({ error })
            setIsUserAlreadyVerified(true)
            textFocus.current.focus();
        }

    }

    const redirectToLogin = () => {
        history.push("/login")
    }

    return (
        <>

            {isUserAlreadyVerified && (
                <>
                    <span ref={textFocus} aria-label="User is already verified. Please click on the below login button to continue"
                        tabIndex={-1}>
                        User is already verified. Please click on the below login button to continue
                    </span><br />
                    <button
                        className="btn btn-danger"
                        aria-label="Click the here redirect to login page"
                        onClick={redirectToLogin}
                    >
                        Login
                    </button>
                </>
            )}
            {isUserVerified &&
                <>
                    <span ref={textFocus} aria-label="User is Verified and will be redirected after 10 seconds or the user can click the below link to redirect to login page"
                        tabIndex={-1}>
                        User is Verified and will be redirected after 10 seconds or the user can click the below link to redirect to login page
                    </span><br />
                    <button
                        className="btn btn-danger"
                        aria-label="Click the here redirect to login page"
                        onClick={redirectToLogin}
                    >
                        Login
                    </button>
                </>
            }
        </>
    );
}
export default UserVerification;
