/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import TextFieldGroup from "../common/TextFieldGroup";
import { Formik } from "formik";
import { useToasts } from "react-toast-notifications";
import { loadSingleEquipment, updateEquipments } from "../../dataServices/Services";
import { useParams } from "react-router-dom";
import { categoryValidation, descriptionValidation, titleValidation } from "../../constants/Constants";
const yup = require("yup");
const validationSchema = yup.object().shape({
    title: yup.string().trim().required("Title is required"),
    description: yup.string().trim().required("Description is required"),
    category: yup.string().trim().required("Category is required"),
    url: yup.string()
        .matches(
            /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig,
            'Enter correct url!'
        )
        .required('Please enter url'),
});
function EditEquipments({ history }) {
    const [ loading, setLoading ] = useState(false);
    const [ resource, setResource ] = useState({});
    const { id } = useParams();
    const { addToast } = useToasts();
    const toastRef = useRef();

    const fetchEquipments = async () => {
        let r = await loadSingleEquipment(id);
        if (r.status === 200) {
            setResource(r.data.resourceData);
        }
    };

    useEffect(() => {
        fetchEquipments();
    }, []);



    return (
        <div>
            <div className="container">
                <div>
                    <div className="col-md-6 m-auto"></div>{" "}
                    <Formik
                        enableReinitialize
                        validationSchema={validationSchema}
                        initialValues={{
                            title: resource && resource.title,
                            description: resource && resource.description,
                            category: resource && resource.category,
                            url: resource && resource.url
                        }}
                        onSubmit={async (values) => {
                            setLoading(true);
                            const resourceData = {
                                title: values.title.trim(),
                                description: values.description.trim(),
                                category: values.category.trim(),
                                url: values.url.trim()
                            }
                            let response = await updateEquipments(resourceData, id);
                            setLoading(false);
                            if (response.status === 200) {
                                addToast(<span ref={toastRef}
                                    aria-label={response.data.success}
                                    tabIndex={0}>{response.data.success}</span>, {
                                    appearance: 'success',
                                    autoDismiss: true,
                                })
                                toastRef.current.focus()
                            }
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => (
                            <div>
                                {loading ? (
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                ) : (
                                    <div>
                                        <label tabIndex={0} htmlFor="title">
                                            Title
                                        </label>
                                        <TextFieldGroup
                                            name="title"
                                            id="title"
                                            type="text"
                                            value={values.title}
                                            onChange={handleChange}
                                            error={titleValidation(touched, errors)}
                                        />
                                        <label tabIndex={0} htmlFor="description">
                                            Description
                                        </label>
                                        <textarea
                                            name="description"
                                            id="description"
                                            type="textArea"
                                            value={values.description}
                                            onChange={handleChange}
                                            className="form-control"
                                            style={descriptionValidation(touched, errors)}
                                        ></textarea>
                                        {touched.description && errors.description && (
                                            <p tabIndex={0} aria-label={touched.description && errors.description} style={{ fontSize: '13px', color: 'red' }}>{touched.description && errors.description}</p>

                                        )}
                                        <label tabIndex={0} htmlFor="category">
                                            Category
                                        </label>
                                        <TextFieldGroup
                                            name="category"
                                            id="category"
                                            type="text"
                                            value={values.category}
                                            onChange={handleChange}
                                            error={
categoryValidation(touched, errors)
                                            }
                                        />
                                        <label tabIndex={0} htmlFor="url">
                                            Url
                                        </label>
                                        <TextFieldGroup
                                            name="url"
                                            id="url"
                                            type="text"
                                            value={values.url}
                                            onChange={handleChange}
                                            error={
                                                touched.url && errors.url
                                                    ? errors.url
                                                    : ""
                                            }
                                        />
                                        <button
                                            type="submit"
                                            onClick={(e) => {
                                                handleSubmit();
                                            }}
                                            className="btn btn-primary m-2"
                                        >
                                            Update Health Resource
                                        </button>{" "}
                                        <button
                                            className="btn btn-primary m-2"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                history.push("/recommended-equipments");
                                            }}
                                        >
                                            Cancel{" "}
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}

export default EditEquipments;
