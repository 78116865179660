import React from 'react'

function SingleComment(props) {
	function getYear(fulldatestring) {
		var date = new Date(fulldatestring)

		return (
			date.getFullYear() +
			'-' +
			0 +
			parseInt(date.getMonth() + 1) +
			'-' +
			date.getDate()
		)
	}

	function getTime(fulldateString) {
		var date = new Date(fulldateString)
		return date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds()
	}

	return (
		<div className="border solid m-1">
			{' '}
			<div className="d-flex justify-content-left pl-2">
				<h5
					tabIndex={0}
					aria-label={`Comment By ${props.name}`}
					style={{ fontStyle: 'italic' }}
				>
					{' '}
					{props.name}
				</h5>

				<p
					tabIndex={0}
					aria-label={`Comment Created At ${getYear(props.time)} - ${getTime(
						props.time
					)}`}
					style={{ fontSize: '10px', marginTop: '10px', marginLeft: '10px' }}
				>
					{' '}
					{getYear(props.time)} - {getTime(props.time)}
				</p>
			</div>
			<div className="d-flex justify-content-left pl-2">
				<p
					tabIndex={0}
					aria-label={`Text of the comment ${props.text}`}
					style={{ fontSize: '14px' }}
				>
					{' '}
					{props.text}
				</p>
			</div>{' '}
			{(props.userId === props.commentUserId || props.isTrainer) && (
				<div className=" align-right">
					{' '}
					<button
						tabIndex={0}
						aria-label="Remove comment By clicking this "
						className="btn btn-secondary"
						onClick={() => {
							props.delete(props.classId, props.commentId)
						}}
					>
						Delete
					</button>
				</div>
			)}
		</div>
	)
}

export default SingleComment
