import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "antd/dist/antd.css";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { loadUser, loadAllMessagesService } from "./dataServices/Services";
import { loadUserAction } from "./redux/actions/authActions";
import { loadMessageAction } from "./redux/actions/messageActions";
import store from "./redux/store";
import { NETWORK_ERROR } from "./constants/Constants";
(async () => {
  let token = localStorage.getItem("token");

  if (token !== null) {
    let r = await loadUser(token);
    if (r !== NETWORK_ERROR) {
      store.dispatch(loadUserAction(r.data.user));
    }
  }
  let r = await loadAllMessagesService();
  if (r.status === 200) {
    store.dispatch(loadMessageAction(r.data.messages));
  }
})();
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
