import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import Navbar from "./layout/Navbar";
import Landing from "./layout/Landing";
import Footer from "./layout/Footer";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import Dashboard from "./components/dashboard/Dashboard";
import Profile from "./components/pages/Profile";
import Classroom from "./components/pages/Classroom";
import SingleClassroom from "./components/pages/SingleClassroom";
import store from "./redux/store";
import { Provider } from "react-redux";
import PrivateRoute from "./components/common/PrivateRoute";
import PrivateRouteAdmin from "./components/common/PrivateRoutesAdmin";
import { ToastProvider } from "react-toast-notifications";
import Studios from "./components/pages/Studios";
import SingleStudio from "./components/pages/SingleStudio";
import Favorites from "./components/pages/Favorites";
import MessageBoard from "./components/pages/MessageBoard";
import Privacypolicy from "./components/pages/Privacypolicy";
import TermsConditions from "./components/pages/TermsConditions";
import Disclainer from "./components/pages/Disclaimer";
import AddClassrooms from "./components/adminPages/AddClassrooms";
import AdminDashBoard from "./components/adminPages/AdminDashBoard";
import AddFitnessPlan from "./components/adminPages/AddFitnessPlan";
import EditClassroom from "./components/adminPages/EditClassroom";
import AddStudio from "./components/adminPages/AddStudio";
import EditStudio from "./components/adminPages/EditStudio";
import NotSubscribed from "./components/pages/NotSubscribed";
import FitnessPlan from "./components/pages/FitnessPlan";
import EditFitnessPlan from "./components/adminPages/EditFitnessPlan";
import SingleFitnessPlan from "./components/pages/SingleFitnessPlan";
import AddEnteries from "./components/adminPages/AddEnteries";
import EditEntery from "./components/adminPages/Editentry";
import EditDay from "./components/adminPages/EditDay";
import AddDay from "./components/adminPages/AddDay";
import Contact from "./components/pages/Contact";
import ForgetPassword from "./components/pages/ForgetPassword";
import NewPassword from "./components/pages/NewPassword";
import Users from "./components/adminPages/Users";
import UserVerification from "./components/pages/UserVerification";
import HealthAndFitness from "./components/pages/HealthAndFitness";
import AddHealthAndFitness from "./components/adminPages/AddHealthAndFitness";
import EditHealthResouce from "./components/pages/EditHealthResource";
import SingleHealthAndFitness from "./components/pages/SingleHealthAndFitness";
import Faq from "./components/pages/Faq";
import AddFaqCategory from "./components/adminPages/AddFaqCategory";
import EditFaqCategory from "./components/adminPages/EditFaqCategory";
import FaqCategory from "./components/pages/FaqCategory";
import AddFaqQuestion from "./components/adminPages/AddFaqQuestion";
import EditFaqQuestion from "./components/adminPages/EditFaqQuestion";
import FaqSingleQuestion from "./components/pages/FaqSingleQuestion";
import RecommendedEquipments from "./components/pages/RecommendedEquipments";
import AddRecommendedEquipments from "./components/adminPages/AddRecommendedEquipments";
import EditEquipments from "./components/pages/EditEquipments";
import SingleEquipments from "./components/pages/SingleEquipments";
import Credits from "./components/adminPages/Credits";
import AddCredits from "./components/adminPages/addCredits";
import ViewCredits from "./components/adminPages/viewCredits";
import EditCredits from "./components/adminPages/EditCredits";
import AddPushNotication from "./components/adminPages/AddPushNotifcation";
import PushNotification from "./components/adminPages/PushNotification";
import ViewPushNotification from "./components/adminPages/ViewPushNotification";
import EditPushNotification from "./components/adminPages/EditPushNotification";
import Routes from "./constants/routes";
function App() {
  return (
    <Provider store={store}>
      <ToastProvider>
        {" "}
        <Router>
          <div className="App">
            <Navbar />

            <div style={{ minHeight: "80vh" }}>
              <ToastProvider autoDismissTimeout={15000}>
                <Route exact path="/register" component={Register} />
              </ToastProvider>
              <Route
                exact
                path="/terms-conditions"
                component={TermsConditions}
              />
              <Route exact path="/contact-us" component={Contact} />
              <Route exact path="/" component={Landing} />
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/forget-password" component={ForgetPassword} />
              <Route
                exate
                path="/new-password"
                component={NewPassword}
              />
              <PrivateRoute exact path="/profile" component={Profile} />
              <PrivateRoute exact path="/classrooms" component={Classroom} />
              <PrivateRoute
                exact
                path="/classroom/:id"
                component={SingleClassroom}
              />
              <PrivateRoute exact path="/favorites" component={Favorites} />
              <PrivateRoute
                exact
                path="/studios/:id"
                component={SingleStudio}
              />

              <PrivateRoute
                exact
                path="/health-resource/:id"
                component={SingleHealthAndFitness}
              />
              <PrivateRoute
                exact
                path="/recommended-equipments/:id"
                component={SingleEquipments}
              />

              <PrivateRoute exact path="/studios" component={Studios} />
              <PrivateRoute exact path="/health-and-fitness" component={HealthAndFitness} />
              <PrivateRoute exact path="/add-health-and-fitness" component={AddHealthAndFitness} />

              <PrivateRoute exact path="/faq" component={Faq} />
              <PrivateRoute exact path="/faq-category/:id" component={FaqCategory} />

              <PrivateRoute exact path="/add-faq-category" component={AddFaqCategory} />
              <PrivateRoute exact path="/edit-faq-category/:id" component={EditFaqCategory} />
              <PrivateRoute exact path="/faq-category/:id/add-question" component={AddFaqQuestion} />
              <PrivateRoute exact path="/faq-category/:id/edit-question/:questionId" component={EditFaqQuestion} />
              <PrivateRoute exact path="/faq-category/:id/question" component={FaqSingleQuestion} />

              <PrivateRoute exact path="/recommended-equipments" component={RecommendedEquipments} />
              <PrivateRoute exact path="/add-recommended-equipments" component={AddRecommendedEquipments} />
              <PrivateRoute
                exact
                path="/editEquipments/:id"
                component={EditEquipments}
              />

              <PrivateRoute
                exact
                path="/message-board"
                component={MessageBoard}
              />
              <PrivateRoute
                exact
                path="/fitness-plans"
                component={FitnessPlan}
              />{" "}
              <PrivateRoute
                exact
                path="/single-plan/:id"
                component={SingleFitnessPlan}
              />
              <PrivateRouteAdmin
                exact
                path="/adminDashboard"
                component={AdminDashBoard}
              />
              <PrivateRouteAdmin
                exact
                path="/addClassroom"
                component={AddClassrooms}
              />{" "}
              <PrivateRouteAdmin
                exact
                path="/editClassroom/:id"
                component={EditClassroom}
              />{" "}

              <PrivateRouteAdmin
                exact
                path="/editHealthResource/:id"
                component={EditHealthResouce}
              />{" "}
              <PrivateRouteAdmin
                exact
                path="/addStudio"
                component={AddStudio}
              />{" "}
              <PrivateRouteAdmin
                exact
                path="/add-fitness-plan"
                component={AddFitnessPlan}
              />{" "}
              <PrivateRouteAdmin
                exact
                path="/editStudio/:id"
                component={EditStudio}
              />{" "}
              <PrivateRoute exact path={Routes.credits} component={Credits} />{" "}
              <PrivateRouteAdmin exact path={Routes.addCredits} component={AddCredits} />{" "}
              <PrivateRoute exact path={Routes.viewCredits} component={ViewCredits} />{" "}
              <PrivateRouteAdmin exact path={Routes.editCredits} component={EditCredits} />{" "}
              <PrivateRouteAdmin exact path={Routes.addPushNotification} component={AddPushNotication} />
              <PrivateRouteAdmin exact path={Routes.pushNotification} component={PushNotification} />{" "}
              <PrivateRouteAdmin exact path={Routes.viewPushNotification} component={ViewPushNotification} />{" "}
              <PrivateRouteAdmin exact path={Routes.editPushNotification} component={EditPushNotification} />
              <PrivateRouteAdmin exact path="/users" component={Users} />{" "}

              <PrivateRouteAdmin
                exact
                path="/edit-plan/:id"
                component={EditFitnessPlan}
              />
              <PrivateRouteAdmin
                exact
                path="/add-enteries/:id"
                component={AddEnteries}
              />
              <PrivateRouteAdmin
                exact
                path="/edit-enteries/:pid/:eid"
                component={EditEntery}
              />
              <PrivateRouteAdmin
                exact
                path="/edit-day/:pid/:eid/:did"
                component={EditDay}
              />
              <PrivateRouteAdmin
                exact
                path="/add-day/:pid/:eid"
                component={AddDay}
              />
              <Route exact path="/privacyPolicy" component={Privacypolicy} />
              <Route exact path="/disclaimer" component={Disclainer} />
              <Route exact path="/not-subscribed" component={NotSubscribed} />
              <Route exact path="/verify-user/:userId" component={UserVerification} />
            </div>
            <Footer />
          </div>
        </Router>
      </ToastProvider>
    </Provider>
  );
}

export default App;
