/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import '../../assets/css/classrooms.css'


function FaqSingleQuestion() {
    const location = useLocation();
    const { question } = location.state
    const { answer } = location.state
    const history = useHistory();
    const { id } = useParams()

    return (
        <div>

            <div className="m-4 p-4">
                <h1 tabIndex={0} aria-label={question} style={{ display: 'block' }}>
                    {question}
                </h1>{' '}
                <p tabIndex={0} aria-label={answer}>
                    {answer}
                </p>
                <button
                    onClick={(e) => {
                        history.push(`/faq-category/${id}`)
                    }}
                    className="btn btn-primary"
                    style={{ marginLeft: "10px" }}
                >
                    Go back to FAQ list
                </button>
            </div>
        </div>
    )
}

export default FaqSingleQuestion
