import React, { useState, useEffect } from 'react'
import TextFieldGroup from '../common/TextFieldGroup'
import { Formik } from 'formik'
import {
	loadClassroomsService,
	loadStudiosService,
	updateSingleDay,
	loadSinglePlan
} from '../../dataServices/Services'
import { useParams } from 'react-router-dom'
import Select from 'react-select'
import { connect } from 'react-redux'
import { dayLabelValidation } from '../../constants/Constants'
const yup = require('yup')
const validationSchema = yup.object().shape({
	daylabel: yup.string().required('Day label is required'),
})
function EditDay({ history, plans }) {
	const { pid, eid, did } = useParams()
	const [ loading, setLoading ] = useState(false)
	const [ day, setDay ] = useState()

	const [ classroomlist, setClassRoomList ] = useState([])
	const [ selectedClass, setSelectedClass ] = useState([])
	const [ studiolist, setStudioList ] = useState([])
	const [ selectedStudio, setSelectedStudio ] = useState([])

	const [ classRooms, setClassrooms ] = useState([])
	const [ studios, setStudios ] = useState([])

	const fetchDay = async () => {
		const r = await loadSinglePlan(pid)
		console.log(r)
		let day = r.data.plan

		day = day.enteries.filter((en) => en._id === eid)

		//day = [...day[0]]
		day = day.map((da) => da.days.filter((d) => d._id === did))
		console.log(day)
		let d = [ ...day[ 0 ] ]
		d = d[ 0 ]
		setDay(d)
		let cl = [ ...classRooms ]
		let sc = [ ...selectedClass ]
		let st = [ ...studios ]
		let ss = [ ...selectedStudio ]
		for (const element of d.attachedClassRoom) {
			cl.push({
				value: element.id,
				label: element.label,
			})
			sc.push(element.id)
		}
		for (const element of d.attachedStudio) {
			st.push({
				value: element.id,
				label: element.label,
			})
			ss.push(element.id)
		}
		setClassrooms(cl)
		setSelectedClass(sc)
		setStudios(st)
		setSelectedStudio(ss)
	}

	const fetchClassrooms = async () => {
		const r = await loadClassroomsService()

		let l = []
		for (const element of r.data.classrooms) {
			l.push({
				value: element._id,
				label: element.title,
			})
		}

		if (r.status === 200) {
			setClassRoomList(l)
		} else {
			alert('Network Error')
		}
	}
	const fetchStudios = async () => {
		const r = await loadStudiosService()
		console.log(r)
		let l = []

		for (const element of r.data.studios) {
			l.push({
				value: element._id,
				label: element.title,
			})
		}

		if (r.status === 200) {
			setStudioList(l)
		} else {
			alert('Network Error')
		}
	}

	useEffect(() => {
		fetchDay()
		fetchStudios()
		fetchClassrooms()
	}, [ fetchDay ])

	const classhandler = (e) => {
		setSelectedClass(Array.isArray(e) ? e.map((x) => x.value) : [])
		setClassrooms(
			Array.isArray(e) ? e.map((x) => ({ id: x.value, label: x.label })) : []
		)
	}
	const studiohandler = (e) => {
		setSelectedStudio(Array.isArray(e) ? e.map((x) => x.value) : [])
		setStudios(
			Array.isArray(e) ? e.map((x) => ({ id: x.value, label: x.label })) : []
		)
	}

	return (
		<div>
			<div className="container">
				<div>
					<div className="col-md-6 m-auto"></div>{' '}
					<Formik
						validationSchema={validationSchema}
						enableReinitialize={true}
						initialValues={{
							daylabel: day ? day.label : '',
						}}
						onSubmit={async (values) => {
							setLoading(true)

							const { daylabel } = values
							console.log(daylabel)
							let r = await updateSingleDay(pid, eid, did, {
								label: daylabel,
								attachedClassRoom: classRooms,
								attachedStudio: studios,
							})
							console.log(r)
							if (r.status === 200) {
								alert(r.data.message)
								setLoading(false)
							} else {
								setLoading(false)
								alert('Network Error')
							}
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
						}) => (
							<div>
								{loading ? (
									<div class="spinner-border text-primary" role="status">
										<span class="sr-only">Loading...</span>
									</div>
								) : (
									<div>
										<div>
											<label tabIndex={0} htmlFor="Daylabel">
												Day Label
											</label>

											<TextFieldGroup
												name="Day Label"
												id="Daylabel"
												type="text"
												value={values.daylabel}
												onChange={handleChange('daylabel')}
												onBlur={handleBlur('daylabel')}
												error={dayLabelValidation(touched, errors)}
											/>

											<Select
												tabIndex={0}
												label="Select Classrooms"
												className="dropdown"
												placeholder="Select Class Rooms"
												value={classroomlist.filter((obj) =>
													selectedClass.includes(obj.value)
												)} // set selected values
												options={classroomlist} // set list of the data
												onChange={classhandler} // assign onChange function
												isMulti
												isClearable
												aria-label="edit classRooms"
											/>
											<br></br>
											<Select
												tabIndex={0}
												label="Select Studios"
												className="dropdown"
												placeholder="Select Studios"
												value={studiolist.filter((obj) =>
													selectedStudio.includes(obj.value)
												)} // set selected values
												options={studiolist} // set list of the data
												onChange={studiohandler} // assign onChange function
												isMulti
												isClearable
												aria-label="edit Studios"
											/>
										</div>
										<br></br>

										<button
											type="submit"
											onClick={(e) => {
												handleSubmit()
											}}
											className="btn btn-primary m-2"
										>
											Update Entery
										</button>
										<button
											className="btn btn-primary m-2"
											onClick={(e) => {
												e.preventDefault()
												history.push(`/single-plan/${pid}`)
											}}
										>
											Cancel{' '}
										</button>
									</div>
								)}
							</div>
						)}
					</Formik>
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		plans: state.plans,
	}
}
export default connect(mapStateToProps)(EditDay)
