export const BASE_URL = process.env.REACT_APP_API_URL;
export const STRIPE_MODE = process.env.STRIPE_MODE;
export const stripeLiveKey = "pk_live_51HVGlZFm9N2WPB4VS6wpDQP9QGhkxzbDNWprT1rcF23I0Llk3E6ob3W1rdVwyJJIXX8fvXeJOFf8fkoYqQpuiLYc00Qr7xZ94M"
export const stripeTestKey = "pk_test_51HVamyKftvTqLtiJZOlH20CkBf2cAVSS3YPYq7fztKC9abMnSzDO4oe7JonzqHHu7aiGyYgeOZZF7u81tor3GvRy00g7U6k7wA"
// export const BASE_URL = 'http://localhost:5000/api/'

//http://ec2-3-131-119-162.us-east-2.compute.amazonaws.com:5000/api/
export const LOAD_USER_URL = "users/loaduser";
export const CREATE_ACCOUNT_URL = "users/createaccount";
export const LOGIN_URL = "users/login";
export const NETWORK_ERROR = "NETWORK_ERROR";
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS";
export const LOGOUT_USER = "LOGOUT_USER";
export const DateFormat = "MM/DD/YYYY h:mm a z";
//http://ec2-18-222-223-124.us-east-2.compute.amazonaws.com:5000/
//https://revision-backend.herokuapp.com
export const audioUrl =
  "http://ec2-3-131-119-162.us-east-2.compute.amazonaws.com:5000/";
export const LOAD_MESSAGES_SUCCESS = "LOAD_MESSAGES_SUCCESS";
export const videoUrl = "https://revision-audios.s3.us-east-2.amazonaws.com/ReVision+Fitness_Compressed_1.MOV"

export const emailTemplate = `<div>
<div>Thank you for joining the ReVision Fitness Program and starting your 14 day Free Trial. We are so glad that you have chosen to make fitness a priority, and we look forward to taking this journey with you.</div>
<div>You can login after going to our site at this link…</div>
https://www.revisionfitnessapp.com 
<div>Here are a couple of tips that will enhance your use of the ReVision Fitness Program.</div>
<ol>
<li>The layout of the platform is designed to be simple and functional. The Dashboard for quick access and updates, the classroom for learning new exercises, and the fitness studio to get your fitness going with our audio workouts.  Some of the functions and features are still under construction, so check back often for updates and new content.</li>
<li>We are working hard to make this the most accessible and informative platform for fitness you have ever worked with.  We would love to have your help as this resource grows.  If you find any technical issues with the platform, send us an email at support@revisionfitnessapp.com.  If you have any suggestions as to how we can improve the platform and its content, you can send an email to feedback@revisionfitnessapp.com.</li>
<li>A great place to start is the classroom section with our Foundational Positions and Movements lessons.  These lessons will teach you proper positioning, movement patterns, terminology and cues that will be used throughout the rest of the program.</li>
<li>A quick crash course.  Each classroom lesson will contain a page of text if you want to read the lesson yourself, or an audio file where I will read the same lesson for you.  Each Fitness Studio Session begins with text describing the workout and the exercises included and has two main sections, an introduction and the workout list.  The introduction track will be a basic description of the workout and the exercises.  The Workout tracks will be divided into beginner or advanced, and with music or without, all your choice.</li>
</ol>
<div>So get your fitness attire, grab yourself some water, and let’s get fit!</div>
<div>Tyler Merren</div>
<div>Owner of ReVision Training LLC</div>
<div>“Let’s Change The Way We Look At It!”</div>
</div>`;

export const cancelEmailTemplate = `<div>
<div>Subscription cancelled successfully</div>
https://www.revisionfitnessapp.com 
<div>So get your fitness attire, grab yourself some water, and let’s get fit!</div>
<div>Tyler Merren</div>
<div>Owner of ReVision Training LLC</div>
<div>“Let’s Change The Way We Look At It!”</div>
</div>`;

export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,20}$/;
export const dayLabelValidation = (touched, errors) => touched.daylabel && errors.daylabel ? errors.daylabel : ''
export const titleValidation = (touched, errors) => touched.title && errors.title ? errors.title : ''
export const descriptionValidation = (touched, errors) => touched.description && errors.description ? { border: '1px solid red' } : {}
export const categoryValidation = (touched, errors) => touched.category && errors.category ? errors.category : ""
export const questionValidation = (touched, errors) => touched.question && errors.question ? errors.question : ''
export const answerValidation = (touched, errors) => touched.description && errors.description ? { border: '1px solid red' } : {}
export const urlValidation = (touched, errors) => touched.url && errors.url ? errors.url : ""


export const requiredField = {
  inputMessageRequired: 'Please input the message!',
  inputTitleRequired: 'Please input the title!',
  inputPlatformRequired: 'Please select the Platform',
  inputDateRequired: 'Please select a date!',
  inputDescriptionRequired: 'Please input the description!',
};

export const requiredLength = {
  titleLength: 'Title must not be more than 50 characters!',
  messageLength: 'message must not ne more than 1000 characters!',
  descriptionLength: 'description must not ne more than 1000 characters!',
};

export const placeHolder = {
  inputTitle: 'Please Enter the Title',
  inputMessage: 'Please Enter the Message',
  inputPlatform: 'Please Select the Platform',
  inputDate: 'Please Select Date and Time',
  inputDescription: 'Please Enter the description',
};

export const selectPlatform = [
  { value: 'android', label: 'Android',ariaLabel: '1. Android platform' },
  { value: 'ios', label: 'IOS', ariaLabel: '2. iOS platform' },
  { value: 'both', label: 'Both', ariaLabel: '3. Both platforms' },
];

export const platformNames = {
  android: 'android',
  ios: 'ios',
  both: 'both'
};

export const errorConstants = {
  pushNotificationAddError: "An error occurred while adding push notification",
  pushNotificationError: "An error occured while fetching data",
  creditAddError: "An error occurred while adding credits",
  creditError: "An error occured while fetching data"
};

export const successConstants = {
  pushNotification: {
    add: "Successfully Added Notification",
    update: "Successfully Updated Notification",
    delete: "SuccessFully Deleted Notification"
  },
  credit: {
    add: "Successfully Added Credits",
    update: "Successfully Updated Credits",
    delete: "SuccessFully Deleted Credits"
  }
};

export const deleteBody = {
  title: 'Are you sure?',
  message: 'Once deleted it cannot be retained'
}

export const status = {
  success: "success",
  error: "error"
};