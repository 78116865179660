import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import TableFooter from '@material-ui/core/TableFooter'
import { deleteSingleEntry, deleteSingleDay } from '../../dataServices/Services'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles({
	table: {
		minWidth: 650,
		marginTop: 40,
	},
})

export default function SingleEntry({
	title,
	description,
	days,
	auth,
	eid,
	pid,
	fetchplan,
}) {
	const history = useHistory()
	const classes = useStyles()
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		setLoading(false)
	}, [])
	return (
		<div>
			{loading ? (
				<div class="spinner-border text-primary" role="status">
					<span class="sr-only">Loading...</span>
				</div>
			) : (
				<TableContainer component={Paper}>
					{auth.user.isTrainer && (
						<Table className={classes.table} accessible={false}>
							<TableHead accessible={false}>
								<TableRow>
									<TableCell
										align="center"
										width="100%"
										aria-label={'title' + title}
										tabIndex={0}
										colSpan={5}
									>
										{title}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										align="center"
										width="100%"
										aria-label={'desscription' + description}
										tabIndex={0}
										colSpan={5}
									>
										{description}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell align="center" tabIndex={0} width="15%">
										Day
									</TableCell>
									<TableCell align="center" tabIndex={0} width="70%">
										Recommended Activities
									</TableCell>
									<TableCell align="center" tabIndex={0} width="15%">
										Actions
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{days.map((day) => (
									<TableRow key={day._id}>
										<TableCell
											component="th"
											tabIndex={0}
											align="center"
											scope="row"
										>
											{day.label}
										</TableCell>
										<TableCell align="left">
											{day.attachedClassRoom.map((c) => (
												<button
													className="btn btn-primary"
													style={{ marginTop: 10 }}
													onClick={() => {
														history.push(`/classroom/${c.id}`)
													}}
												>
													{c.label}
												</button>
											))}

											{day.attachedStudio.map((s) => (
												<button
													className="btn btn-primary"
													style={{ marginTop: 10 }}
													onClick={() => {
														history.push(`/studios/${s.id}`)
													}}
												>
													{s.label}
												</button>
											))}
										</TableCell>
										<TableCell align="left">
											<button
												aria-label="delete day"
												className="btn btn-danger m-2"
												onClick={async (e) => {
													setLoading(true)
													const r = await deleteSingleDay(pid, eid, day._id)
													console.log(r)
													if (r.status === 200) {
														alert(r.data.message)
														fetchplan()
														setLoading(false)
													} else {
														alert('Network Error')
														setLoading(false)
													}
												}}
											>
												<i className="fa fa-trash"></i>
											</button>
											<button
												aria-label="Edit day"
												className="btn btn-danger m-2"
												onClick={(e) => {
													history.push(`/edit-day/${pid}/${eid}/${day._id}`)
												}}
											>
												<i className="fa fa-pencil"></i>
											</button>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
							<TableFooter>
								<TableRow>
									{' '}
									<TableCell align="center" colSpan={5}>
										<button
											aria-label="delete Entery"
											className="btn btn-danger m-2"
											onClick={async (e) => {
												setLoading(true)
												const r = await deleteSingleEntry(pid, eid)
												if (r.status === 200) {
													alert(r.data.message)
													fetchplan()
													setLoading(false)
												} else {
													alert('Network Error')
													setLoading(false)
												}
											}}
										>
											<i className="fa fa-trash"></i>
										</button>
										<button
											aria-label="Edit Entery"
											className="btn btn-danger m-2"
											onClick={(e) => {
												history.push(`/edit-enteries/${pid}/${eid}`)
											}}
										>
											<i className="fa fa-pencil"></i>
										</button>
										<button
											aria-label="Add day in Entery"
											className="btn btn-danger m-2"
											onClick={(e) => {
												history.push(`/add-day/${pid}/${eid}`)
											}}
										>
											<i className="fa fa-plus"></i>
										</button>
									</TableCell>
								</TableRow>
							</TableFooter>
						</Table>
					)}
					{!auth.user.isTrainer && (
						<Table className={classes.table} accessible={false}>
							<TableHead>
								<TableRow>
									<TableCell
										align="center"
										width="100%"
										aria-label={'title' + title}
										tabIndex={0}
										colSpan={5}
									>
										{title}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										align="center"
										width="100%"
										aria-label={'desscription' + description}
										tabIndex={0}
										colSpan={5}
									>
										{description}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell align="center" tabIndex={0} width="15%">
										Day
									</TableCell>
									<TableCell align="center" tabIndex={0} width="70%">
										Recommended Activities
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{days.map((day) => (
									<TableRow key={day._id}>
										<TableCell
											component="th"
											tabIndex={0}
											align="center"
											scope="row"
										>
											{day.label}
										</TableCell>
										<TableCell align="left">
											{day.attachedClassRoom.map((c) => (
												<button
													className="btn btn-primary"
													style={{ marginTop: 10 }}
													onClick={() => {
														history.push(`/classroom/${c.id}`)
													}}
												>
													{c.label}
												</button>
											))}

											{day.attachedStudio.map((s) => (
												<button
													className="btn btn-primary"
													style={{ marginTop: 10 }}
													onClick={() => {
														history.push(`/studios/${s.id}`)
													}}
												>
													{s.label}
												</button>
											))}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					)}
				</TableContainer>
			)}
		</div>
	)
}
