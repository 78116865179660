import React, { useState, useEffect } from 'react'
import TextFieldGroup from '../common/TextFieldGroup'
import { Formik } from 'formik'
import {
	loadClassroomsService,
	loadStudiosService,
	addDayInEntery,
} from '../../dataServices/Services'
import { useParams } from 'react-router-dom'
import Select from 'react-select'
import { dayLabelValidation } from '../../constants/Constants'

const yup = require('yup')
const validationSchema = yup.object().shape({
	daylabel: yup.string().required('Day Label is required'),
})
function AddDay({ history }) {
	const { pid, eid } = useParams()
	const [loading, setLoading] = useState(false)

	const [classroomlist, setClassRoomList] = useState([])
	const [selectedClass, setSelectedClass] = useState([])
	const [studiolist, setStudioList] = useState([])
	const [selectedStudio, setSelectedStudio] = useState([])

	const [valid, setValid] = useState(true)
	const [classRooms, setClassrooms] = useState([])
	const [studios, setStudios] = useState([])

	const fetchClassrooms = async () => {
		const r = await loadClassroomsService()

		let l = []
		for (const element of r.data.classrooms) {
			l.push({
				value: element._id,
				label: element.title,
			})
		}

		if (r.status === 200) {
			setClassRoomList(l)
		} else {
			alert('Network Error')
		}
	}
	const fetchStudios = async () => {
		const r = await loadStudiosService()
		console.log(r)
		let l = []
		for (const element of r.data.studios) {
			l.push({
				value: element._id,
				label: element.title,
			})
		}

		if (r.status === 200) {
			setStudioList(l)
		} else {
			alert('Network Error')
		}
	}

	useEffect(() => {
		fetchClassrooms()
		fetchStudios()
	}, [])

	const classhandler = (e) => {
		setSelectedClass(Array.isArray(e) ? e.map((x) => x.value) : [])
		setClassrooms(
			Array.isArray(e) ? e.map((x) => ({ id: x.value, label: x.label })) : []
		)
	}
	const studiohandler = (e) => {
		setSelectedStudio(Array.isArray(e) ? e.map((x) => x.value) : [])
		setStudios(
			Array.isArray(e) ? e.map((x) => ({ id: x.value, label: x.label })) : []
		)
	}

	return (
		<div>
			<div className="container">
				<div>
					<div className="col-md-6 m-auto"></div>{' '}
					<Formik
						validationSchema={validationSchema}
						initialValues={{ daylabel: '' }}
						onSubmit={async (values) => {
							setLoading(true)
							if (values.daylabel === '') {
								setValid(false)
								setLoading(false)
							} else {
								setValid(true)

								setLoading(false)

								let r = await addDayInEntery(pid, eid, {
									label: values.daylabel,
									attachedClassRoom: classRooms,
									attachedStudio: studios,
								})
								console.log(r)
								if (r.status === 200) {
									alert(r.data.message)
									setLoading(false)
									setSelectedStudio([])
									setSelectedClass([])
									setClassrooms([])
									setStudios([])
									values.daylabel = ''
								} else {
									setLoading(false)
									alert('Network Error')
								}
							}
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
						}) => (
							<div>
								{loading ? (
									<div class="spinner-border text-primary" role="status">
										<span class="sr-only">Loading...</span>
									</div>
								) : (
									<div>
										<div>
											<label tabIndex={0} htmlFor="Daylabel">
												Day Label
											</label>

											<TextFieldGroup
												name="Day Label"
												id="Daylabel"
												type="text"
												value={values.daylabel}
												onChange={handleChange('daylabel')}
												error={
													dayLabelValidation(touched, errors)
												}
											/>

											<Select
												tabIndex={0}
												label="Select Classrooms"
												className="dropdown"
												placeholder="Select Class Rooms"
												value={classroomlist.filter((obj) =>
													selectedClass.includes(obj.value)
												)} // set selected values
												options={classroomlist} // set list of the data
												onChange={classhandler} // assign onChange function
												isMulti
												isClearable
											/>
											<br></br>
											<Select
												tabIndex={0}
												label="Select Studios"
												className="dropdown"
												placeholder="Select Class Rooms"
												value={studiolist.filter((obj) =>
													selectedStudio.includes(obj.value)
												)} // set selected values
												options={studiolist} // set list of the data
												onChange={studiohandler} // assign onChange function
												isMulti
												isClearable
											/>
											{!valid && (
												<p
													style={{
														color: 'red',
														textAlign: 'left',
														marginTop: 10,
													}}
												>
													Day label is required
												</p>
											)}
										</div>

										<button
											type="submit"
											onClick={(e) => {
												handleSubmit()
											}}
											className="btn btn-primary m-2"
										>
											Add Day
										</button>
										<button
											className="btn btn-primary m-2"
											onClick={(e) => {
												history.push(`/single-plan/${pid}`)
											}}
										>
											Cancel{' '}
										</button>
									</div>
								)}
							</div>
						)}
					</Formik>
				</div>
			</div>
		</div>
	)
}

export default AddDay
