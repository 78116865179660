/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import TextFieldGroup from '../common/TextFieldGroup'
import { Formik } from 'formik'
import { useToasts } from 'react-toast-notifications'

import { useParams } from 'react-router-dom'
import { loadSinglePlan, updatePlan } from '../../dataServices/Services'
import { titleValidation } from '../../constants/Constants'
const yup = require('yup')
const validationSchema = yup.object().shape({
	title: yup.string().trim().required('Title is required'),
	description: yup.string().trim().required('Description is required'),
})
function EditFitness({ history }) {
	const [loading, setLoading] = useState(true)

	const [plan, setPlan] = useState({})
	const { id } = useParams()
	const { addToast } = useToasts()
	const fetchPlan = async () => {
		let r = await loadSinglePlan(id)

		if (r.status === 200) {
			setLoading(false)
			setPlan(r.data.plan)
		} else {
			setLoading(false)
			alert('NETWORK_ERROR')
		}
	}
	useEffect(() => {
		fetchPlan()
	}, [])
	return (
		<div>
			<div className="container">
				<div>
					<div className="col-md-6 m-auto"></div>{' '}
					<Formik
						enableReinitialize
						validationSchema={validationSchema}
						initialValues={{
							title: plan && plan.title,
							description: plan && plan.description,
						}}
						onSubmit={async (values) => {
							setLoading(true)
							const { title, description } = values
							let r = await updatePlan(id, { title:title.trim(), description:description.trim() })
							setLoading(false)
							if (r.status === 200) {
								addToast(r.data.message, {
									appearance: 'success',
									autoDismiss: true,
								})
								history.push('/fitness-plans')
							} else {
								setLoading(false)
								alert('Required data misisng')
							}
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
						}) => (
							<div>
								{loading ? (
									<div class="spinner-border text-primary" role="status">
										<span class="sr-only">Loading...</span>
									</div>
								) : (
									<div>
										<label tabIndex={0} htmlFor="title">
											Title
										</label>
										<TextFieldGroup
											name="title"
											id="title"
											type="text"
											value={values.title}
											onChange={handleChange}
											error={titleValidation(touched, errors)}
										/>
										<label tabIndex={0} htmlFor="description">
											Description
										</label>
										<textarea
											name="description"
											id="description"
											type="textArea"
											value={values.description}
											onChange={handleChange}
											className="form-control"
										></textarea>
										<button
											type="submit"
											onClick={(e) => {
												handleSubmit()
											}}
											className="btn btn-primary m-2"
										>
											Update Fitness Plan
										</button>{' '}
										<button
											className="btn btn-primary m-2"
											onClick={(e) => {
												e.preventDefault()
												history.push('/fitness-plans')
											}}
										>
											Cancel{' '}
										</button>
									</div>
								)}
							</div>
						)}
					</Formik>
				</div>
			</div>
		</div>
	)
}

export default EditFitness
