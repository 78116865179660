/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { loadAllMessagesService } from '../../dataServices/Services'
import { useToasts } from 'react-toast-notifications'
import { connect } from 'react-redux'
import { loadMessageAction } from '../../redux/actions/messageActions'

import SingleMessage from './SingleMessage'
function MessageBoard({ loadMessageAction, auth }) {

	const [ allmessages, setAllMessages ] = useState([])
	const [ isLoading, setIsLoading ] = useState(false)

	const { addToast } = useToasts()
	const fetchmessages = async () => {
		setIsLoading(true)
		let r = await loadAllMessagesService()
		//console.log(r)
		if (r.status === 200) {

			console.log(r.data)
			addToast('Messages Loaded Succesfully', {
				appearance: 'success',
				autoDismiss: true,
			})
			loadMessageAction(r.data.messages)
			setAllMessages(r.data.messages)
			setIsLoading(false)
		}
	}

	useEffect(() => {
		fetchmessages()
	}, [])
	return (
		<div>
			<div className="top-bar">
				{' '}
				<div className="top-bar-search">
					{' '}
					<input
						type="text"
						name=""
						id=""
						placeholder="Search"
						aria-label="Search classroom by user id or user name"
					/>
				</div>
			</div>
			<div className="m-4 p-4">
				<h1 tabIndex={0} style={{ display: 'block' }} aria-label="Classrooms">
					Welcome to Message Dashboard
				</h1>{' '}
				<p tabIndex={0}>Here you can Read all the messages from Users</p>
				{isLoading ? (
					<div
						class="spinner-border text-primary ml-auto mr-auto"
						role="status"
					>
						<span class="sr-only">Loading...</span>
					</div>
				) : allmessages.map((m, ind) => {
					return <SingleMessage singleMessage={m} fetchAll={fetchmessages} />
				})}
			</div>
		</div>
	)
}
const mapDispatchtoProps = (dispatch) => {
	return {
		loadMessageAction: (messages) => {
			dispatch(loadMessageAction(messages))
		},
	}
}
const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	}
}
export default connect(mapStateToProps, mapDispatchtoProps)(MessageBoard)
