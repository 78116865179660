/* eslint-disable react-hooks/exhaustive-deps */
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import TextFieldGroup from '../common/TextFieldGroup'

import { useParams } from 'react-router-dom'
import { descriptionValidation, titleValidation } from '../../constants/Constants'
import { loadSingleFaqCategory, updateCategory } from '../../dataServices/Services'
const yup = require('yup')
const validationSchema = yup.object().shape({
    title: yup.string().trim().required('Title is required'),
    description: yup.string().trim().required('Description is required'),
})
function EditFaqCategory({ history }) {
    const [ loading, setLoading ] = useState(true)

    const [ plan, setPlan ] = useState({})
    const { id } = useParams()

    const fetchPlan = async () => {
        let r = await loadSingleFaqCategory(id)

        if (r.status === 200) {
            setLoading(false)
            setPlan(r.data.faqCategory)
        } else {
            setLoading(false)
            alert('NETWORK_ERROR')
        }
    }

    useEffect(() => {
        fetchPlan()
    }, [])

    return (
        <div>
            <div className="container">
                <div>
                    <div className="col-md-6 m-auto"></div>{' '}
                    <Formik
                        enableReinitialize
                        validationSchema={validationSchema}
                        initialValues={{
                            title: plan && plan.title,
                            description: plan && plan.description,
                        }}
                        onSubmit={async (values) => {
                            setLoading(true)
                            const { title, description } = values
                            let r = await updateCategory(id, { title:title.trim(), description:description.trim() })
                            setLoading(false)
                            if (r.status === 200) {
                                alert("succesfully updated faq category")
                                history.push('/faq')
                            } else {
                                setLoading(false)
                                alert('Required data misisng')
                            }
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => (
                            <div>
                                {loading ? (
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                ) : (
                                    <div>
                                        <label tabIndex={0} htmlFor="title">
                                            Title
                                        </label>
                                        <TextFieldGroup
                                            name="title"
                                            id="title"
                                            type="text"
                                            value={values.title}
                                            onChange={handleChange}
                                            error={titleValidation(touched, errors)}
                                        />
                                        <label tabIndex={0} htmlFor="description">
                                            Description
                                        </label>
                                        <textarea
                                            name="description"
                                            id="description"
                                            type="textArea"
                                            value={values.description}
                                            onChange={handleChange}
                                            className="form-control"
                                            style={
                                                descriptionValidation(touched, errors)
                                            }
                                        ></textarea>
                                        {
                                            touched.description && errors.description && (
                                                <p aria-label={errors.description} tabIndex={0} style={{ fontSize: '13px', color: 'red' }}>
                                                    {touched.description && errors.description}
                                                </p>
                                            )
                                        }
                                        <button
                                            type="submit"
                                            onClick={(e) => {
                                                handleSubmit()
                                            }}
                                            className="btn btn-primary m-2"
                                        >
                                            Update FAQ Category
                                        </button>{' '}
                                        <button
                                            className="btn btn-primary m-2"
                                            onClick={(e) => {
                                                e.preventDefault()
                                                history.push('/faq')
                                            }}
                                        >
                                            Cancel{' '}
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default EditFaqCategory
