import { Formik } from 'formik'
import React, { useState } from 'react'

import { Link, useLocation } from 'react-router-dom'
import { resetPassword } from '../../dataServices/Services'

import { PASSWORD_REGEX } from '../../constants/Constants'

const yup = require('yup')

const validationShcema = yup.object().shape({
	confirmPassword: yup
		.string()
		.required('Password is required'),
	password: yup
		.string()
		.required('Password is required')
		.matches(PASSWORD_REGEX, 'Password must contain at least one uppercase, one lower case, one numeral, one special character and have a minimum of 6 characters.'),
})
function NewPassword({ history }) {
	const location = useLocation();
	const email = location.state
	const [ loading, setLoading ] = useState(false)
	const [ showPass, setShowPass ] = useState('Show')
	const [ confirmPass, setConfirmShowPass ] = useState('Show')

	return (
		<div className="login">
			{loading && (
				<div class="spinner-border text-primary ml-auto mr-auto" role="status">
					<span class="sr-only">Loading...</span>
				</div>
			)}
			{!loading && (
				<div className="container">
					<div className="row text-left">
						<Formik
							initialValues={{
								password: '',
								confirmPassword: '',
							}}
							validationSchema={validationShcema}
							onSubmit={async (values) => {
								setLoading(true)
								const { password } = values
								const r = await resetPassword(email, password)
								if (r.status === 200) {
									if (r.data.user) {
										alert('Password Reset Successfully')
										history.push('/login')
										setLoading(false)
									} else {
										alert('Password Reset Failed')
										setLoading(false)
									}
								} else {
									alert('Network Error')
									setLoading(false)
								}
							}}
						>
							{({
								values,
								errors,
								touched,
								handleChange,
								handleBlur,
								handleSubmit,
								isSubmitting,
								setFieldValue,
							}) => (
								<div className="col-md-6 m-auto" aria-label="Login Form.">
									<h1 className="display-5 text-center text-danger">
										Reset Password
									</h1>
									<p className="lead text-center">
										Reset Password of your Revision account
									</p>{' '}
									<label htmlFor="userPassword" tabIndex={0}>
										New Password
									</label>
									<div className="input-group mb-3">
										<input
											className="form-control"
											placeholder="Please enter your new password."
											id="userPassword"
											name="password"
											type="password"
											onChange={handleChange}
											value={values.password}
											onBlur={handleBlur}
										/>
										<div className="input-group-append">
											<span
												tabIndex={0}
												aria-label="Show Password"
												style={{ cursor: 'pointer' }}
												onMouseOver={(e) => {
													let thiss = document.getElementById('basic-addon2')
													thiss.style.boxShadow = '2px'
													thiss.style.color = 'red'
												}}
												onMouseOut={(e) => {
													let thiss = document.getElementById('basic-addon2')
													thiss.style.boxShadow = '0'
													thiss.style.color = 'black'
												}}
												className="input-group-text"
												id="basic-addon2"
												onClick={(e) => {
													let x = document.getElementById('userPassword')

													if (x.type === 'password') {
														x.type = 'text'
														setShowPass('Hide')
													} else {
														x.type = 'password'
														setShowPass('Show')
													}
												}}
											>
												{showPass}
											</span>
										</div>{' '}
									</div>{' '}
									<p style={{ color: 'red' }}>
										{' '}
										{touched.password && errors.password ? errors.password : ''}
									</p>
									<label htmlFor="cPassword" tabIndex={0}>
										Confirm Password
									</label>
									<div className="input-group mb-3">
										<input
											className="form-control"
											placeholder="Confirm password."
											id="cPassword"
											name="confirmPassword"
											type="password"
											onChange={handleChange}
											value={values.confirmPassword}
											onBlur={handleBlur}
										/>
										<div className="input-group-append">
											<span
												tabIndex={0}
												aria-label="Show Password"
												style={{ cursor: 'pointer' }}
												onMouseOver={(e) => {
													let thiss = document.getElementById('basic-addon3')
													thiss.style.boxShadow = '2px'
													thiss.style.color = 'red'
												}}
												onMouseOut={(e) => {
													let thiss = document.getElementById('basic-addon3')
													thiss.style.boxShadow = '0'
													thiss.style.color = 'black'
												}}
												className="input-group-text"
												id="basic-addon3"
												onClick={(e) => {
													let x = document.getElementById('cPassword')

													if (x.type === 'password') {
														x.type = 'text'
														setConfirmShowPass('Hide')
													} else {
														x.type = 'password'
														setConfirmShowPass('Show')
													}
												}}
											>
												{confirmPass}
											</span>
										</div>{' '}
									</div>{' '}
									<p style={{ color: 'red' }}>
										{' '}
										{touched.confirmPassword && errors.confirmPassword
											? errors.confirmPassword
											: touched.confirmPassword && values.password !== values.confirmPassword
												? `Password doesn't match`
												: ''}
									</p>
									<button
										type="submit"
										onClick={handleSubmit}
										className="btn btn-danger btn-block mt-4"
										disabled={
											errors.confirmPassword || errors.password ||
												values.password !== values.confirmPassword ? true : false
										}
									>
										Reset Password
									</button>{' '}
									<Link className="btn btn-danger btn-block mt-4" to="/login">
										{' '}
										Cancel
									</Link>
								</div>
							)}
						</Formik>
					</div>
				</div>
			)}
		</div>
	)
}

export default NewPassword
