/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Table, Modal, Row, Col, Input, DatePicker, Layout } from "antd";
import {
  loadAllUsers,
  getUserDetail,
  updateUser,
  searchUsers,
} from "../../dataServices/Services";
import moment from "moment";

import { EditFilled } from "@ant-design/icons";
import { useToasts } from "react-toast-notifications";
import { DateFormat } from "../../constants/Constants";

const Users = (props) => {
  const [ dataSource, setDataSource ] = useState([]);
  const [ isFetching, setIsFetching ] = useState(true);
  const [ isModalVisible, setIsModalVisible ] = useState(false);
  const [ selectedUser, setSelectedUser ] = useState({});
  const selectedUserRef = useRef(selectedUser);
  selectedUserRef.current = selectedUser;
  const [ editUser, setEditUser ] = useState(false);
  const [ searchText, setSearchText ] = useState('');
  const [ showEditIcon, setShowEditIcon ] = useState(false);
  const [ enableDate, setEnableDate ] = useState(false);

  const { addToast } = useToasts();

  const showModal = (record) => {
    let userBody = { email: record.email };
    getUserDetail(userBody)
      .then((res) => {
        let user = { ...selectedUserRef.current };
        user.subEndDate = moment(parseInt(res.data.subEndDate));
        user.subPlan = res.data.subPlan;
        user.lastModified = res.data.lastModified;

        setSelectedUser(user);
        setEnableDate(moment(user.subEndDate).isAfter(moment()))
        setShowEditIcon(true);
      })
      .catch((err) => {
        addToast("Error getting users details.", {
          appearance: "error",
          autoDismiss: true,
        });
      });
    setIsModalVisible(true);
  };

  const setCurrentStatus = (status) => {
    console.log({ status })
    if (status === "month") {
      return "monthly"
    } else if (status === "year") {
      return "yearly"
    } else {
      return status
    }
  }

  const handleOk = () => {
    setIsModalVisible(false);
    setShowEditIcon(false);
    let userBody = {
      firstName: selectedUserRef.current.firstName,
      subEndDate: new Date(selectedUserRef.current.subEndDate),
      lastName: selectedUserRef.current.lastName,
      phone: selectedUserRef.current.phone,
      email: selectedUserRef.current.email,
    };
    updateUser(userBody)
      .then((res) => {
        //Get updated user details.
        let userBody = { email: selectedUserRef.current.email };

        getUserDetail(userBody)
          .then((res) => {
            setIsFetching(true);
            let user = { ...selectedUserRef.current };
            user.subEndDate = moment(parseInt(res.data.subEndDate));
            user.subPlan = res.data.subPlan;
            user.lastModified = res.data.lastModified;

            setSelectedUser(user);
            setEnableDate(moment(user.subEndDate).isAfter(moment()))
          })
          .catch((err) => {
            addToast("Error getting users details.", {
              appearance: "error",
              autoDismiss: true,
            });
          });

        addToast("User changes updated Successfully.", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((err) => {
        addToast("Error getting users details.", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setShowEditIcon(false);
  };
  const subDateChange = (val, dateString) => {
    let user = { ...selectedUserRef.current };
    user.subEndDate = dateString;

    setSelectedUser(user);
  };
  const valueChange = (val, field) => {
    let user = { ...selectedUserRef.current };
    user[ field ] = val;
    setSelectedUser(user);
  };
  useEffect(() => {
    (async function fetchData() {
      await loadAllUsers()
        .then((res) => {
          if (res && res.data && res.data.users) setDataSource(res.data.users);
          else if (res === "NETWORK_ERROR")
            addToast("Error getting users.", {
              appearance: "error",
              autoDismiss: true,
            });
        })
        .catch((err) => {
          addToast("Error getting users.", {
            appearance: "error",
            autoDismiss: true,
          });
        })
        .finally(() => setIsFetching(false));
    })();
  }, [ isFetching ]);

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      width: "20%",
      render: (record) => (
        <div style={{ wordBreak: "break-word" }}>{record}</div>
      ),

      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      render: (record) => (
        <div style={{ wordBreak: "break-word" }}>{record}</div>
      ),

      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
      width: "20%",
      key: "lastName",
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (record) => (
        <div style={{ wordBreak: "break-word" }}>{record}</div>
      ),

      sorter: (a, b) => a.email.localeCompare(b.email),
      width: "20%",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      render: (record) => (
        <div style={{ wordBreak: "break-word" }}>{record}</div>
      ),
      width: "15%",
      key: "phone",
    },
    {
      title: "Current Status",
      dataIndex: "subPlan",
      sorter: (a, b) => (a.subPlan && b.subPlan) && a.subPlan.localeCompare(b.subPlan),
      render: (record) => (
        <div style={{ wordBreak: "break-word" }}>{setCurrentStatus(record)}</div>
      ),
      width: "15%",
      key: "phone",
    },
    {
      title: "Subscribed Platform",
      dataIndex: "subPlatform",
      sorter: (a, b) => a.subPlatform.localeCompare(b.subPlatform),
      render: (record) => (
        <div style={{ wordBreak: "break-word" }}>{record}</div>
      ),
      width: "15%",
      key: "phone",
    },
    {
      title: "Subscribtion End Date",
      dataIndex: "subEndDate",
      sorter: (a, b) => new Date(parseInt(a.subEndDate)) - new Date(parseInt(b.subEndDate)),
      render: (record) => {
        return moment(record).isValid()
          ? moment(record).format(DateFormat)
          : moment(parseInt(record)).format(DateFormat)
      },

      width: "15%",
    },
    {
      title: "Created Date",
      key: "createdAt",
      className: "drag-visible",
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),

      render: (record) =>
        record.createdAt
          ? moment(record.createdAt).format(DateFormat)
          : "",
      width: "15%",
    },
    {
      title: "Modified Date",
      key: "updatedAt",
      className: "drag-visible",
      sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),

      render: (record) =>
        record.updatedAt
          ? moment(record.updatedAt).format(DateFormat)
          : "",
      width: "15%",
    },
    {
      title: "Edit User",
      dataIndex: "",
      width: "10%",
      key: "x",
      render: (text, record) => (
        <div
          onClick={() => {
            setSelectedUser(record);
            setEditUser(false);
            showModal(record);
          }}
        >
          <EditFilled
            style={{
              fontSize: "20px",
              cursor: "pointer",
              color: "#bfbfbf",
            }}
          />
        </div>
      ),
    },
  ];

  const showError = () => {
    addToast("Error getting users.", {
      appearance: "error",
      autoDismiss: true,
    });
  }

  const onSearchText = async (currentSearchText) => {
    if (currentSearchText.trim().length && currentSearchText !== searchText) {
      setIsFetching(true);
      setSearchText(currentSearchText);
    }
    await searchUsers(currentSearchText.trim())
      .then((res) => {
        if (res && res.data && res.data.users) {
          setDataSource(res.data.users);
        } else if (res === "NETWORK_ERROR") {
          showError();
        }
      })
      .catch((err) => {
        showError();
      })
      .finally(() => setIsFetching(false));
  };

  const handleSearchTextChange = async (value) => {
    if (value.trim().length === 0) {
      setSearchText('');
      setIsFetching(true);
      await loadAllUsers()
        .then((res) => {
          if (res && res.data && res.data.users) {
            setDataSource(res.data.users);
          } else if (res === "NETWORK_ERROR") {
            showError();
          }
        })
        .catch((err) => {
          showError();
        })
        .finally(() => setIsFetching(false));
    }
  };

  const disabledDate = (value) => {
    return value <= moment()
  }

  return (
    <Layout.Content>
      <Row className="mb-4 justify-content-end">
        <Col>
          <Row>
            <Col className="d-flex gutter-row mb-3 mb-lg-0">
              <Input.Search
                placeholder={'Search'}
                className="ml-2"
                onSearch={(value) => onSearchText(value)}
                onChange={(e) => handleSearchTextChange(e.target.value)}
                style={{ width: 300 }}
                allowClear
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <div style={{ marginLeft: "35px", marginRight: "25px" }}>
        <Table
          dataSource={dataSource}
          title={() => <h1>Users</h1>}
          loading={isFetching}
          columns={columns}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: [ "10", "20", "30", "50", "100" ],
            position: 'bottomRight'
          }}
          rowKey="_id"
        />
        <Modal
          title={
            <div style={{ display: "flex" }}>
              Edit User
              <div
                style={{ marginLeft: "5px" }}
                onClick={() => {
                  setEditUser(!editUser);
                }}
              >{showEditIcon === false ||
                selectedUser.subPlan === "year" ||
                selectedUser.subPlan === "month" ?
                null :
                <EditFilled
                  style={{
                    fontSize: "18px",
                    cursor: "pointer",
                    color: "#bfbfbf",
                  }}
                />}
              </div>
            </div>
          }
          visible={isModalVisible}
          okText={"Save Changes"}
          okButtonProps={{
            disabled: !editUser,
            className: "modal-footer-hiden-button",
          }}
          onOk={handleOk}
          onCancel={handleCancel}
          width={700}
        >
          <Row style={{ marginBottom: "10px" }}>
            <Col span={12}>First Name</Col>
            <Col span={12}>
              <Input
                onChange={(e) => {
                  valueChange(e.target.value, "firstName");
                }}
                disabled={true}
                value={selectedUser.firstName}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: "10px" }}>
            <Col span={12}>Last Name</Col>
            <Col span={12}>
              <Input
                onChange={(e) => {
                  valueChange(e.target.value, "lastName");
                }}
                disabled={true}
                value={selectedUser.lastName}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: "10px" }}>
            <Col span={12}>Email</Col>
            <Col span={12}>
              <Input disabled={true} value={selectedUser.email} />
            </Col>
          </Row>
          <Row style={{ marginBottom: "10px" }}>
            <Col span={12}>Phone Number</Col>
            <Col span={12}>
              <Input
                onChange={(e) => {
                  valueChange(e.target.value, "phone");
                }}
                disabled={true}
                value={selectedUser.phone}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: "10px" }}>
            <Col span={12}>Created Date</Col>
            <Col span={12}>
              <Input
                disabled={true}
                value={moment(selectedUser.createdAt).format(
                  DateFormat
                )}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: "10px" }}>
            <Col span={12}>Subscription Plan</Col>
            <Col span={12}>
              <Input disabled={true} value={setCurrentStatus(selectedUser.subPlan)} />
            </Col>
          </Row>
          <Row style={{ marginBottom: "10px" }}>
            <Col span={12}>Subscribed platform</Col>
            <Col span={12}>
              <Input disabled={true} value={selectedUser.subPlatform} />{" "}
            </Col>
          </Row>
          <Row style={{ marginBottom: "10px" }}>
            <Col span={12}>Last Modified Date</Col>
            <Col span={12}>
              <Input
                disabled={true}
                value={moment(parseInt(selectedUser.lastModified)).format(
                  DateFormat
                )}
              />{" "}
            </Col>
          </Row>
          <Row style={{ marginBottom: "10px" }}>
            <Col span={12}>Subscription Expiry Date</Col>
            <Col span={12}>
              <DatePicker
                onChange={subDateChange}
                disabled={
                  !editUser
                  || enableDate
                }
                disabledDate={(current) => disabledDate(current)}
                showTime
                value={moment(selectedUser.subEndDate)}
              />
            </Col>
          </Row>
        </Modal>
      </div>
    </Layout.Content>
  );
};

export default Users;
