import React, { useState, useRef } from 'react'
import ReactPlayer from 'react-player/lazy'
import { Link } from 'react-router-dom'
import Modal from 'react-modal'
import '../assets/css/modal.css'
import { videoUrl } from '../constants/Constants'
function Landing() {
	const [ showModal, setShowModal ] = useState(false)
	const [ playing, setPlaying ] = useState(false)
	let nowPlayingRef = useRef()
	return (
		<div className="landing">
			<div className="dark-overlay landing-inner ">
				<div className="container">
					<div className="row">
						<div className="col-md-12 text-center">
							<h1 className="display-5 mb-4" tabIndex={0}>
								ReVision Fitness: The Audio Fitness App
							</h1>
							<p className="lead"> </p>

							<div tabIndex={0} aria-label="Introduction" role="Introduction">
								<p tabIndex={0} className="text-justify">
									Welcome to ReVision Fitness, the audio fitness program by ReVision
									Training LLC! We are so glad you’re here! Whether you are a person
									looking for a place to start, an elite athlete training for your next
									big event, or somewhere in between, our goal is to bring all aspects
									of fitness to you in an accessible format. Check out the video below
									for more information on the ReVision Fitness Program, or you can keep
									scrolling to log-in or sign-up.
								</p>
							</div>
							<hr />

							<div className="conatainer mb-4">
								<div className="card">
									<div className="card-header">
										<h4 className="card-title">Introduction</h4>
									</div>

									<div className="card-body" tabIndex={0}>
										{' '}
										<ReactPlayer
											width="100%"
											height="100%"
											controls
											url={videoUrl}
											//playing={playing}
											onKeyPress={(e) => {
												if (e.charCode === 32) {
													//setPlaying(!playing)
												}
											}}
											ref={(player) => {
												nowPlayingRef = player
											}}
											onPlay={() => {
												console.log('erap', nowPlayingRef.wrapper.innerHtml)
												nowPlayingRef.wrapper.focus()
											}}
										/>
									</div>
								</div>
							</div>
							<div tabIndex={0} className="mb-2">
								<span>
									Now that you have a better idea of what the program is all about, you
									can click “Log-in” below to jump into the program, or click “Sign-up”
									to create your free account. Every new account will get an automatic
									14 day trial totally free, so you can check out every drop of material
									in the program to see if it is the right fit for you. Click sign-up to
									get started!
								</span>
							</div>
							<div className="row">
								<div className="col-md-6 col-sm-6 col-lg-6 md-auto">
									{' '}
									<Link
										to="#/"
										className="btn btn-danger m-1 btn-block"
										onClick={() => {
											setShowModal(true)
										}}
									>
										SignUp
									</Link>
								</div>
								<div className="col-md-6 col-sm-6 col-lg-6 md-auto">
									{' '}
									<Link className="btn btn-danger m-1 btn-block" to="/login">
										Login
									</Link>
								</div>
							</div>
							<Modal
								style={{ overlay: { backgroundColor: 'rgba(0,0,0,0.8)' } }}
								id="modal"
								isOpen={showModal}
								onRequestClose={() => setShowModal(false)}
							>
								<div className="row align-items-center">
									<h4
										style={{
											textAlign: 'center',
											width: '100%',
										}}
									>
										Signup
									</h4>{' '}
									<p className="pr-5 pl-5 text-center" tabIndex={0}>
										Are you signing up as an individual or as a group?{' '}
									</p>
									<Link className="btn btn-danger m-1 btn-block" to="/register">
										Sign up as Individual
									</Link>{' '}
									{/*	<Link to="#/" className="btn  m-1 btn-block inactive">
										Sign up as Group
									</Link>*/}
									<button
										className="btn btn-danger btn-block m-1 "
										onClick={() => {
											setShowModal(false)
										}}
									>
										Cancel
									</button>
								</div>
							</Modal>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Landing
