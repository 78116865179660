import { Formik } from 'formik'
import React, { useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import {
	NETWORK_ERROR,
	PASSWORD_REGEX
} from '../../constants/Constants'
import {
	signupService
} from '../../dataServices/Services'
import { signupUser } from '../../redux/actions/authActions'
import TextFieldGroup from '../common/TextFieldGroup'

const yup = require('yup')
const emailRE = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const phoneRE = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/
const validationShcema = yup.object().shape({
	firstName: yup.string().trim()
		.required('First name is required'),
	lastName: yup.string().required('Last name is required'),
	phone: yup
		.string()
		.required('Phone number is required')
		.matches(phoneRE, 'Invalid phone num'),

	email: yup
		.string()
		.required('Email is required')
		.matches(emailRE, 'Invalid email'),
	password: yup
		.string()
		.required('Password is required')
		.matches(PASSWORD_REGEX, 'Password must contain at least one uppercase, one lower case, one numeral, one special character and have a minimum of 6 characters.'),
	password2: yup.string().required('Confirm password is required'),
})
function Register({ signupUser, history }) {
	const { addToast } = useToasts()
	const toastRef = useRef();
	const [ loading, setLoading ] = useState(false)
	const [ showPass, setShowPass ] = useState('Show')
	const [ showConfirmPass, setConfirmShowPass ] = useState('Show')

	const registerUser = async (values) => {
		let user = {
			firstName: values.firstName,
			lastName: values.lastName,
			email: values.email,
			password: values.password,
			phone: values.phone,
			isTrainer: false,
			isGroup: false,
			card: { card: null },
			subscription: { customer: values.firstName },
		}
		signupService(user).then((res) => {
			if (res !== NETWORK_ERROR) {
				setLoading(false)
				addToast(<span ref={toastRef}
					aria-label={res.data.message}
					autofocus
					tabIndex={0}>{res.data.message}</span>, {
					appearance: 'success',
					autoDismiss: true,
				})
				toastRef.current.focus()
				setLoading(false);
				signupUser(res.data.user)

				setTimeout(() => {
					history.push('/login')
				}, 15000);


			} else {
				addToast(<span ref={toastRef} aria-label='Email Already exist' autofocus tabIndex={0}>Email already exist.</span>, {
					appearance: "error",
					autoDismiss: true,
					onDismiss: function () {
						document.getElementsByName("email")[ 0 ].focus();
					}
				});
				toastRef.current.focus()
				setLoading(false);
			}
		})
	}
	return (
		<div className="container">
			{loading ? (
				<div class="spinner-border" role="status">
					<span class="sr-only">Loading...</span>
				</div>
			) : (
				<div className="row">
					{' '}
					<Formik
						enableReinitialize
						initialValues={{
							email: '',
							password: '',
							firstName: '',
							lastName: '',
							phone: '',
							password2: '',
						}}
						validationSchema={validationShcema}
						onSubmit={async (values) => {
							registerUser(values)
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting,
							setFieldValue,
							isValid,
							dirty,
						}) => (
							<div className="col-md-6 m-auto text-left">
								{/*	is valid: {isValid ? 'true' : 'false'}*/}
								<h1
									tabIndex={0}
									aria-label="Signup Page"
									className="display-5 text-center text-danger"
								>
									Sign Up
								</h1>
								<p className="lead text-center" tabIndex={0}>
									Create your Revision account Now And{' '}
									<b>Get 14 days free Trial</b>
								</p>
								<p className="lead text-center" tabIndex={0}>
									{' '}
									Welcome to ReVision Fitness, the audio fitness program by
									ReVision Training LLC! We are so glad you’re here! Whether you
									are a person looking for a place to start, an elite athlete
									training for your next big event, or somewhere in between, our
									goal is to bring all aspects of fitness to you in an
									accessible format.
								</p>
								<label htmlFor="firstName" tabIndex={0}>
									First Name
								</label>
								<TextFieldGroup
									placeholder="Please Enter Your First Name"
									name="firstName"
									id="firstName"
									type="name"
									value={values.firstName}
									onBlur={handleBlur}
									onChange={handleChange}
									error={
										touched.firstName && errors.firstName
											? errors.firstName
											: ''
									}
								/>{' '}
								<label htmlFor="lastName">Last Name</label>
								<TextFieldGroup
									placeholder="Please Enter your Last name"
									name="lastName"
									type="name"
									id="lastName"
									value={values.lastName}
									onBlur={handleBlur}
									onChange={handleChange}
									error={
										touched.lastName && errors.lastName ? errors.lastName : ''
									}
								/>{' '}
								<label htmlFor="email">Email Address</label>
								<TextFieldGroup
									placeholder="Please Enter your Email Address"
									name="email"
									id="email"
									type="email"
									onChange={handleChange}
									value={values.email}
									onBlur={handleBlur}
									error={touched.email && errors.email ? errors.email : ''}
								/>
								<label htmlFor="phone">Phone Number</label>
								<TextFieldGroup
									placeholder="Please Enter your Phone number"
									name="phone"
									id="phone"
									type="number"
									value={values.phone}
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.phone && errors.phone ? errors.phone : ''}
								/>
								<label htmlFor="paasword">Password</label>
								<div className="input-group mb-3">
									{/*<TextFieldGroup
                  placeholder='Please Enter your Password'
                  name='password'
                  id='password'
                  type='password'
                  value={values.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={
                    touched.password && errors.password ? errors.password : ''
                  }
                />*/}
									<input
										className="form-control"
										placeholder="Please enter your password."
										id="paasword"
										name="password"
										type="password"
										onChange={handleChange}
										value={values.password}
										onBlur={handleBlur}
									/>
									<div className="input-group-append">
										<span
											tabIndex={0}
											aria-label="Show Password"
											style={{ cursor: 'pointer' }}
											onMouseOver={(e) => {
												let thiss = document.getElementById('basic-addon2')
												thiss.style.boxShadow = '2px'
												thiss.style.color = 'red'
											}}
											onMouseOut={(e) => {
												let thiss = document.getElementById('basic-addon2')
												thiss.style.boxShadow = '0'
												thiss.style.color = 'black'
											}}
											className="input-group-text"
											id="basic-addon2"
											onClick={(e) => {
												let x = document.getElementById('paasword')

												if (x.type === 'password') {
													x.type = 'text'
													setShowPass('Hide')
												} else {
													x.type = 'password'
													setShowPass('Show')
												}
											}}
										>
											{showPass}
										</span>
									</div>{' '}
								</div>
								<p style={{ color: 'red' }}>
									{' '}
									{touched.password && errors.password ? errors.password : ''}
								</p>
								<label htmlFor="confirm_password">Confirm Password</label>
								{/* <TextFieldGroup
                  placeholder='Please Enter your Confirm password'
                  name='password2'
                  id='confirm_password'
                  type='password'
                  value={values.password2}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={
                    touched.password2 && errors.password2
                      ? errors.password2
                      : ''
                  }
                />*/}
								<div className="input-group mb-3">
									<input
										className="form-control"
										placeholder="Please re-enter your password."
										id="confirm_password"
										name="password2"
										type="password"
										onChange={handleChange}
										value={values.password2}
										onBlur={handleBlur}
									/>
									<div className="input-group-append">
										<span
											tabIndex={0}
											aria-label="Show Confirm Password"
											style={{ cursor: 'pointer' }}
											onMouseOver={(e) => {
												let thiss = document.getElementById('basic-addon3')
												thiss.style.boxShadow = '2px'
												thiss.style.color = 'red'
											}}
											onMouseOut={(e) => {
												let thiss = document.getElementById('basic-addon3')
												thiss.style.boxShadow = '0'
												thiss.style.color = 'black'
											}}
											className="input-group-text"
											id="basic-addon3"
											onClick={(e) => {
												let x = document.getElementById('confirm_password')

												if (x.type === 'password') {
													x.type = 'text'
													setConfirmShowPass('Hide')
												} else {
													x.type = 'password'
													setConfirmShowPass('Show')
												}
											}}
										>
											{showConfirmPass}
										</span>
									</div>{' '}
								</div>
								<p style={{ color: 'red' }}>
									{' '}
									{touched.password2 && errors.password2
										? errors.password2
										: touched.password2 && values.password !== values.password2
											? `Password doesn't match`
											: ''}
								</p>
								<div>
									<div>
										<p tabIndex={0}>
											By clicking sign-up, you are agreeing to our terms and conditions,
											<a href="/terms-conditions"> Terms and conditions</a>
											<a href="/privacyPolicy"> Privacy policy</a> And{' '}
											<a href="/disclaimer">liability waver</a> You also agree to receiving
											important notifications via email and phone. We will never share your
											information with third parties. After clicking "Sign-up" your 14 day
											free trial will begin, giving you full access to all the material in
											the program through the end of your trial period. After the 14 days,
											you can continue your training by selecting one of the auto-renewable
											monthly or yearly subscriptions. I am excited to join you on your
											journey, click sign-up and let's get started, and from ReVision
											Training, Let's change the way we Look At It!
										</p>
									</div>
								</div>{' '}
								{/* <input
									type="radio"
									id="yearly"
									name="subscription"
									value="yearly"
									onChange={(e) => {
										setSubscriptionType(e.target.value)
										console.log(e.target.value)
									}}
								/>
								<label
									tabIndex={0}
									aria-label="Pay $60.00 for annual subscription with fourteen day trial"
								>
									Pay $60.00 for annual subscription with fourteen day trial
								</label>
								<br></br>
								<input
									type="radio"
									id="monthly"
									checked
									name="subscription"
									value="monthly"
									onChange={(e) => {
										setSubscriptionType(e.target.value)
										console.log(e.target.value)
									}}
								/>
								<label
									tabIndex={0}
									aria-label="Pay $5.99 per month for Monthly subscription with fourteen day trial"
								>
									Pay $5.99 per month for Monthly subscription with fourteen day
									trial
								</label> */}
								{/* <StripeCheckout
									stripeKey="pk_live_51HVGlZFm9N2WPB4VS6wpDQP9QGhkxzbDNWprT1rcF23I0Llk3E6ob3W1rdVwyJJIXX8fvXeJOFf8fkoYqQpuiLYc00Qr7xZ94M"
									email={values.email}
									image={require('../../assets/Logo1.png')}
									token={(token) => {
										if (
											subscriptionType === 'yearly' ||
											subscriptionType === 'monthly'
										) {
											if (
												!isEmpty(values.firstName) &&
												!isEmpty(values.lastName) &&
												!isEmpty(values.email) &&
												!isEmpty(values.password) &&
												!isEmpty(values.password2) &&
												!isEmpty(values.phone)
											) {
												onToken(token, values)
											} else {
												alert(
													'All fields are required Subscription Not created'
												)
											}
										} else {
											alert('Please Select A subscription Plan')
										}
									}}
								>
				
								</StripeCheckout> */}
								<button
									className="btn btn-danger btn-block mt-4"
									aria-label="Sign Up"
									disabled={
										!isValid || !dirty || values.password !== values.password2
									}
									onClick={handleSubmit}
								>
									Sign Up
								</button>
								<Link className="btn btn-danger btn-block mt-4" to="/">
									{' '}
									Cancel
								</Link>
							</div>
						)}
					</Formik>
				</div>
			)}
		</div>
	)
}
const matchDispatchToProps = (dispatch) => {
	return {
		signupUser: (userData) => {
			dispatch(signupUser(userData))
		},
	}
}
export default connect(null, matchDispatchToProps)(Register)
