import React from "react";

function Disclaimer({ history }) {
  return (
    <div
      style={{
        marginBottom: "10px",
        marginTop: "10px",
        marginLeft: "10px",
        marginRight: "10px",
        textAlign: "left",
        justifyContent: "left",
        textJustify: "center",
        padding: "0px 20px",
      }}
    >
      <h1>Disclaimer, release of liability, and waiver of rights</h1>
      <p>
        <b>
          ReVision Training LLC and its affiliates (collectively, “ReVision”,
          “us”, “we” or “our”) are pleased to provide you with access to and use
          of our website, applications, content, products, goods, equipment,
          services, software, technology and any other materials (collectively,
          “ReVision Services”) that we may provide.
        </b>
      </p>
      <p>
        <b>
          THE REVISION SERVICES ARE NOT INTENDED FOR ANYONE UNDER THE AGE OF 13.
          IF YOU ARE 13 TO 17 YEARS OF AGE, YOU MAY ONLY ACCESS AND/OR USE ANY
          REVISION SERVICES WITH THE INVOLVEMENT AND APPROVAL OF YOUR PARENT OR
          GUARDIAN.
        </b>
      </p>
      <ul>
        <li>
          <b>Contract between You and ReVision</b>
          <p>
            This Disclaimer, Release of Liability, and Waiver of Rights (this “
            <b>Disclaimer, Release, and Waiver</b>”), our Website Terms and
            Conditions of Use, and our Privacy Policy (available at [INSERT THE
            LINK]) form a legally binding agreement between you and ReVision,
            and govern your access and use, and our provision of, the ReVision
            Services and any other technology, content, items or other materials
            provided by or through ReVision or otherwise.
          </p>
          <p>
            <b>
              BY ACCESSING OR USING ANY REVISION SERVICE, YOU AGREE TO BE BOUND
              AND ABIDE BY THIS DISCLAIMER, RELEASE, AND WAIVER, our Website
              Terms and Conditions of Use, our Privacy Policy, SUPPLEMENTAL
              TERMS, AND ANY AMENDMENTS TO ANY OF THE FOREGOING, AND YOU
              REPRESENT AND WARRANT THAT YOU ARE AT LEAST 13 YEARS OF AGE.
              PLEASE READ, REVIEW, and/or listen to THIS DISCLAIMER, RELEASE,
              AND WAIVER, our Website Terms and Conditions of Use, our Privacy
              Policy, and, if applicable, SUPPLEMENTAL TERMS CAREFULLY (OR
              PLEASE HAVE THEM READ TO YOU) AND IN THEIR ENTIRETY BEFORE USING
              ANY REVISION SERVICES, ESPECIALLY SINCE THIS DISCLAIMER, RELEASE,
              AND WAIVER AND SUCH otheR TERMS AND DOCUMENTS MAY SUBSTANTIALLY
              LIMIT OR OTHERWISE AFFECT YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT
              TO BRING A LAWSUIT. [BY CLICKING THE “i AGREE” BOX AT THE END OF
              THIS DOCUMENT], YOU certify that YOU have FULLY reviewed, YOU
              FULLY UNDERSTAND, AND YOU VOLUNTARILY AND IRREVOCABLY consent and
              agree to, all of the terms AND CONDITIONS CONTAINED IN THIS
              DISCLAIMER, RELEASE, AND WAIVER.]
            </b>
          </p>
          <p>
            IF YOU DO NOT AGREE TO THIS DISCLAIMER, RELEASE, AND WAIVER, our
            Website Terms and Conditions of Use, OR our Privacy Policy, PLEASE
            IMMEDIATELY CEASE USE OF ANY REVISION SERVICES.
          </p>
          <p>
            Supplemental terms and conditions may apply to certain ReVision
            Services, such as rules for a particular activity or terms that may
            accompany certain content, software or other materials accessible
            through the ReVision Services (“Supplemental Terms”). Any
            Supplemental Terms will be disclosed to you in connection with those
            other activities.
          </p>
        </li>
        <li>
          <b>Changes to Disclaimer, Release, and Waiver</b>
          <p>
            We may in our sole and absolute discretion change this Disclaimer,
            Release, and Waiver, our Website Terms and Conditions of Use, and
            our Privacy Policy, from time to time, to comply with laws or to
            meet our changing business requirements. These revisions shall be
            effective for new users immediately upon being posted to the
            ReVision Services; however, for existing customers, the applicable
            revisions shall be effective 30 days after posting unless otherwise
            stated. If you do not agree with any of the changes, you must
            discontinue using any and all ReVision Services. By continuing to
            use any ReVision Services after the 30-day period referenced above
            in this Section, you expressly accept any applicable changes.
          </p>
        </li>
        <li>
          <b>Third-Party Services and Content</b>
          <p>
            Certain ReVision Services may integrate, be integrated into, or be
            provided in connection with third-party websites, services,
            applications, tools, interfaces, content, and/or materials (“
            <b>Third-Party Services</b>”). We do not review or control any
            Third-Party Services. We additionally make no claim or
            representation regarding, and accept no responsibility for, the
            quality, content, nature, or reliability of Third-Party Services
            accessible from our website, apps, software or any other element of
            the ReVision Services. There is no implied affiliation, endorsement
            or adoption by ReVision of these Third-Party Services and we shall
            not be responsible for any content provided on or through these
            Third-Party Services. You should read the terms of use and privacy
            policies that separately apply to these Third-Party Services.
          </p>
          <p>
            Certain opinions, advice, statements, or other information,
            including without limitation, food, nutrition and exercise data, may
            be made available by third parties through or in connection with the
            ReVision Services (“<b>Third-Party Content</b>”). This Third-Party
            Content belongs to the respective authors or providers of the
            applicable Third-Party Content and these authors and providers are
            solely responsible for the Third-Party Content they provide in
            connection with the ReVision Services. REVISION DOES NOT: (I)
            GUARANTEE THE ACCURACY, COMPLETENESS, OR USEFULNESS OF ANY
            THIRD-PARTY CONTENT ON ANY REVISION SERVICE; OR (II) ADOPT, ENDORSE
            OR ACCEPT RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY
            OPINION, ADVICE OR STATEMENT MADE BY A THIRD-PARTY AS PART OF ANY
            THIRD-PARTY CONTENT. UNDER NO CIRCUMSTANCES WILL REVISION BE
            RESPONSIBLE FOR ANY LOSS OR DAMAGE RESULTING FROM YOUR RELIANCE ON
            THIRD-PARTY CONTENT POSTED ON THE REVISION SERVICES OR TRANSMITTED
            TO OR BY ANY THIRD-PARTY IN CONNECTION WITH THE REVISION SERVICES.
          </p>
        </li>
        <li>
          <b>Disclaimer of Warranties</b>
          <p>
            YOU ACKNOWLEDGE AND AGREE THE REVISION SERVICES, INCLUDING WITHOUT
            LIMITATION, ANY PRODUCTS, GOODS, SERVICES, WEBSITES, APPLICATIONS,
            HEALTH, FITNESS, NUTRITION AND WELLNESS CONTENT AND ADVICE, OR ANY
            OTHER INFORMATION PROVIDED THEREIN ARE PROVIDED “AS IS” AND “AS
            AVAILABLE”, WITH ALL FAULTS, ERRORS AND OMISSIONS, AND WITHOUT ANY
            PERFORMANCE ASSURANCES OR GUARANTEES OF ANY KIND. WE EXPRESSLY
            DISCLAIM ANY AND ALL WARRANTIES, CONDITIONS, AND REPRESENTATIONS,
            EXPRESS OR IMPLIED, REGARDING THE REVISION SERVICES, INCLUDING
            WITHOUT LIMITATION ANY IMPLIED WARRANTIES OF MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, SATISFACTORY
            QUALITY, UPTIME, ACCESSIBILITY, AND WARRANTIES ARISING FROM COURSE
            OF DEALING, USAGE OR TRADE PRACTICE. WE MAKE NO GUARANTEE OR
            WARRANTY THAT THE REVISION SERVICE WILL MEET YOUR REQUIREMENTS OR
            THAT THEY WILL BE UNINTERRUPTED OR ERROR-FREE. WE FURTHER MAKE NO
            GUARANTEE OR WARRANTY AS TO THE PARTICULAR HEALTH AND WELLNESS
            GOALS, RESULTS, BENEFITS OR OUTCOMES THAT MAY BE ACHIEVED OR
            OBTAINED THROUGH USE OF ANY REVISION SERVICES. YOU AGREE TO USE THE
            REVISION SERVICES AT YOUR SOLE RISK.
          </p>
          <p>
            YOU WILL NOT HOLD REVISION OR ITS THIRD-PARTY SERVICE PROVIDERS,
            LICENSORS AND SUPPLIERS, AS APPLICABLE, RESPONSIBLE FOR ANY LOSS OR
            DAMAGE THAT RESULTS FROM YOUR ACCESS TO OR USE OF THE REVISION
            SERVICES, INCLUDING WITHOUT LIMITATION ANY LOSS OR DAMAGE TO ANY OF
            YOUR COMPUTERS OR DATA, AS THE REVISION SERVICES MAY CONTAIN BUGS,
            ERRORS, PROBLEMS OR OTHER LIMITATIONS.
          </p>
          <p>
            YOU ACKNOWLEDGE THAT WE DO NOT CONTROL ANY ADVERTISEMENTS, PRODUCT
            DESCRIPTIONS, PRODUCTS, OR CONTENT OFFERED BY THIRD PARTIES ON OR
            THROUGH OUR WEBSITE, INCLUDING WITHOUT LIMITATION, INFORMATION OR
            PRODUCTS PROVIDED BY LICENSE TO US FROM THIRD PARTIES. CERTAIN
            WARRANTIES WITH RESPECT TO PARTICULAR PRODUCTS/SERVICES ACCESSED FOR
            SALE THROUGH OUR WEBSITE MAY BE AVAILABLE THROUGH MANUFACTURERS’
            WARRANTIES, THOUGH NOT THROUGH REVISION; PLEASE READ THE WARRANTIES
            INCLUDED IN THE DOCUMENTATION PROVIDED ALONG WITH THOSE
            PRODUCTS/SERVICES (OR HAVE THEM READ TO YOU) FOR FURTHER DETAILS.
            EXCEPT AS OTHERWISE AGREED IN WRITING, WE ASSUME NO RESPONSIBILITY
            FOR AND MAKE NO WARRANTY OR REPRESENTATION AS TO THE ACCURACY,
            CURRENCY, COMPLETENESS, RELIABILITY, OR USEFULNESS OF THIRD-PARTY
            CONTENT, THIRD-PARTY SERVICES, OR THIRD-PARTY PRODUCTS (INCLUDING
            PRODUCT DESCRIPTIONS) DISTRIBUTED OR MADE AVAILABLE BY THIRD PARTIES
            THROUGH OUR WEBSITE.
          </p>
          <p>
            [Certain health or medical material on our Website may be sexually
            explicit. Please do not use this site if you would find these
            materials offensive.] [NTD: This provision is better suited to be
            included in the Website Terms of Use.]
          </p>
        </li>
        <li>
          <b>Important Information About Our Fitness and Nutrition Content</b>
          <ul>
            <li>
              <b>
                The ReVision Services Do Not Constitute Medical Advice or Other
                Professional Healthcare Advice.
              </b>
              <p>
                Although ReVision provides its products and services with your
                health and safety in mind, each individual has their own
                limitations and it is therefore critical that you consult your
                physician or other licensed healthcare provider for necessary
                medical or appropriate care, follow all safety and other
                instructions provided by ReVision, and obtain appropriate
                authorization before accessing or using any ReVision Services,
                especially if you have any physical impairment or any unique or
                special medical condition, if you are prone to injuries, are
                pregnant or nursing, you (or your family) have a history of high
                blood pressure or heart disease, if you have ever experienced
                chest pain when exercising or have experienced chest pain in the
                past when not engaged in physical activity, if you smoke, if you
                have high cholesterol, if you are obese, or have a bone or joint
                problem that could be made worse by a change in physical
                activity, or if you have any other medical condition.
              </p>
              <p>
                After reading or listening to the articles, watching videos or
                reading or listening to other content from our website, you are
                encouraged to review the information carefully with your
                professional healthcare provider.
              </p>
              <p>
                Do not use the ReVision Services if your physician or health
                care provider advises against it. If you experience faintness,
                dizziness, pain or shortness of breath at any time while
                exercising you should stop immediately. If you are in the United
                States and think you are having a medical or health emergency,
                call your health care professional, or 911, immediately.
              </p>
              <p>
                ReVision is not a licensed medical care provider. ALL REVISION
                SERVICES ARE PROVIDED FOR INFORMATIONAL PURPOSES ONLY, AND ARE
                NOT INTENDED TO DIAGNOSE ANY MEDICAL OR HEALTHCARE CONDITION,
                ADVISE ON THE effect of any specific exercise on a medical
                condition, REPLACE THE ADVICE OF A LICENSED HEALTHCARE
                PROFESSIONAL, OR PROVIDE ANY MEDICAL ADVICE, OPINION, DIAGNOSIS,
                TREATMENT OR GUARANTEE. The information made available on or
                through the ReVision Services should not be relied upon when
                making medical or other important healthcare decisions. REVISION
                DOES NOT PROVIDE CLINICAL SERVICES. NO REPRESENTATIVES OF
                REVISION ARE FUNCTIONING IN ANY CLINICAL OR LICENSED MEDICAL OR
                PSYCHOLOGICAL CAPACITY; SUCH INDIVIDUALS ARE SOLELY PROVIDING
                INFORMATIONAL AND EDUCATIONAL SELF-HELP RESOURCES. YOUR USE OF
                ANY REVISION SERVICES DOES NOT CREATE A DOCTOR-PATIENT,
                PROFESSIONAL-CLIENT, OR SIMILAR RELATIONSHIP BETWEEN YOU AND
                REVISION OR YOU AND ANY REPRESENTATIVE OF REVISION.
              </p>
              <p>
                Never disregard professional medical advice, or delay in seeking
                it, because of something you have (or have been) read or
                listened to on our website or application or otherwise as part
                of the ReVision Services.
              </p>
              <p>
                Any individual results using ReVision Services may vary.
                Exercise and proper diet are necessary to achieve and maintain
                weight loss and muscle definition. Any testimonials featured on
                our website may have used more than one ReVision product,
                nutrition program and/or supplements, or extended the program to
                achieve their successful results.
              </p>
              <p>
                <b>
                  {" "}
                  You are acknowledging that you are participating voluntarily
                  in using the ReVision Services
                </b>{" "}
                and you alone are solely and personally responsible for your
                results. You acknowledge that you take full responsibility for
                your health, life and well-being, as well as the health, lives
                and well-being of your family and children (born and unborn, as
                applicable), and all decisions now or in the future.
              </p>
            </li>
            <li>
              Nutrition.{" "}
              <p>
                Certain ReVision Services, such as nutrition plans provided with
                our fitness programs and online nutrition database, and access
                to our nutrition team, provide nutrition, food, caloric and
                other related information designed to help our customers eat
                healthy to help them reach their health and wellness goals.
                While any nutrition information we may provide is designed to
                safely align with our related exercise regimens, any
                recommendation for changes in diet are entirely your
                responsibility and you must consult your physician or other
                licensed healthcare provider prior to undergoing any dietary or
                food supplement changes, beginning any nutrition plan, or
                altering any dietary regimen, especially if you have any unique
                or special medical needs or conditions, including, as an
                example, food allergies, dietary restrictions or if you are
                pregnant or breast feeding. The ReVision Services are not a
                substitute for professional care. If you have or suspect you may
                have a medical or psychological problem, or a nutritional
                deficiency or dietetic condition, you should consult your
                medical doctor, psychologist or nutritionist, as appropriate.
              </p>{" "}
            </li>
            <li>
              <b>
                Assumption of Risk; Waiver of rights and Release of Liability.
              </b>
              <p>
                <b>
                  You expressly acknowledge and agree that your access, use
                  and/or involvement with any ReVision Services may involve
                  potentially dangerous physical and other activities that may
                  lead to personal and/or bodily injury, illness, death,
                  temporary or permanent disability, loss of services, loss of
                  consortium, or damage to or loss of property or privacy. YOU
                  AGREE THAT YOU ARE VOLUNTARILY PARTICIPATING IN THESE
                  ACTIVITIES AND YOU HEREBY WILLINGLY ACCEPT AND ASSUME ALL SUCH
                  RISKS, INCLUDING ALL RISKS OF INJURY, ILLNESS, DISABILITY, OR
                  DEATH, ARISING FROM YOUR ACCESS, USE, AND/OR INVOLVEMENT WITH
                  THE REVISION SERVICES, WHETHER CAUSED BY THE NEGLIGENCE OF
                  REVISION OR OTHERWISE. In consideration for your access and
                  use of the ReVision Services, you, on behalf of yourself and
                  your agents, insurers, heirs, beneficiaries, legal
                  representatives, estate administrators and executors,
                  successors, assigns, trustees, and indemnitors (collectively,
                  the “Releasors”), hereby absolutely, unconditionally, and
                  irrevocably RELEASE, WAIVE, AND FOREVER RELINQUISH AND
                  DISCHARGE any and all claims, demands, obligations, suits,
                  damages (including any incidental, consequential and punitive
                  damages), losses, causes of action, costs, expenses, and
                  liabilities, in each case, of any kind, nature or description,
                  whether known or unknown, whether matured or unmatured,
                  whether arising in law or equity, or upon contract or tort, or
                  under any state or federal law or otherwise, which you or any
                  other Releasor has had, now has, has made a claim to have, or
                  may have, directly or indirectly, against ReVision and/or its
                  members, managers, officers, directors, employees, suppliers,
                  agents, representatives, successors and assigns (collectively,
                  the “Releasees”), arising out of or related to your access,
                  use and/or involvement with any ReVision Services, whether
                  arising out of the negligence of any Releasee or otherwise.
                  You acknowledge, agree, and understand that no fact, event,
                  circumstance, evidence or transaction which could now be
                  asserted or which may hereafter be discovered shall affect in
                  any manner the final, absolute and unconditional nature of the
                  release set forth in this Section. You further covenant and
                  agree not to make or bring any claim or action against any
                  Releasee for any damages, losses, causes of action, costs,
                  expenses, and/or other liabilities, of whatever kind, arising
                  out of or related to your access, use and/or involvement with
                  any ReVision Services, whether arising out of the negligence
                  of any Releasee or otherwise, and you hereby forever release
                  and discharge ReVision and all other Releasees from any and
                  liability under such claims.
                </b>
              </p>
              <p>
                <b>
                  This waiver of rights and release of liability includes,
                  without limitation, all injuries which may occur, regardless
                  of negligence, as a result of your use of, and your
                  participation in any, ReVision Services, including, without
                  limitation, our instruction, training, supervision, or dietary
                  or nutritional recommendations.
                </b>
              </p>
              <p>
                <b>
                  You acknowledge that you have carefully reviewed this “waiver
                  and release” in its entirety, have consulted with counsel of
                  your choice if you deemed it to be necessary, and fully
                  understand that it is a release of liability.
                </b>
              </p>
            </li>
            <li>
              <b>Indemnification; Limitation of Liability</b>
              <ul>
                <li>
                  <b>ndemnification</b>
                  <p>
                    You agree to indemnify and hold ReVision and other Releasees
                    harmless from and against any and all losses, damages,
                    liabilities, deficiencies, claims, actions, judgments,
                    settlements, interest, awards, penalties, fines, costs, or
                    expenses of whatever kind, including attorney fees, fees,
                    and the costs of enforcing any right to indemnification
                    under this Agreement, and the cost of pursuing any insurance
                    providers, incurred by ReVision or any other Releasees,
                    arising out or resulting from any claim of a third party
                    related to your improper use of any ReVision Services, your
                    violation of this Disclaimer, Release, and Waiver, our
                    Website Terms and Conditions of Use, our Privacy Policy, any
                    Supplemental Terms, or your violation of any rights of a
                    third party.
                  </p>
                </li>
                <li>
                  <b>Limitation of Liability</b>
                  <p>
                    TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL
                    REVISION OR ITS SERVICE PROVIDERS, LICENSORS OR SUPPLIERS BE
                    LIABLE FOR SPECIAL, COMPENSATORY, INDIRECT, INCIDENTAL,
                    CONSEQUENTIAL, OR EXEMPLARY DAMAGES, LOST PROFITS, LOST DATA
                    OR CONFIDENTIAL OR OTHER INFORMATION, LOSS OF PRIVACY, COSTS
                    OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, FAILURE TO
                    MEET ANY DUTY INCLUDING WITHOUT LIMITATION OF GOOD FAITH OR
                    OF REASONABLE CARE, NEGLIGENCE, OR OTHERWISE, REGARDLESS OF
                    THE FORESEEABILITY AND/OR IF WE WERE ADVISED OF SUCH DAMAGES
                    OR OF ANY ADVICE OR NOTICE (including, without limitation,
                    any notice of your physical impairment or other special or
                    unique medical condition) GIVEN TO REVISION OR ITS SERVICE
                    PROVIDERS, LICENSORS AND SUPPLIERS ARISING OUT OF OR IN
                    CONNECTION WITH YOUR USE OF ANY REVISION SERVICES. THIS
                    LIMITATION SHALL APPLY REGARDLESS OF WHETHER THE DAMAGES
                    ARISE OUT OF BREACH OF CONTRACT, NEGLIGENCE, TORT, OR ANY
                    OTHER LEGAL THEORY OR FORM OF ACTION. ADDITIONALLY, THE
                    MAXIMUM LIABILITY OF REVISION AND ITS SERVICE PROVIDERS,
                    LICENSORS AND SUPPLIERS TO YOU UNDER ALL CIRCUMSTANCES WILL
                    BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU IN THE LAST
                    NINETY (90) DAYS TO REVISION FOR ANY REVISION SERVICES. YOU
                    AGREE THAT THIS LIMITATION OF LIABILITY REPRESENTS A
                    REASONABLE ALLOCATION OF RISK AND IS A FUNDAMENTAL ELEMENT
                    OF THE BASIS OF THE BARGAIN BETWEEN REVISION AND YOU. THE
                    REVISION SERVICES WOULD NOT BE PROVIDED WITHOUT SUCH
                    LIMITATIONS. REVISION WILL NOT BE LIABLE FOR FAILURE TO
                    PERFORM ANY OBLIGATION UNDER this disclaimer, release, and
                    waiver, our website terms of use, our privacy policy,
                    supplemental terms, or otherwise, IF SUCH FAILURE IS CAUSED
                    BY THE OCCURRENCE OF ANY UNFORESEEN CIRCUMSTANCE BEYOND ITS
                    REASONABLE CONTROL, INCLUDING, WITHOUT LIMITATION, INTERNET
                    OUTAGES, COMMUNICATIONS OUTAGES, FIRE, FLOOD, NATURAL
                    DISASTER, ACT OF TERRORISM, EPIDEMICS, PANDEMICS, GOVERNMENT
                    ACTIONS OR ORDERS, CIVIL UNREST OR WAR.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
          <li>
            <b>MISCELLANEOUS</b>
            <ul>
              <li>
                <b>Governing Law.</b> You agree that the laws of the State of
                Indiana, without regard to principles of conflict of laws, will
                exclusively govern this Disclaimer, Release, and Waiver, and any
                dispute between you and ReVision. As the ReVision Services are
                controlled by ReVision from Indiana, Indiana law will apply
                regardless of your residence or the location where you use
                ReVision Services.
              </li>
              <li>
                <b>Exclusive Venue.</b> ReVision and you agree that any disputes
                arising under this Disclaimer, Release, and Waiver shall be
                filed only in the state and federal courts located in Fort
                Wayne, Allen County, Indiana, and each such party hereby
                irrevocably and unconditionally consents and submits to the
                exclusive jurisdiction of such courts for any such controversy.
              </li>
              <li>
                <b>WAIVER OF CLASS ACTIONS.</b> YOU AND REVISION AGREE THAT EACH
                PARTY MAY BRING DISPUTES AGAINST THE OTHER PARTY ONLY IN AN
                INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN
                ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING, INCLUDING
                WITHOUT LIMITATION FEDERAL OR STATE CLASS ACTIONS.
              </li>
              <li>
                <b>Severability.</b> If any provision of this Disclaimer,
                Release, and Waiver, or a part thereof, shall be unlawful, void
                or for any reason unenforceable, then that provision or part
                thereof shall be deemed severable from this Disclaimer, Release,
                and Waiver and shall not affect the validity and enforceability
                of any remaining provisions or parts thereof.
              </li>
              <li>
                <b>Termination.</b> Notwithstanding anything to the contrary,
                ReVision reserves the right, without notice and in its sole and
                absolute discretion, for any reason or no reason, to terminate
                your access and use of any ReVision Services, including to block
                or prevent your access and use of our website. You agree that
                ReVision shall not be liable for any interruption or termination
                of your access and/or use of our website.
              </li>
            </ul>
          </li>
        </li>
      </ul>
      <button
        className="btn btn-primary"
        onClick={() => {
          history.goBack();
        }}
      >
        Back
      </button>
    </div>
  );
}

export default Disclaimer;
