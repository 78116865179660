import { GET_HEALTH_AND_FITNESS } from "../actions/types";
const initialState = {
    healthResource: [],
};
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_HEALTH_AND_FITNESS:
            return {
                ...state,
                healthResource: action.payload,
            };
        default:
            return state;
    }
}
