import React, { useState } from 'react'
import TextFieldGroup from '../common/TextFieldGroup'
import { Formik } from 'formik'

import { addFaqQuestion } from '../../dataServices/Services'
import { useParams } from 'react-router-dom'
import { answerValidation, questionValidation } from '../../constants/Constants'

const yup = require('yup')
const validationSchema = yup.object().shape({
    question: yup.string().trim().required('Question is required'),
    answer: yup.string().trim().required('Answer is required'),
})
function AddFaqQuestion({ history }) {
    const [ loading, setLoading ] = useState(false)
    const { id } = useParams()

    return (
        <div>
            <div className="container">
                <div>
                    <div className="col-md-6 m-auto"></div>{' '}
                    <Formik
                        validationSchema={validationSchema}
                        initialValues={{ question: '', answer: '' }}
                        onSubmit={async (values) => {
                            setLoading(true)
                            const { question, answer } = values
                            let r = await addFaqQuestion(id, { question:question.trim(), answer:answer.trim() })
                            if (r.status === 200) {
                                setLoading(false)
                                alert(r.data.success)
                                history.push(`/faq-category/${id}`)
                            } else {
                                setLoading(false)
                                alert('FAQ not added successfully')
                            }
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => (
                            <div>
                                {loading ? (
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                ) : (
                                    <div>
                                        <label tabIndex={0} htmlFor="question">
                                            Question
                                        </label>
                                        <TextFieldGroup
                                            name="question"
                                            id="question"
                                            type="text"
                                            value={values.question}
                                            onChange={handleChange}
                                            error={questionValidation(touched, errors)}
                                        />
                                        <label tabIndex={0} htmlFor="answer">
                                            Answer
                                        </label>
                                        <textarea
                                            name="answer"
                                            id="answer"
                                            type="textArea"
                                            value={values.answer}
                                            onChange={handleChange}
                                            className="form-control"
                                            style={
                                                answerValidation(touched, errors)
                                            }
                                        ></textarea>
                                          {
                                            touched.answer && errors.answer && (
                                                <p aria-label={errors.answer} tabIndex={0} style={{ fontSize: '13px', color: 'red' }}>
                                                    {touched.answer && errors.answer}
                                                </p>
                                            )
                                        }

                                        <button
                                            type="submit"
                                            onClick={(e) => {
                                                handleSubmit()
                                            }}
                                            className="btn btn-primary m-2"
                                        >
                                            Create FAQ
                                        </button>
                                        <button
                                            className="btn btn-primary m-2"
                                            onClick={(e) => {
                                                e.preventDefault()
                                                history.push(`/faq-category/${id}`)
                                            }}
                                        >
                                            Cancel{' '}
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default AddFaqQuestion
