import { Formik } from 'formik'
import React, { useRef, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { contactUs } from '../../dataServices/Services'
import TextFieldGroup from '../common/TextFieldGroup'

const yup = require('yup')
const validationSchema = yup.object().shape({
	name: yup.string().required('Name is required'),
	subject: yup.string().required('Subject is required'),
	mail: yup.string().email().required('Email is required'),
	message: yup.string().required('Message is required'),
})

function Contact({ history }) {
	const [ loading, setLoading ] = useState(false);
	const { addToast } = useToasts()
	const toastRef = useRef();

	function messageValidation(touched, errors) {
		return touched.message && errors.message
			? { border: '1px solid red' }
			: {}
	}

	function nameValidation(touched, errors) {
		return touched.name && errors.name ? errors.name : ''
	}

	function mailValidation(touched, errors) {
		return touched.mail && errors.mail ? errors.mail : ''
	}

	function subjectValidation(touched, errors) {
		return touched.subject && errors.subject ? errors.subject : ''
	}

	return (
		<div>
			<div className="container">
				<div>
					<div className="col-md-6 m-auto"></div>{' '}
					<Formik
						validationSchema={validationSchema}
						initialValues={{ name: '', subject: '', message: '', mail: '' }}
						onSubmit={async (values, { resetForm }) => {
							try {
								setLoading(true)
								const { message, mail, subject, name } = values
								const data = { name, email: mail, subject, message }

								const response = await contactUs(data)

								setLoading(false)
								resetForm({ values: '' });
								addToast(<span
									ref={toastRef}
									aria-label={response?.data?.success} tabIndex={0}> {response?.data?.success}</span>, {
									appearance: "success",
									autoDismiss: true,
								})
								toastRef.current.focus();
							} catch (error) {
								addToast(<span
									ref={toastRef}
									aria-label="Unable to send the email Please try again later"
									tabIndex={0}> Unable to send the email Please try again later</span>, {
									appearance: "success",
									autoDismiss: true,
								})
								setLoading(false)

							}
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit
						}) => (
							<div>
								{loading ? (
									<div className="spinner-border text-primary" role="status">
										<span className="sr-only">Loading...</span>
									</div>
								) : (
									<div className="m-4 p-4">
										<h1
											tabIndex={0}
											style={{ display: 'block' }}
											aria-label="Contact us"
										>
											Contact us{' '}
										</h1>{' '}
										<p tabIndex={0}>Let us know how we can help.</p>
										<label tabIndex={0} htmlFor="name">
											Name
										</label>
										<TextFieldGroup
											name="name"
											id="name"
											type="text"
											value={values.name}
											onChange={handleChange}
											error={nameValidation(touched, errors)}
										/>
										<label tabIndex={0} htmlFor="mail">
											Email
										</label>
										<TextFieldGroup
											name="mail"
											id="mail"
											type="text"
											value={values.mail}
											onChange={handleChange}
											error={mailValidation(touched, errors)}
										/>
										<label tabIndex={0} htmlFor="subject">
											Subject
										</label>
										<TextFieldGroup
											name="subject"
											id="subject"
											type="text"
											value={values.subject}
											onChange={handleChange}
											error={
												subjectValidation(touched, errors)
											}
										/>
										<label tabIndex={0} htmlFor="message">
											Message
										</label>
										<textarea
											name="message"
											id="message"
											type="textArea"
											value={values.message}
											onChange={handleChange}
											onBlur={handleBlur}
											className="form-control"
											style={
												messageValidation(touched, errors)
											}
										></textarea>
										<p style={{ fontSize: '13px', color: 'red' }}>
											{touched.message && errors.message}
										</p>
										<button
											type="submit"
											onClick={() => {
												handleSubmit();
											}}
											className="btn btn-primary"
										>
											Send Message
										</button>{' '}
										<button
											className="btn btn-primary m-2"
											onClick={(e) => {
												e.preventDefault()
												history.push('/dashboard')
											}}
										>
											Cancel{' '}
										</button>
									</div>
								)}
							</div>
						)}
					</Formik>
				</div>
			</div>
		</div>
	)
}

export default Contact
