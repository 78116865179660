import { LOAD_USER_SUCCESS, LOGOUT_USER } from "../../constants/Constants";
import { SIGNUP_SUCCESS, LOGIN_SUCCESS } from "../actions/types";
const initialState = {
  isAuth: false,
  user: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SIGNUP_SUCCESS:
      return {
        ...state,
        user: {},
        isAuth: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isAuth: true,
      };
    case LOAD_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isAuth: true,
      };
    case LOGOUT_USER:
      return {
        ...state,
        user: action.payload,
        isAuth: false,
      };
    default:
      return state;
  }
}
