import { Formik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'

import { Link } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { loginService } from '../../dataServices/Services'
import { loginUser } from '../../redux/actions/authActions'
import TextFieldGroup from '../common/TextFieldGroup'
const yup = require('yup')
const re = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[/d]{1,3}\.[0-9]{1,3}\.[/d]{1,3}\.[/d]{1,3}\])|(([a-zA-Z\-/d]+\.)+[a-zA-Z]{2,}))$/

const validationShcema = yup.object().shape({
	email: yup
		.string()
		.required('Email is required')
		.matches(re, 'Invalid email'),
	password: yup
		.string()
		.min(1, 'Minimum length is 1 for password')
		.required('Password is required'),
})
function Login({ loginUser, history, auth }) {
	const { addToast } = useToasts()
	const [loading, setLoading] = useState(false)
	const toastRef = useRef();
	const textFocus = useRef();
	const [showPass, setShowPass] = useState('Show')
	useEffect(() => {
		if (auth.isAuth) {
			history.push('/dashboard')
		}
	}, [auth, history])

	useEffect(() => {
		if(textFocus.current)
	  	textFocus.current.focus()
	}, [])
	

	return (
		<div className="login">
			{loading && (
				<div class="spinner-border text-primary ml-auto mr-auto" role="status">
					<span class="sr-only">Loading...</span>
				</div>
			)}
			{!loading && (
				<div className="container">
					<div className="row text-left">
						<Formik
							initialValues={{
								email: '',
								password: '',
							}}
							validationSchema={validationShcema}
							onSubmit={async (values, {resetForm}) => {
								try{
									setLoading(true)
									let user = {
										email: values.email,
										password: values.password,
									}
	
									let r = await loginService(user)
									if (r.status === 200) {
										setLoading(false)
										loginUser(r.data.user)
										localStorage.setItem('token', r.data.token)
										if (r.data.user.isTrainer === true) {
											history.push('/adminDashboard')
										} else {
											history.push('/dashboard')
										}
									} else{
										resetForm({ values: ''});
										addToast(<span ref={toastRef} aria-label={r.response.data.message} tabIndex={-1}>{r.response.data.message}</span>, {
											appearance: "error",
											autoDismiss: true,
											onDismiss: function () {
												document.getElementsByName("email")[ 0 ].focus();
											}
										});
										toastRef.current.focus()
										setLoading(false)
									}
								}catch(error){
									console.log({error})
								}

							}}
						>
							{({
								values,
								errors,
								touched,
								handleChange,
								handleBlur,
								handleSubmit,
								isSubmitting,
								setFieldValue,
							}) => (
								<div className="col-md-6 m-auto" aria-label="Login Form.">
									<h1 className="display-5 text-center text-danger">Log In</h1>
									<p ref={textFocus} tabIndex={0} className="lead text-center">
										Sign in to your ReVision Fitness Account
									</p>{' '}
									<label htmlFor="userEmail" tabIndex={1}>
										User name or Email
									</label>
									<TextFieldGroup
										placeholder="Enter Your Email Address."
										name="email"
										type="email"
										id="userEmail"
										onChange={handleChange}
										value={values.email}
										onBlur={handleBlur}
										error={touched.email && errors.email ? errors.email : ''}
									/>
									<label htmlFor="userPassword" tabIndex={3}>
										Password
									</label>
									<div className="input-group mb-3">
										{/* <TextFieldGroup
                    placeholder="Please enter your password."
                    id="userPassword"
                    name="password"
                    type="password"
                    onChange={handleChange}
                    value={values.password}
                    onBlur={handleBlur}
                    error={
                      touched.password && errors.password ? errors.password : ""
                    }
                  /> */}
										<input
											className="form-control"
											placeholder="Please enter your password."
											id="userPassword"
											name="password"
											type="password"
											onChange={handleChange}
											value={values.password}
											onBlur={handleBlur}
										/>
										<div className="input-group-append">
											<span
												tabIndex={0}
												aria-label="Show Password"
												style={{ cursor: 'pointer' }}
												onMouseOver={(e) => {
													let thiss = document.getElementById('basic-addon2')
													thiss.style.boxShadow = '2px'
													thiss.style.color = 'red'
												}}
												onMouseOut={(e) => {
													let thiss = document.getElementById('basic-addon2')
													thiss.style.boxShadow = '0'
													thiss.style.color = 'black'
												}}
												className="input-group-text"
												id="basic-addon2"
												onClick={(e) => {
													let x = document.getElementById('userPassword')

													if (x.type === 'password') {
														x.type = 'text'
														setShowPass('Hide')
													} else {
														x.type = 'password'
														setShowPass('Show')
													}
												}}
											>
												{showPass}
											</span>
										</div>{' '}
									</div>{' '}
									<p style={{ color: 'red' }}>
										{' '}
										{touched.password && errors.password ? errors.password : ''}
									</p>
									<button
										type="submit"
										onClick={handleSubmit}
										className="btn btn-danger btn-block mt-4"
									>
										Login
									</button>{' '}
									<a href="/forget-password">Forgot password ?</a>
									<Link className="btn btn-danger btn-block mt-4" to="/">
										{' '}
										Cancel
									</Link>
								</div>
							)}
						</Formik>
					</div>
				</div>
			)}
		</div>
	)
}
const mapDispatchToProps = (dispatch) => {
	return {
		loginUser: (user) => {
			dispatch(loginUser(user))
		},
	}
}
const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(Login)
