import React, { useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { BASE_URL } from "../../constants/Constants";
import { updateNote } from "../../redux/actions/noteActions";
import Routes from "../../constants/routes";
function AdminDashBoard({ auth, messages, history, note, saveNote }) {
  const { addToast } = useToasts();
  const [showMessage, setShowMessage] = useState(false);
  const [messageText, setMessageText] = useState("");
  const [showNote, setShowNote] = useState(false);
  const [noteText, setNoteText] = useState(note.note);
  let token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  function validateMessage(messageText) {
    return messageText === ""
      ? "Send Message button Enter Text in Field to active"
      : "Send Message Button Click to Send Message";
  }

  function isValidMessageText(messageText) {
    return messageText === "" ? true : false;
  }


  return (
    <div>
      <h1>Admin Dashboard</h1>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: '8px',
        }}
      >
      <button
        onClick={(e) => {
          history.push("/addClassroom");
        }}
        className="btn btn-primary"
      >
        Add Classroom
      </button>{" "}
      <button
        onClick={(e) => {
          history.push("/addStudio");
        }}
        className="btn btn-primary"
      >
        Add Studio
      </button>{" "}
      <button
        onClick={(e) => {
          history.push("/add-fitness-plan");
        }}
        className="btn btn-primary"
      >
        Add New Fitness Plan
      </button>{" "}
      <button
        onClick={(e) => {
          history.push("/add-health-and-fitness");
        }}
        className="btn btn-primary"
      >
        Add New Health And Fitness Guide
      </button>{" "}
      <button
        onClick={(e) => {
          history.push("/add-recommended-equipments");
        }}
        className="btn btn-primary"
      >
        Add New Recommended Product
      </button>{" "}
      <button
        onClick={(e) => {
          history.push("/add-faq-category");
        }}
        className="btn btn-primary"
      >
        Add New FAQ Category
      </button>{" "}
      <button
        className="btn btn-primary"
        aria-label="Edit Trainers Note Button"
        onClick={() => setShowNote(!showNote)}
      >
        Edit Trainers note
      </button>{" "}
      <button
        className="btn btn-primary"
        aria-label="Add message Button"
        onClick={() => setShowMessage(!showMessage)}
      >
        Add Message
      </button>{" "}
      <button
        onClick={(e) => {
          history.push("/users");
        }}
        className="btn btn-primary"
      >
        List Users
      </button>
      <button
        onClick={(e) => {
          history.push(Routes.addPushNotification);
        }}
        className="btn btn-primary"
      >
        Add Push Notification
      </button>
      {showNote && (
        <div
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <div
            style={{
              width: "90%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 50,
            }}
          >
            <label tabIndex={0} htmlFor="trainer note">
              Trainers Note
            </label>
            <textarea
              name="trainer note"
              id="trainer note"
              type="textArea"
              className="form-control"
              value={noteText}
              onChange={(e) => setNoteText(e.target.value)}
            ></textarea>
            <button
              className="btn btn-primary"
              aria-label="Save Note Changes"
              style={{ marginTop: 10 }}
              onClick={() => {
                saveNote(noteText);
                addToast("Note Updated Successfully", {
                  appearance: "success",
                  autoDismiss: true,
                });
              }}
            >
              Save Changes
            </button>{" "}
          </div>
        </div>
      )}
      {showMessage ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 50,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "90%",
            }}
          >
            <input
              className="form-control md-2"
              placeholder="Please enter your Message"
              id="sendMessage"
              name="message"
              type="text"
              value={messageText}
              onChange={(e) => setMessageText(e.target.value)}
            />
            <button
              disabled={isValidMessageText(messageText)}
              style={{ width: 100 }}
              className="btn btn-primary"
              aria-label={
                validateMessage(messageText)
              }
              onClick={() => {
                axios
                  .post(
                    `${BASE_URL}messages/add`,
                    {
                      userId: auth.user._id,
                      text: messageText,
                    },
                    config
                  )
                  .then((response) => {
                    if (response.status === 200) {
                      console.log(response);
                      addToast("Message Sent Successfully", {
                        appearance: "success",
                        autoDismiss: true,
                      });
                      setMessageText("");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }}
            >
              <i class="fa fa-paper-plane"> SEND</i>
            </button>
          </div>
        </div>
      ) : null}
      </div>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    saveNote: (note) => {
      dispatch(updateNote(note));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    messages: state.messages.messages,
    auth: state.auth,
    note: state.note,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashBoard);



