/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import SingleEntry from './SingleEntry'
import { connect } from 'react-redux'
import { loadSinglePlan } from '../../dataServices/Services'
function SingleFitnessPlan({ history, auth }) {
	const { id } = useParams()
	const [plan, setPlan] = useState([])
	const fetchPlan = async () => {
		const r = await loadSinglePlan(id)
		if (r.status === 200) {
			setPlan(r.data)
		} else {
			alert('Network Error')
		}
	}

	useEffect(() => {
		fetchPlan()
	}, [])
	return (
		<div className="container">
			<h1 tabIndex={0} style={{ display: 'block' }} aria-label="Fitness Plan">
				{plan.plan && plan.plan.title}
			</h1>{' '}
			<p className="card-text" tabIndex={0}>
				{plan.plan && plan.plan.description}
			</p>
			{auth.user.isTrainer && (
				<button
					tabIndex={0}
					onClick={(e) => {
						history.push(`/add-enteries/${id}`)
					}}
					className="btn btn-primary"
				>
					Add New Fitness Plan Enteries
				</button>
			)}
			{plan.plan &&
				plan.plan.enteries.map((entry) => (
					<SingleEntry
						title={entry.heading}
						description={entry.enteryDescription}
						days={entry.days}
						auth={auth}
						pid={id}
						eid={entry._id}
						fetchplan={fetchPlan}
					/>
				))}
		</div>
	)
}
const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	}
}

export default connect(mapStateToProps)(SingleFitnessPlan)
