export const GET_CLASSROOMS = 'GET_CLASSROOMS'
export const GET_STUDIOS = 'GET_STUDIOS'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCES'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const GET_PLANS = 'GET_PLANS'
export const UPDATE_NOTE = 'UPDATE_NOTE'
export const GET_HEALTH_AND_FITNESS = 'GET_HEALTH_AND_FITNESS'
export const GET_FAQ = 'GET_FAQ'
export const GET_FAQ_CATEGORY = 'GET_FAQ_CATEGORY'
export const GET_RECOMMENDED_EQUIPMENTS = 'GET_RECOMMENDED_EQUIPMENTS'
