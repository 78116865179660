import React, { useState, useCallback, useEffect } from 'react';
import { Form, Input, Select, Checkbox, DatePicker } from 'antd';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import { addPushNotication } from "../../dataServices/Services";
import { useToasts } from "react-toast-notifications";
import "../../assets/css/pushNotification.css";
import { platformNames, selectPlatform, requiredField, requiredLength, placeHolder, status, errorConstants, successConstants } from "../../constants/Constants";


const AddPushNotification = () => {
    const [showDatePicker, setShowDatePicker] = useState(false);
    const history = useHistory();
    const { addToast } = useToasts();

    const disabledDate = current => current && current < moment().startOf('day');
    const onFinish = async (values) => {
        try {
            const { title, message, platforms, dateAndTime } = values;
            const platform = platforms.includes(platformNames.both) ? [platformNames.android, platformNames.ios] : [platforms];
            let dateFormat = dateAndTime ? moment.utc(dateAndTime).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : moment.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

            const requestData = {
                title: title,
                message: message,
                platform: platform,
                dateAndTime: dateFormat,
            };

            await addPushNotication(requestData);
            const toastRef = React.createRef();
            addToast(
            <span
                ref={toastRef}
                aria-live="polite"
            >
                {successConstants.pushNotification.add}
            </span>,
            {
                appearance: 'success',
                autoDismiss: true,
                onDismiss: () => {
                    toastRef.current.focus();
                }
            }
        );
            history.goBack();
        } catch (error) {
            addToast(errorConstants.pushNotificationError, {
                appearance: status.error,
                autoDismiss: true,
            });
        }
    };

    const handleCheckboxChange = useCallback((e) => {
        setShowDatePicker(e.target.checked);
    }, []);

    const optionsWithAria = selectPlatform.map((option, index) => ({
  ...option,
  'aria-posinset': index + 1,
  'aria-setsize': selectPlatform.length,
}));


    const formItemLayout = {
        labelCol: {
            span: 24,
            style: { textAlign: 'center' },
        },
        wrapperCol: {
            span: 24,
        },
    };


    return (
        <>
            <Form onFinish={onFinish} layout="vertical" className='container' {...formItemLayout}>
            <h2 tabIndex={0}>Add Push Notification</h2>
                <Form.Item label={<span tabIndex={0}>Title</span>} name="title"
                    rules={[{ required: true, message: requiredField.inputTitleRequired },
                    { max: 50, message: requiredLength.titleLength }]}
                >
                    <Input placeholder={placeHolder.inputTitle} tabIndex={0} />
                </Form.Item>

                <Form.Item label={<span tabIndex={0}>Message</span>} name="message"
                    rules={[{ required: true, message: requiredField.inputMessageRequired },
                    { max: 1000, message: requiredLength.messageLength }
                    ]}>
                    <Input.TextArea placeholder={placeHolder.inputMessage} tabIndex={0} />
                </Form.Item>

                <Form.Item label={<span tabIndex={0}>Platform</span>} name="platforms" rules={[{ required: true, message: requiredField.inputPlatformRequired }]}>
                    <Select maxCount={1} placeholder={placeHolder.inputPlatform} tabIndex={0} options={optionsWithAria}/>
                </Form.Item>
                <Form.Item valuePropName="checked" name="scheduleChecked">
                    <Checkbox onClick={handleCheckboxChange} tabIndex={0} onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                            e.preventDefault();
                            e.target.focus();
                            e.target.click();
                        }
                    }}>Scheduled Date and Time</Checkbox>
                </Form.Item>


                {showDatePicker && (
                    <Form.Item label={<span tabIndex={0}>Date</span>} name="dateAndTime" rules={[{ required: true, message: requiredField.inputDateRequired }]}>
                        <DatePicker format="Do-MMMM-YYYY h:mmA" showTime placeholder={placeHolder.inputDate} tabIndex={0} disabledDate={disabledDate} />
                    </Form.Item>
                )}

                <Form.Item  >
                    <button type="submit" className="btn btn-primary" tabIndex={0}>
                        Add Push Notification
                    </button>

                    <button type="button" className="btn btn-primary m-2" onClick={() => history.goBack()} tabIndex={0} onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                            e.preventDefault();
                            e.target.focus();
                            e.target.click();
                        }
                    }}>
                        Cancel
                    </button>
                </Form.Item>
            </Form>
        </>
    );
};

export default AddPushNotification;
