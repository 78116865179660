import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";

import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { sendOTP, verifyOTP } from "../../dataServices/Services";
import TextFieldGroup from "../common/TextFieldGroup";


const yup = require("yup");
const re =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validationShcema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .matches(re, "Invalid email"),
});
const codeShcema = yup.object().shape({
  code: yup.string().required("Please Enter 5 digit code"),
});

function ForgetPassword({ history }) {
  const [ loading, setLoading ] = useState(false);
  const [, setCode ] = useState();
  const [ sent, setSent ] = useState(false);
  const [ email, setEmail ] = useState("");
  const { addToast } = useToasts()
  const toastRef = useRef()
  const makeCode = () => {
    let result = "";
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let charactersLength = characters.length;
    for (let i = 0; i < 5; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  useEffect(() => {
    setCode(makeCode());
  }, []);
  return (
    <div className="login">
      {loading && (
        <div class="spinner-border text-primary ml-auto mr-auto" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      )}
      {!loading && (
        <div className="container">
          <div className="row text-left">
            {!sent ? (
              <Formik
                initialValues={{
                  email: "",
                }}
                validationSchema={validationShcema}
                onSubmit={async (values) => {
                  try {
                    setLoading(true);
                    const { email } = values;
                    const response = await sendOTP(email);
                    if (response.status === 200) {
                      setEmail(values.email);
                      addToast(<span ref={toastRef} aria-label={response.data.success} tabIndex={-1}>{response.data.success}</span>, {
                        appearance: "success",
                        autoDismiss: true,
                      });
                      toastRef.current.focus()
                      setSent(true);
                    } else {
                      addToast(<span ref={toastRef} aria-label={response.response.data.error} tabIndex={-1}>{response.response.data.error}</span>, {
                        appearance: "error",
                        autoDismiss: true,
                      });
                      toastRef.current.focus()
                    }

                    setLoading(false);

                  } catch (error) {
                    // alert(error.message
                    console.log({ error });
                    setLoading(false);
                  }

                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <div className="col-md-6 m-auto" aria-label="Login Form.">
                    <h1 tabIndex={0} className="display-5 text-center text-danger">
                      Password Reset
                    </h1>
                    <p tabIndex={0} className="lead text-center">
                      Please enter the email associated with your ReVision Fitness account and a 5 digit code will be sent to reset your password.
                    </p>{" "}
                    <label htmlFor="userEmail" tabIndex={0}>
                      Email
                    </label>
                    <TextFieldGroup
                      name="email"
                      type="email"
                      id="userEmail"
                      onChange={handleChange}
                      value={values.email}
                      onBlur={handleBlur}
                      error={touched.email && errors.email ? errors.email : ""}
                    />
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      className="btn btn-danger btn-block mt-4"
                    >
                      Get Code
                    </button>{" "}
                    <Link className="btn btn-danger btn-block mt-4" to="/login">
                      {" "}
                      Cancel
                    </Link>
                  </div>
                )}
              </Formik>
            ) : (
              <Formik
                initialValues={{
                  code: "",
                }}
                validationSchema={codeShcema}
                onSubmit={async (values) => {
                  try {
                    setLoading(true);
                    const data = {
                      email,
                      otp: values.code,
                    }
                    const response = await verifyOTP(data)
                    if (response !== "NETWORK_ERROR") {
                      setLoading(false);
                      history.push({
                        pathname: '/new-password',
                        state: email
                      })
                    } else {
                      addToast(<span ref={toastRef} aria-label="Invalid OTP please check your email for correct OTP" tabIndex={-1}>Invalid OTP please check your email for correct OTP</span>, {
                        appearance: "error",
                        autoDismiss: true,
                      });
                      toastRef.current.focus()
                      setLoading(false);
                    }
                  } catch (error) {
                    console.log({ error })
                    addToast(<span ref={toastRef} aria-label="Invalid OTP please check your email for correct OTP" tabIndex={-1}>Invalid OTP please check your email for correct OTP</span>, {
                      appearance: "error",
                      autoDismiss: true,
                    });
                    toastRef.current.focus()
                    setLoading(false)
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <div className="col-md-6 m-auto" aria-label="Login Form.">
                    <h1 className="display-5 text-center text-danger">
                      Password Reset
                    </h1>
                    <p className="lead text-center">
                      Enter Code Received through Email
                    </p>{" "}
                    <label htmlFor="codeHtml" tabIndex={0}>
                      Code
                    </label>
                    <TextFieldGroup
                      placeholder="Enter Code here."
                      name="code"
                      type="text"
                      id="codeHtml"
                      onChange={handleChange}
                      value={values.code}
                      onBlur={handleBlur}
                      error={touched.code && errors.code ? errors.code : ""}
                    />
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      className="btn btn-danger btn-block mt-4"
                    >
                      Match Code
                    </button>{" "}
                    <button
                      className="btn btn-danger btn-block mt-4"
                      onClick={() => {
                        setSent(false);
                      }}
                    >
                      Did not receive Code send again
                    </button>
                    <Link className="btn btn-danger btn-block mt-4" to="/login">
                      {" "}
                      Cancel
                    </Link>
                  </div>
                )}
              </Formik>
            )}
          </div>
        </div>
      )
      }
    </div >
  );
}

export default ForgetPassword;
