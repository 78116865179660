/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import TextFieldGroup from '../common/TextFieldGroup'
import { Formik } from 'formik'
import { updateSingleEntery, loadSinglePlan } from '../../dataServices/Services'
import { useParams } from 'react-router-dom'

import { connect } from 'react-redux'
import { descriptionValidation, titleValidation } from '../../constants/Constants'
const yup = require('yup')
const validationSchema = yup.object().shape({
	title: yup.string().required('Title is required'),
	description: yup.string().required('Description is required'),
	daylabel: yup.string(),
})
function EditEntry({ history, plans }) {
	const { pid, eid } = useParams()
	const [loading, setLoading] = useState(false)
	const [entery, setEntery] = useState()

	const fetchEntry = async () => {
		const r = await loadSinglePlan(pid)
		let entry = r.data.plan

		//entry = entry.filter((plan) => plan._id === pid)
		entry = entry.enteries.filter((en) => en._id === eid)
		console.log(entry)
		let e = entry[0]

		setEntery(e)
	}

	useEffect(() => {
		fetchEntry()
	}, [])

	return (
		<div>
			<div className="container">
				<div>
					<div className="col-md-6 m-auto"></div>{' '}
					<Formik
						validationSchema={validationSchema}
						enableReinitialize={true}
						initialValues={{
							title: entery ? entery.heading : '',
							description: entery ? entery.enteryDescription : '',
						}}
						onSubmit={async (values) => {
							setLoading(true)

							const { title, description } = values

							let r = await updateSingleEntery(pid, eid, {
								heading: title,
								enteryDescription: description,
								days: entery.days,
							})
							console.log(r)
							if (r.status === 200) {
								alert(r.data.message)
								setLoading(false)
							} else {
								setLoading(false)
								alert('Network Error')
							}
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
						}) => (
							<div>
								{loading ? (
									<div class="spinner-border text-primary" role="status">
										<span class="sr-only">Loading...</span>
									</div>
								) : (
									<div>
										<label tabIndex={0} htmlFor="title">
											Title
										</label>
										<TextFieldGroup
											name="title"
											id="title"
											type="text"
											value={values.title}
											onChange={handleChange}
											error={titleValidation(touched, errors)}
										/>
										<label tabIndex={0} htmlFor="description">
											Description
										</label>
										<textarea
											name="description"
											id="description"
											type="textArea"
											value={values.description}
											onChange={handleChange}
											className="form-control"
											style={
												descriptionValidation(touched, errors)
											}
										></textarea>
										<p style={{ fontSize: '13px', color: 'red' }}>
											{touched.description && errors.description}
										</p>

										<button
											type="submit"
											onClick={(e) => {
												handleSubmit()
											}}
											className="btn btn-primary m-2"
										>
											Update Entery
										</button>
										<button
											className="btn btn-primary m-2"
											onClick={(e) => {
												e.preventDefault()
												history.push(`/single-plan/${pid}`)
											}}
										>
											Cancel{' '}
										</button>
									</div>
								)}
							</div>
						)}
					</Formik>
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		plans: state.plans,
		auth: state.auth,
	}
}

export default connect(mapStateToProps)(EditEntry)
