/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import AudioPlayer from 'react-h5-audio-player'
import { useParams } from 'react-router-dom'
import {
	loadSingleStudio,
	loadStudiosService,
	loadSingleStudioFav,
	addStudioFav,
	removeStudioFav,
} from '../../dataServices/Services'
import isEmpty from '../../validation/is-empty'
import { useToasts } from 'react-toast-notifications'

import Axios from 'axios'
import TextFieldGroup from '../common/TextFieldGroup'
import { BASE_URL, NETWORK_ERROR } from '../../constants/Constants'
import SingleComment from './SingleComment'
import { getStudios } from '../../redux/actions/studioAction'
import { connect } from 'react-redux'
import DropzoneInput from '../input/DropzoneInput'
function SingleStudio({ getStudios, auth, history }) {
	let token = localStorage.getItem('token')
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	}
	const { addToast } = useToasts()
	const [showComments, setShowComments] = useState(false)
	const [isPlaying, setIsPlaying] = useState(false)
	const [comment, setCommentText] = useState('')
	const [fav, setFav] = useState({})
	const [like, setLike] = useState('')
	const [currentUserLike, setCurrentUserLike] = useState(false)
	const [studio, setStudio] = useState({})
	const { id } = useParams()
	const [audioFile, setAudioFile] = useState(null)
	const [loading, setLoading] = useState(false)
	const player = useRef([])
	let nowPlayingRef = useRef()
	let singlePlayerRef = useRef()
	const fetchSingleStudio = async () => {
		let r = await loadSingleStudio(id)
		setCurrentUserLike(
			Boolean(
				r.data.studio.likes.find(function (l) {
					return l.userId === auth.user._id
				})
			)
		)
		setLike(
			r.data.studio.likes.filter(function (l) {
				return l.userId === auth.user._id
			})[0]
		)

		if (r.status === 200) {
			setStudio(r.data.studio)
			player.current = new Array(r.data.studio.audios.length)
		}
	}
	const fetchStudioFav = async () => {
		let r = await loadSingleStudioFav(auth.user._id, id)

		if (r !== NETWORK_ERROR) {
			if (r.data.favorite) {
				setFav(r.data.favorite)
			} else {
				setFav({})
			}
		}
	}
	useEffect(() => {
		fetchSingleStudio()
		fetchStudioFav()
	}, [])
	const deleteComment = async (classId, commentId) => {
		try {
			Axios({
				method: 'delete',
				url: `${BASE_URL}studios/comments/remove/${classId}/${commentId}`,
				headers: { Authorization: `Bearer ${token}` },
			}).then((res) => {
				alert('Comment Deleted ')
			})
			let r = await loadStudiosService()
			getStudios(r.data.classrooms)
			fetchSingleStudio()
		} catch {
			alert('Comment not delelted ')
		}
	}

	if (!loading) {
		return (
			<div>
				{studio ? (
					<div>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							{/* <div style={{ width: "50%" }}>
              {" "}
              <Select
                aria-label="Reference Classroom Links"
                id="select"
                tabIndex={0}
                isClearable
                options={
                  studio.refClassrooms &&
                  studio.refClassrooms.map((c) => {
                    return { values: c.classroomId, label: c.name };
                  })
                }
                onChange={(e) => {
                  if (e) {
                    history.push(`/classroom/${e.values}`);
                  } else {
                    alert("Please select a classroom ");
                  }
                }}
              />
            </div> */}
						</div>{' '}
						<div className="row p-3">
							<div className="col-md-6 md-auto">
								{' '}
								<div className="d-flex justify-content-center m-2">
									{' '}
									<div className="card" style={{ width: '100%' }}>
										<div className="card-body">
											<h5 className="card-title" tabIndex={0} aria-label="">
												{studio.title}
											</h5>

											<p
												className="card-text"
												tabIndex={0}
												aria-label="Description"
											>
												{studio.description}
											</p>
											<div className="card-text">
												<h5 tabIndex={0}>Pre Workout Information Session</h5>
												{studio && studio.audio_description ? (
													<AudioPlayer
														src={`${studio.audio_description}`}
														layout="horizontal"
														onPlay={() => {
															nowPlayingRef &&
																nowPlayingRef.current &&
																nowPlayingRef.current.audio.current.pause()
															let play
															if (isPlaying) {
																setIsPlaying(false)
																play = isPlaying
															}
															if (!isPlaying) {
																setIsPlaying(true)
															}
															nowPlayingRef.current = singlePlayerRef.current
														}}
														ref={singlePlayerRef}
														progressJumpSteps={{
															backward: 15000,
															forward: 15000,
														}}
													/>
												) : (
													'No Audio'
												)}
											</div>

											<div
												style={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
												}}
											>
												{' '}
												{isEmpty(fav) ? (
													<button
														className="btn btn-danger m-1"
														aria-label="Add in Favorites"
														onClick={async (e) => {
															e.preventDefault()
															let fav = {
																userId: auth.user._id,
																refId: id,
																name: studio.title,
															}
															console.log(fav)
															await addStudioFav(fav)
															addToast('Studio added to Favorites', {
																appearance: 'success',
																autoDismiss: true,
															})
															fetchStudioFav()
														}}
													>
														<i className="fa fa-heart"></i>
													</button>
												) : (
													<button
														className="btn btn-danger m-1"
														aria-label="Remove from Favorites"
														onClick={async (e) => {
															e.preventDefault()
															await removeStudioFav(fav._id)
															addToast('Studio removed from favorites', {
																appearance: 'error',
																autoDismiss: true,
															})
															fetchStudioFav()
														}}
													>
														<i className="fa fa-heart-o"></i>
													</button>
												)}
												{currentUserLike ? (
													<button
														className="btn btn-primary m-1"
														onClick={async () => {
															Axios({
																method: 'delete',
																url: `${BASE_URL}studios/likes/remove/${studio._id}/${like._id}`,
																headers: { Authorization: `Bearer ${token}` },
															}).then((res) => {
																setCurrentUserLike(false)
															})
															let r = await loadStudiosService()
															getStudios(r.data.studios)
															fetchSingleStudio()
														}}
														aria-label="Dislike"
													>
														{' '}
														<i className="fa fa-thumbs-down"></i>{' '}
														<span className="m-1 ml-3">
															{studio.likes && studio.likes.length}
														</span>
													</button>
												) : (
													<button
														className="btn btn-primary m-1"
														onClick={async () => {
															Axios.post(
																`${BASE_URL}studios/likes/add/${studio._id}`,
																{ userId: auth.user._id },
																config
															).then((res) => {
																console.log(res)
															})
															let r = await loadStudiosService()
															getStudios(r.data.studios)
															fetchSingleStudio()
														}}
														aria-label="Like"
													>
														{' '}
														<i className="fa fa-thumbs-up"></i>{' '}
														<span className="m-1 ml-3">
															{studio.likes && studio.likes.length}
														</span>
													</button>
												)}{' '}
												<button
													className="btn btn-primary m-1"
													onClick={() => {
														setShowComments(!showComments)
													}}
													aria-label="Comments"
												>
													<i className="fa fa-comment"></i>
													<span className="m-1 ml-3">
														{studio.comments && studio.comments.length}
													</span>
												</button>
											</div>

											{showComments && (
												<div>
													{studio.comments && studio.comments.length > 0 ? (
														<div className=" border m-2 p-3">
															<div aria-label="Add new Comment Section">
																{' '}
																<TextFieldGroup
																	name="comment"
																	type="text"
																	value={comment}
																	onChange={(e) => {
																		setCommentText(e.target.value)
																	}}
																	placeholder="Add Comment"
																/>
																<br></br>
																<button
																	className="btn btn-secondary"
																	aria-label="Add New Comment"
																	onClick={async () => {
																		if (comment.trim() !== '') {
																			Axios.post(
																				`${BASE_URL}studios/comments/add/${studio._id}`,
																				{
																					userId: auth.user._id,
																					text: comment,
																					firstName: auth.user.firstName,
																					lastName: auth.user.lastName,
																				},
																				config
																			)
																			let r = await loadStudiosService()
																			getStudios(r.data.studios)
																			setCommentText("")
																			fetchSingleStudio()
																		} else {
																		}
																	}}
																>
																	Add Comment
																</button>
															</div>

															<hr></hr>
															<h5>Comments</h5>
															{studio.comments.map((c) => {
																return (
																	<SingleComment
																		name={c.firstName + ' ' + c.lastName}
																		text={c.text}
																		time={Date.parse(c.createdAt)}
																		userId={auth.user._id}
																		classId={studio._id}
																		commentId={c._id}
																		commentUserId={c.userId}
																		delete={deleteComment}
																	/>
																)
															})}
														</div>
													) : (
														<div className="border m-2 p-3">
															<TextFieldGroup
																name="comment"
																type="text"
																value={comment}
																onChange={(e) => {
																	setCommentText(e.target.value)
																}}
																placeholder="Add Comment"
															/>
															<br></br>
															<button
																className="btn btn-secondary"
																aria-label="Add New Comment"
																onClick={async () => {
																	if (comment.trim() !== '') {
																		Axios.post(
																			`${BASE_URL}studios/comments/add/${studio._id}`,
																			{
																				userId: auth.user._id,
																				text: comment,
																				firstName: auth.user.firstName,
																				lastName: auth.user.lastName,
																			},
																			config
																		)
																		let r = await loadStudiosService()
																		getStudios(r.data.studios)
																		setCommentText("")
																		fetchSingleStudio()
																	} else {
																	}
																}}
															>
																Add Comment
															</button>
														</div>
													)}
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-6 md-auto">
								<div
									className="card"
									style={{
										width: '100%',
										minHeight: '500px',
										overflowY: 'none',
									}}
								>
									<div className="card-body">
										{auth.user.isTrainer && (
											<>
												{' '}
												<DropzoneInput
													onDropComplete={(file) => setAudioFile(file)}
												/>
												<button
													className="btn btn-secondary"
													aria-label="Add New Audio In Studio"
													onClick={() => {
														const token = localStorage.getItem('token')
														if (audioFile) {
															let formData = new FormData()
															formData.append('audio', audioFile)
															setLoading(true)
															Axios({
																method: 'post',
																url: `${BASE_URL}studios/audio/add/${id}/`,
																data: formData,
																headers: {
																	'content-type': 'multipart/form-data',
																	Authorization: token,
																},
															})
																.then((r) => {
																	setLoading(false)
																	console.log(r)
																	if (r.status === 200) {
																		alert(r.data.message)
																		fetchSingleStudio()
																	}
																})
																.catch((e) => {
																	setLoading(false)
																	console.log(e)
																})
														} else {
															alert('Audio file is required')
														}
													}}
												>
													Add Audio
												</button>
											</>
										)}
										<h3 aria-label="Studio Session Workouts">
											Studio Session Workouts
										</h3>
										{studio.audios &&
											studio.audios.map((a, i) => {
												let name = a.name
												// name = name.split('#', 2)
												// let ext = name && name[1]
												// ext = ext && ext.split('.', 2)
												// console.log(ext && ext[1])
												// name = name[0] + '.' + (ext && ext[1])
												name = name.split('')
												name = name.reverse()
												name = name.join('')
												let ext = name.slice(0, 4)
												name = name.slice(18, name.length)
												name = ext + name
												name = name.split('')
												name = name.reverse()
												name = name.join('')

												return (
													<div key={a._id}>
														{/* <ReactPlayer
                            width="100%"
                            height="50px"
                            url={`${a.url}`}
                            controls
                          /> */}
														<label tabIndex={0}>{name}</label>
														<AudioPlayer
															src={`${a.url}`}
															// other props here
															progressJumpSteps={{
																backward: 15000,
																forward: 15000,
															}}
															onPlay={() => {
																console.log('ref is', nowPlayingRef)
																nowPlayingRef &&
																	nowPlayingRef.current &&
																	nowPlayingRef.current.audio.current.pause()
																if (isPlaying) {
																	setIsPlaying(false)
																}

																if (!isPlaying) {
																	setIsPlaying(true)
																}
																nowPlayingRef.current = player.current[i]
															}}
															ref={(el) => (player.current[i] = el)}
														/>
														{console.log('player ref', player)}
														{auth.user.isTrainer && (
															<button
																className="btn btn-danger m-3"
																onClick={(e) => {
																	e.preventDefault()
																	let token = localStorage.getItem('token')
																	const config = {
																		headers: {
																			Authorization: `Bearer ${token}`,
																		},
																	}
																	Axios.delete(
																		`${BASE_URL}studios/audio/remove/${id}/${a._id}`,
																		config
																	)
																		.then((r) => {
																			console.log(r)
																			if (r.status === 200) {
																				alert(r.data.message)
																				fetchSingleStudio()
																			}
																		})
																		.catch((e) => {
																			console.log(e)
																		})
																}}
															>
																Delete Audio
															</button>
														)}
													</div>
												)
											})}
									</div>
								</div>
							</div>
						</div>
						<div></div>
					</div>
				) : (
					<h1>Not Found</h1>
				)}
			</div>
		)
	} else {
		return (
			<div class="spinner-border text-primary" role="status">
				<span class="sr-only">Loading...</span>
			</div>
		)
	}
}
const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	}
}
const mapDispatchToProps = (dispatch) => {
	return {
		getStudios: (classrooms) => {
			dispatch(getStudios(classrooms))
		},
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(SingleStudio)
