/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import '../../assets/css/modal.css'

function Dashboard({  auth, history, note }) {
	const [ showModal, setShowModal ] = useState(false)

	useEffect(() => {
		if (auth.user.isTrainer) {
			history.push('/adminDashboard')
		}

		if (note) {
			setShowModal(true)
		}
	}, [ note ])
	return (
		<div>
			<div className="dashboard">
				<div className="container">
					<div className="row">
						<div className="col-md-12 col-lg-12 ">
							<h1 className="display-4">User Dashboard</h1>
							{auth && (
								<h1 tabIndex={0}>
									Welcome{' '}
									{auth.user
										? auth.user.firstName + ' ' + auth.user.lastName
										: ''}
								</h1>
							)}
							<div tabIndex={0} aria-label="">
								{' '}
								<Modal
									style={{ overlay: { backgroundColor: 'rgba(0,0,0,0.8)' } }}
									id="modal"
									isOpen={showModal}
									onRequestClose={() => setShowModal(false)}
								>
									<div
										className="float-right"
										tabIndex={0}
										aria-label="You are on Pop-up Modal on which you will be shown a message"
									>
										<button
											tabIndex={0}
											aria-label="You are on close modal "
											className="fa fa-times btn-danger"
											style={{ fontSize: '22px' }}
											onClick={(e) => {
												e.preventDefault()
												setShowModal(false)
											}}
										></button>{' '}
										<div
											tabIndex={0}
											aria-label="You are on Pop-up Message By Trainer"
										>
											<h3>Trainers Note</h3>{' '}
											{note ? (
												<h4 className="mt-4" tabIndex={0}>
													{note.note}
												</h4>
											) : (
												''
											)}
										</div>
									</div>
								</Modal>
							</div>
							<h3 tabIndex={0} className="text-center">
								Let’s start training!
							</h3>
							<p tabIndex={0} className="text-justify">
								Step into a fitness studio session to start enjoying our audio
								workouts. We cover everything from basic beginner sessions, to
								elite level training, all designed to improve your fitness.
								Don’t forget to leave a comment for each session. Your feedback
								will help us improve your experience, and your encouragement
								will help others in the community push for better fitness
							</p>
							<button
								aria-label="Fitness Studios Button"
								className="btn btn-danger"
								onClick={(e) => {
									e.preventDefault()
									history.push('/studios')
								}}
							>
								Fitness Studios
							</button>
							<h3 tabIndex={0} className="text-center">
								Brush Up On Your Skills
							</h3>
							<p tabIndex={0} className="mt-3 text-justify">
								If you are unsure of how to do any exercises found in our
								Fitness Studio, such as basic positioning for squats and
								push-ups, the classroom is a great place to find instructional
								lessons before you really jump into things. Use these lessons to
								learn new exercises and positions for our accessible studio
								sessions, classes at your local Fitness Center, or to build your
								own workout routines. We will always be updating our classroom
								and Fitness Studio, so be sure to check back regularly.
							</p>
							<button
								className="btn btn-danger"
								aria-label="Class Room Button"
								onClick={(e) => {
									e.preventDefault()
									history.push('/classrooms')
								}}
							>
								Class Room
							</button>
							<h3 tabIndex={0} className="text-center">
								Let’s Make a Plan
							</h3>
							<p tabIndex={0} className="mt-3 text-justify">
								Have a fitness goal? Looking for a routine to follow? Need some
								tips to help you along the way? Take a look at our Fitness Plans
								section for schedules, journals, and recommendations on how to
								lose weight, tone up, and more!
							</p>
							<button
								className="btn btn-danger"
								aria-label="Fitness Plan Button"
								onClick={(e) => {
									e.preventDefault()
									history.push('/fitness-plans')
								}}
							>
								Fitness Plans
							</button>

							{/*<button
								style={{ marginTop: 30, marginBottom: 30 }}
								className="btn btn-danger"
								aria-label="Add message Button"
								onClick={() => setShowMessage(!showMessage)}
							>
								Add Message
							</button>
							{showMessage ? (
								<div
									style={{
										width: '',
										display: 'flex',
										flexDirection: 'row',
									}}
								>
									<input
										className="form-control md-2"
										placeholder="Please enter your Message"
										id="sendMessage"
										name="message"
										type="text"
										value={messageText}
										onChange={(e) => setMessageText(e.target.value)}
									/>
									<button
										disabled={messageText === '' ? true : false}
										style={{ width: 100 }}
										className="btn btn-primary"
										aria-label={
											messageText === ''
												? 'Send Message button Text in Field to active'
												: 'Send Message Button Click to Send Message'
										}
										onClick={() => {
											axios
												.post(
													`${BASE_URL}messages/add`,
													{
														userId: auth.user._id,
														text: messageText,
													},
													config
												)
												.then((response) => {
													if (response.status === 200) {
														console.log(response)
														addToast('Message Sent Successfully', {
															appearance: 'success',
															autoDismiss: true,
														})
														setMessageText('')
													}
												})
												.catch((error) => {
													console.log(error)
												})
										}}
									>
										<i class="fa fa-paper-plane"> SEND</i>
									</buon>
							<br></br>ton>
								</div>
							) : null}*/}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
const mapStateToProps = (state) => {
	return {
		note: state.note,
		messages: state.messages.messages,
		auth: state.auth,
	}
}
export default connect(mapStateToProps)(Dashboard)
