/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'

import { connect } from 'react-redux'
import TextFieldGroup from '../common/TextFieldGroup'
import { BASE_URL } from '../../constants/Constants'
import { loadSingleMessageService } from '../../dataServices/Services'

import Axios from 'axios'

import SingleComment from './SingleComment'

function SingleMessage({ singleMessage, auth, fetchAll }) {
	let token = localStorage.getItem('token')
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	}

	const [ showComments, setShowComments ] = useState(false)
	const [ currentUserLike, setCurrentUserLike ] = useState(false)
	const [ message, setmessage ] = useState({})
	const [ comment, setCommentText ] = useState('')
	const [ like, setLike ] = useState('')

	const deleteComment = async (messageId, commentId) => {
		try {
			await Axios({
				method: 'delete',
				url: `${BASE_URL}messages/comments/remove/${messageId}/${commentId}`,
				headers: { Authorization: `Bearer ${token}` },
			})
			alert('Comment Deleted ')
			await loadSingleMessageService(message._id)

			fetchmessage()
		} catch {
			alert('Comment not delelted ')
		}
	}
	const deleteMessage = async (messageId) => {
		try {
			await Axios({
				method: 'delete',
				url: `${BASE_URL}messages/remove/${messageId}`,
				headers: { Authorization: `Bearer ${token}` },
			})
			alert('Message Deleted ')
			fetchAll()
		} catch {
			alert('Message not delelted ')
		}
	}
	const fetchmessage = async () => {
		let r = await loadSingleMessageService(singleMessage._id)
		setCurrentUserLike(
			Boolean(
				r.data.messageBoard.likes.find(function (l) {
					return l.userId === auth.user._id
				})
			)
		)
		setLike(
			r.data.messageBoard.likes.filter(function (l) {
				return l.userId === auth.user._id
			})[ 0 ]
		)

		if (r.status === 200) {
			setmessage(r.data.messageBoard)
		}
	}

	useEffect(() => {
		fetchmessage()
	}, [])
	return (
		<div className="d-flex justify-content-center m-2">
			{' '}
			<div className="card" style={{ width: '100%' }}>
				<div className="card-body">
					<div className="card-text">
						<h5 tabIndex={0}>{message ? message.text : ''}</h5>
					</div>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						{currentUserLike ? (
							<button
								className="btn btn-primary "
								aria-label="Dis Like message"
								onClick={async (e) => {
									Axios({
										method: 'delete',
										url: `${BASE_URL}messages/likes/remove/${message._id}/${like._id}`,
										headers: { Authorization: `Bearer ${token}` },
									}).then((res) => {
										setCurrentUserLike(false)
									})
									await loadSingleMessageService(message._id)
									// getmessages(r.data.messages)
									fetchmessage()
								}}
							>
								{' '}
								<i className="fa fa-thumbs-down"></i>{' '}
								<span className="m-1 ml-3">
									{message.likes && message.likes.length}
								</span>
							</button>
						) : (
							<button
								className="btn btn-primary "
								aria-label="Like message"
								onClick={async () => {
									Axios.post(
										`${BASE_URL}messages/likes/add/${message._id}`,
										{ userId: auth.user._id },
										config
									)
									await loadSingleMessageService(message._id)
									// getmessages(r.data.messages)
									fetchmessage()
								}}
							>
								{' '}
								<i className="fa fa-thumbs-up"></i>{' '}
								<span className="m-1 ml-3">
									{message.likes && message.likes.length}
								</span>
							</button>
						)}{' '}
						<button
							className="btn btn-primary m-1"
							aria-label="Show Comments"
							onClick={() => {
								setShowComments(!showComments)
							}}
						>
							<i className="fa fa-comment"></i>
							<span className="m-1 ml-3">
								{message.comments && message.comments.length}
							</span>
						</button>
						{auth.user.isTrainer ? (
							<button
								className="btn btn-danger m-1"
								aria-label="Delete Messages Button"
								onClick={() => deleteMessage(message._id)}
							>
								<i className="fa fa-trash"></i>
							</button>
						) : null}
					</div>
					{showComments && (
						<div>
							{message.comments && message.comments.length > 0 ? (
								<div className=" border m-2 p-3">
									<div aria-label="Add new Comment Section">
										{' '}
										<TextFieldGroup
											name="comment"
											type="text"
											value={comment}
											onChange={(e) => {
												setCommentText(e.target.value)
											}}
											placeholder="Add Comment"
										/>
										<br></br>
										<button
											className="btn btn-secondary"
											aria-label="Add New Comment"
											onClick={async () => {
												if (comment.trim() !== '') {
													Axios.post(
														`${BASE_URL}messages/comments/add/${message._id}`,
														{
															userId: auth.user._id,
															text: comment,
															firstName: auth.user.firstName,
															lastName: auth.user.lastName,
														},
														config
													)
													 await loadSingleMessageService(message._id)
													// getmessages(r.data.messages)
													fetchmessage()
													setCommentText("")
												}
											}}
										>
											Add Comment
										</button>
									</div>

									<hr></hr>
									<h5>Comments</h5>
									{message.comments.map((c) => {
										return (
											<SingleComment
												name={c.firstName + ' ' + c.lastName}
												text={c.text}
												time={Date.parse(c.createdAt)}
												userId={auth.user._id}
												classId={message._id}
												commentId={c._id}
												commentUserId={c.userId}
												delete={deleteComment}
												isTrainer={auth.user.isTrainer}
											/>
										)
									})}
								</div>
							) : (
								<div className="border m-2 p-3">
									{' '}
									<TextFieldGroup
										name="comment"
										type="text"
										value={comment}
										onChange={(e) => {
											setCommentText(e.target.value)
										}}
										placeholder="Add Comment"
									/>
									<br></br>
									<button
										className="btn btn-secondary"
										aria-label="Add New Comment"
										onClick={async () => {
											if (comment.trim() !== '') {
												Axios.post(
													`${BASE_URL}messages/comments/add/${message._id}`,
													{
														userId: auth.user._id,
														text: comment,
														firstName: auth.user.firstName,
														lastName: auth.user.lastName,
													},
													config
												)
												await loadSingleMessageService(message._id)
												// getmessages(r.data.messages)
												fetchmessage()
												setCommentText("")

											}
										}}
									>
										Add Comment
									</button>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	)
}
const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	}
}
export default connect(mapStateToProps)(SingleMessage)
