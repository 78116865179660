import { GET_FAQ, GET_FAQ_CATEGORY } from "./types";
export const getFaq = (faq) => (dispatch) => {
    dispatch({
        type: GET_FAQ,
        payload: faq,
    });
};

export const getFaqCategory = (faq) => (dispatch) => {
    dispatch({
        type: GET_FAQ_CATEGORY,
        payload: faq,
    });
};
