import React, { useState } from 'react'
import TextFieldGroup from '../common/TextFieldGroup'
import { Formik } from 'formik'

import { addFaqCategory } from '../../dataServices/Services'
import { descriptionValidation, titleValidation } from '../../constants/Constants'

const yup = require('yup')
const validationSchema = yup.object().shape({
    title: yup.string().trim().required('Title is required'),
    description: yup.string().trim().required('Description is required'),
})
function AddFaqCategory({ history }) {
    const [ loading, setLoading ] = useState(false)

    return (
        <div>
            <div className="container">
                <div>
                    <div className="col-md-6 m-auto"></div>{' '}
                    <Formik
                        validationSchema={validationSchema}
                        initialValues={{ title: '', description: '' }}
                        onSubmit={async (values) => {
                            setLoading(true)
                            const { title, description } = values
                            let r = await addFaqCategory({ title:title.trim(), description:description.trim() })
                            if (r.status === 200) {
                                setLoading(false)
                                alert(r.data.success)
                                history.push('/faq')
                            } else {
                                setLoading(false)
                                alert('FAQ category not added successfully')
                            }
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => (
                            <div>
                                {loading ? (
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                ) : (
                                    <div>
                                        <label tabIndex={0} htmlFor="title">
                                            Title
                                        </label>
                                        <TextFieldGroup
                                            name="title"
                                            id="title"
                                            type="text"
                                            value={values.title}
                                            onChange={handleChange}
                                            error={titleValidation(touched, errors)}
                                        />
                                        <label tabIndex={0} htmlFor="description">
                                            Description
                                        </label>
                                        <textarea
                                            name="description"
                                            id="description"
                                            type="textArea"
                                            value={values.description}
                                            onChange={handleChange}
                                            className="form-control"
                                            style={
                                                descriptionValidation(touched, errors)
                                            }
                                        ></textarea>
                                        {
                                            touched.description && errors.description && (
                                                <p aria-label={errors.description} tabIndex={0} style={{ fontSize: '13px', color: 'red' }}>
                                                    {touched.description && errors.description}
                                                </p>
                                            )
                                        }
                                        <button
                                            type="submit"
                                            onClick={(e) => {
                                                handleSubmit()
                                            }}
                                            className="btn btn-primary m-2"
                                        >
                                            Create FAQ Category
                                        </button>
                                        <button
                                            className="btn btn-primary m-2"
                                            onClick={(e) => {
                                                e.preventDefault()
                                                history.push('/faq')
                                            }}
                                        >
                                            Cancel{' '}
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default AddFaqCategory
