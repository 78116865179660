/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "../../assets/css/favorites.css";
import {
  loadAllStudioFav,
  loadAllClassroomFav,
  loadAllHealthResourceFav,
  loadAllEquipmentFav,
} from "../../dataServices/Services";
function Favorites({ auth, history }) {
  const [ studioFavs, setStudioFavs ] = useState([]);
  const [ classroomFavs, setClassroomFavs ] = useState([]);
  const [ equipmentFav, setEquipmentFav ] = useState([])
  const [ healthFav, setHealthFav ] = useState([])
  const [ loadingC, setLoadingC ] = useState(false);
  const [ loadingS, setLoadingS ] = useState(false);


  const fetchStudioFav = async () => {
    setLoadingS(true);
    let r = await loadAllStudioFav(auth.user._id);

    if (r.data.favorites.length > 0) {
      setLoadingS(false);

      setStudioFavs(r.data.favorites);
    } else {
      setLoadingS(false);
    }
  };


  const fetchClassroomFav = async () => {
    setLoadingC(true);
    let r = await loadAllClassroomFav(auth.user._id);

    if (r.data.favorites.length > 0) {
      setClassroomFavs(r.data.favorites);
      setLoadingC(false);
    } else {
      setLoadingC(false);
    }
  };

  const fetchHealthAndFitnessFav = async () => {
    setLoadingC(true);
    let response = await loadAllHealthResourceFav();
    console.log(response);

    if (response.data.favorites.length > 0) {
      setHealthFav(response.data.favorites);
      setLoadingC(false);
    } else {
      setLoadingC(false);
    }
  };

  const fetchEquipmentsFav = async () => {
    setLoadingC(true);
    let response = await loadAllEquipmentFav();
    console.log({ response })

    if (response.data.newFavorite.length > 0) {
      setEquipmentFav(response.data.newFavorite);
      setLoadingC(false);
    } else {
      setLoadingC(false);
    }
  };
  useEffect(() => {
    fetchStudioFav();
    fetchClassroomFav();
    fetchEquipmentsFav();
    fetchHealthAndFitnessFav()
  }, []);
  return (
    <div>
      <h1 tabIndex={0}>Favorites</h1>
      <div className="row p-3">
        <div className="col-md-6 p-4">
          <h2 tabIndex={0}>Classrooms</h2>
          {classroomFavs && !loadingC ? (
            <div>
              {classroomFavs.length > 0 ? (
                classroomFavs.map((c) => {
                  return (
                    <div>
                      <span
                        tabIndex={0}
                        aria-label={`Name of the classroom is ${c.name} click to visit classroom`}
                        id="linkName"
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(`/classroom/${c.refId}`);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            history.push(`/classroom/${c.refId}`);

                          }
                        }}
                      >
                        {c.name}
                      </span>
                      <hr />
                    </div>
                  );
                })
              ) : (
                <div>
                  <p tabIndex={0} aria-label="No clasroom Found in Favorites">
                    No classrooms in Favorites
                  </p>
                </div>
              )}
            </div>
          ) : (
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          )}
        </div>
        <div className="col-md-6 p-4">
          <h2 tabIndex={0}>Studios</h2>
          {studioFavs && !loadingS ? (
            <div>
              {studioFavs.length > 0 ? (
                studioFavs.map((c) => {
                  return (
                    <div>
                      <span
                        tabIndex={0}
                        aria-label={`Name of the studio is ${c.name} click to visit studio`}
                        id="linkName"
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(`/studios/${c.refId}`);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            history.push(`/studios/${c.refId}`);

                          }
                        }}
                      >
                        {c.name}
                      </span>
                      <hr />
                    </div>
                  );
                })
              ) : (
                <div>
                  <p tabIndex={0} aria-label="No Studio Found in Favorites">
                    No Studios In Favorites
                  </p>
                </div>
              )}
            </div>
          ) : (
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          )}
        </div>
        <div className="col-md-6 p-4">
          <h2 tabIndex={0}>Recommended Products</h2>
          {equipmentFav && !loadingS ? (
            <div>
              {equipmentFav.length > 0 ? (
                equipmentFav.map((c) => {
                  return (
                    <div>
                      <span
                        tabIndex={0}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            history.push(`/recommended-equipments/${c.refId._id}`);

                          }
                        }}
                        aria-label={`Name of the equipment is ${c.refId.title} click to visit equipment`}
                        id="linkName"
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(`/recommended-equipments/${c.refId._id}`);
                        }}
                      >
                        {c.refId.title}
                      </span>
                      <hr />
                    </div>
                  );
                })
              ) : (
                <div>
                  <p tabIndex={0} aria-label="No products found in favorites">
                    No Products In Favorites
                  </p>
                </div>
              )}
            </div>
          ) : (
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          )}
        </div>
        <div className="col-md-6 p-4">
          <h2 tabIndex={0}>Health and Fitness Guides</h2>
          {healthFav && !loadingS ? (
            <div>
              {healthFav.length > 0 ? (
                healthFav.map((c) => {
                  return (
                    <div>
                      <span
                        tabIndex={0}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            history.push(`/health-resource/${c.refId._id}`);

                          }
                        }}
                        aria-label={`Name of the health and fitness guide is ${c.refId.title} click to visit the health and fitness guide`}
                        id="linkName"
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(`/health-resource/${c.refId._id}`);
                        }}
                      >
                        {c.refId.title}
                      </span>
                      <hr />
                    </div>
                  );
                })
              ) : (
                <div>
                  <p tabIndex={0} aria-label="No health and fitness found in favorites">
                    No health and fitness found in favorites
                  </p>
                </div>
              )}
            </div>
          ) : (
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(Favorites);
