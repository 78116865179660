import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import ProfilePic from "react-rounded-image";
import { useToasts } from "react-toast-notifications";
import DemoPic from "../../assets/Images/demoPic.jpg";
import "../../assets/css/modal.css";
import "../../assets/css/profile.css";

import Axios from "axios";
import { BASE_URL, NETWORK_ERROR, PASSWORD_REGEX } from "../../constants/Constants";
import { changePassword, loadUser, updatePaymentinfo, deleteAccount } from "../../dataServices/Services";
import { loadUserAction, logoutUserAction } from "../../redux/actions/authActions";
import store from "../../redux/store";
import TextFieldGroup from "../common/TextFieldGroup";
import TermsConditions from "../pages/TermsConditions";
function Profile({ logoutUserAction, auth, history }) {
  const [ passwordModalShow, setPasswordModalShow ] = useState(false);
  const [ paymentModalShow, setPaymentModalShow ] = useState(false);
  const [ termsModalShow, setTermsModalShow ] = useState(false);
  const [ height, setHeight ] = useState("");
  const [ weight, setWeight ] = useState("");
  const [ cardNumber, setCardNumber ] = useState("");
  const [ expiryMonth, setExpiryMonth ] = useState("");
  const [ expiryYear, setExpiryYear ] = useState("");
  const [ oldPassword, setOldPassword ] = useState();
  const [ newPassword, setNewPassword ] = useState();
  const [ confirmPassword, setConfirmPassword ] = useState();

  useEffect(() => {
    if (!passwordModalShow) {
      setConfirmPassword();
      setOldPassword();
      setNewPassword();
    }
  }, [ passwordModalShow ]);

  const [ loading, setLoading ] = useState(false);
  const { addToast } = useToasts();

  const updatePaymentInformation = async (cnum, month, year) => {
    try {
      const r = await updatePaymentinfo(auth.user._id, cnum, month, year, auth.user.subscription.customer);
      setLoading(false);
      if (r.status === 200) {
        setPaymentModalShow(false);
        if (r.data.user) {
          let token = localStorage.getItem("token");
          if (token !== null) {
            let r = await loadUser(token);
            if (r !== NETWORK_ERROR) {
              store.dispatch(loadUserAction(r.data.user));
            }
          }
          alert(r.data.msg);
        } else {
          alert(r.data.msg);
        }
      } else if (r.status === 400) {
        alert("Error updating card details");
      } else {
        alert("Network Error");
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      if (err?.response?.data?.msg) {
        addToast(err.response.data.msg, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }
  };

  const getPasswordError = () => {
    if (newPassword?.length) {
      if (newPassword.match(PASSWORD_REGEX)) {
        return ''
      } else {
        return 'Password must contain at least one uppercase, one lower case, one numeral, one special character and have a minimum of 6 characters.'
      }
    }
    return '';
  }

  const checkIfButtonShouldBeDisabled = () => {
    if (!newPassword || !confirmPassword || !oldPassword ||
      (newPassword && !newPassword.match(PASSWORD_REGEX)) ||
      (newPassword && confirmPassword && newPassword !== confirmPassword)) {
      return true;
    }
    return false;
  }

  const changePasswordFun = async () => {
    try {
      await changePassword(auth.user.email, newPassword, oldPassword);
      addToast("Password updated successfully.", {
        appearance: "success",
        autoDismiss: true,
      });
      setPasswordModalShow(false);
    } catch (err) {
      setLoading(false);
      let msg =
        err && err.response && err.response.data && err.response.data.msg
          ? err.response.data.msg
          : "Error updating password";
      addToast(msg, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
  const cancelSubscription = () => {
    let token = localStorage.getItem("token");

    if (auth && auth.user && auth.user.subPlatform === "web" && auth.user.subscription) {
      Axios({
        method: "post",
        url: `${BASE_URL}payments/${auth.user._id}/unsubscribe/${auth.user.subscription.id}`,
        data: {},
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          alert("Subscription cancelled successfully");
          window.location.href = "/dashboard";
        })
        .catch((error) => {
          console.log(error);
          alert("Subscription cancel failed");
        });
    } else {
      alert("You are not permitted to perform this operation as you are  subscribed from  another platform.")
    }
  };

  const redirectToSubscription = () => {
    if (auth.user.subPlatform !== "web" && auth.user.subPlatform !== "none") {
      alert("You are not permitted to perform this operation as you are  subscribed from  another platform.")
    } else {
      history.push({
        pathname: "/not-subscribed",
        state: auth.user
      });
    }

  }

  const deleteUserAccount = async () => {
    try {
      // eslint-disable-next-line no-restricted-globals
      const isConfirm = confirm("Are you sure you want to delete this account?");

      if (isConfirm) {
        const response = await deleteAccount();
        alert(response.data.msg);
        localStorage.removeItem("token");
        logoutUserAction();
      }
    } catch (error) {
      console.log({ error })
    }
  }

  return (
    <div>
      {loading ? (
        <div class="spinner-border text-primary ml-auto mr-auto" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      ) : (
        <div>
          <div id="top">
            <div>
              <ProfilePic
                image={DemoPic}
                roundedColor="#321124"
                imageWidth="150"
                imageHeight="150"
                roundedSize="13"
              />

              <button className="btn-primary btn m-1">
                Edit Profile Picture
              </button>
            </div>
            <label tabIndex={0} htmlFor="Show User Name and Email">
              {auth.user && auth.user.firstName},{" "}
              {auth.user && auth.user.lastName} : {auth.user && auth.user.email}
            </label>{" "}
          </div>
          <div className="container text-left">
            {" "}
            <h3 aria-label="Page heading : User Information" tabIndex={0}>
              {" "}
              User Information{" "}
            </h3>
            <div className="row">
              <div className="col-md-6 col-lg-6 col-sm-6 md-auto">
                <label htmlFor="firstName" tabIndex={0}>
                  First Name
                </label>
                <TextFieldGroup
                  type="Name"
                  value={auth.user ? auth.user.firstName : ""}
                  placeholder="Please Enter Your First Name. "
                  id="firstName"
                ></TextFieldGroup>
              </div>{" "}
              <div className="col-md-6 col-lg-6 col-sm-6">
                {" "}
                <label htmlFor="lastName" tabIndex={0}>
                  Last Name
                </label>
                <TextFieldGroup
                  id="lastName"
                  type="Name"
                  placeholder="Please Enter Your Last Name."
                  value={auth.user ? auth.user.lastName : ""}
                ></TextFieldGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-6 col-sm-6 md-auto">
                <label htmlFor="userEmail" tabIndex={0}>
                  Email
                </label>
                <TextFieldGroup
                  id="userEmail"
                  type="Name"
                  placeholder="Please Enter Your Email Address. "
                  value={auth.user ? auth.user.email : ""}
                ></TextFieldGroup>
              </div>{" "}
              <div className="col-md-6 col-lg-6 col-sm-6">
                {" "}
                <label htmlFor="userPhone" tabIndex={0}>
                  Phone Number
                </label>
                <TextFieldGroup
                  id="userPhone"
                  type="Name"
                  placeholder="Please Enter Your Phone Number. "
                  value={auth.user ? auth.user.phone : ""}
                ></TextFieldGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-6 col-sm-6 md-auto">
                <label htmlFor="userHeight" tabIndex={0}>
                  Height
                </label>
                <TextFieldGroup
                  id="userHeight"
                  type="number"
                  placeholder="Please Enter Your Height in Inches"
                  value={height}
                  onChange={(e) => {
                    setHeight(Math.abs(e.target.value));
                  }}
                ></TextFieldGroup>
              </div>{" "}
              <div className="col-md-6 col-lg-6 col-sm-6">
                {" "}
                <label htmlFor="userWeight" tabIndex={0}>
                  Weight
                </label>
                <TextFieldGroup
                  id="userWeight"
                  type="number"
                  placeholder="Please Enter Your Weight in Pounds"
                  value={weight}
                  onChange={(e) => {
                    setWeight(Math.abs(e.target.value));
                  }}
                ></TextFieldGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 md-auto text-center pb-3">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setPasswordModalShow(true);
                  }}
                >
                  Change Password
                </button>
              </div>
            </div>
            <Modal
              style={{ overlay: { backgroundColor: "rgba(0,0,0,0.8)" } }}
              id="modalPassword"
              isOpen={passwordModalShow}
              onRequestClose={() => {
                setPasswordModalShow(false);
              }}
            >
              <label htmlFor="oldPassword" tabIndex={0}>
                Enter Old Password
              </label>
              <TextFieldGroup
                id="oldPassword"
                name="oldPassword"
                value={oldPassword}
                onChange={(e) => {
                  setOldPassword(e.target.value);
                }}
                type="password"
              />{" "}
              <label tabIndex={0} htmlFor="newPassword">
                Enter New Password
              </label>
              <TextFieldGroup
                id="newPassword"
                name="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                type="password"
                error={getPasswordError()}
              />{" "}
              <label tabIndex={0} htmlFor="confirmPassword">
                Confirm Password
              </label>
              <TextFieldGroup
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                error={
                  newPassword &&
                    confirmPassword &&
                    newPassword !== confirmPassword
                    ? "password does not match"
                    : ""
                }
              />
              <button
                disabled={checkIfButtonShouldBeDisabled()}
                onClick={() => {
                  changePasswordFun();
                }}
                className="btn btn-primary m-1 "
              >
                Confirm
              </button>
              <button
                className="btn btn-danger m-1"
                onClick={() => {
                  setPasswordModalShow(false);
                }}
              >
                Cancel
              </button>
            </Modal>
            {auth.user.card && auth.user.card.last4 && (
              <div>
                <div>
                  <div
                    className="container"
                    tabIndex={0}
                    aria-label="Card Information Section"
                  >
                    <h5>Card Information</h5>

                    <label tabIndex={0}>
                      Card Number :{" "}
                      {`**** **** **** ${auth.user ? auth.user.card.last4 : ""
                        }`}
                    </label>
                    <br></br>

                    <label tabIndex={0}>
                      Card Expiry Month :{" "}
                      {auth.user ? auth.user.card.exp_month : ""}{" "}
                    </label>
                    <br></br>
                    <label tabIndex={0}>
                      Card Expiry Year :{" "}
                      {auth.user ? auth.user.card.exp_year : ""}{" "}
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 md-auto text-center pb-3">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        setPaymentModalShow(true);
                      }}
                    >
                      Edit Payment Information
                    </button>
                  </div>
                </div>
              </div>
            )}
            <Modal
              style={{ overlay: { backgroundColor: "rgba(0,0,0,0.8)" } }}
              id="modalPassword"
              isOpen={paymentModalShow}
              onRequestClose={() => setPaymentModalShow(false)}
            >
              <label htmlFor="cardNumber" tabIndex={0}>
                Enter Card Number
              </label>
              <TextFieldGroup
                id="cardNumber"
                name="cardNumber"
                value={cardNumber}
                onChange={(e) => setCardNumber(e.target.value)}
                type="text"
              />{" "}
              <label tabIndex={0} htmlFor="expiryMonth">
                Enter Card Expiry Month
              </label>
              <TextFieldGroup
                id="expiryMonth"
                name="expiryMonth"
                type="text"
                value={expiryMonth}
                onChange={(e) => setExpiryMonth(e.target.value)}
              />{" "}
              <label tabIndex={0} htmlFor="expiryYear">
                Enter Card Expiry Year
              </label>
              <TextFieldGroup
                id="expiryYear"
                name="expiryYear"
                type="text"
                value={expiryYear}
                onChange={(e) => setExpiryYear(e.target.value)}
              />
              <button
                className="btn btn-primary m-1 "
                onClick={() => {
                  setLoading(true);
                  updatePaymentInformation(cardNumber, expiryMonth, expiryYear);
                }}
              >
                Confirm
              </button>
              <button
                className="btn btn-danger m-1"
                onClick={() => {
                  setPaymentModalShow(false);
                }}
              >
                Cancel
              </button>
            </Modal>
            <div className="pr-4 pl-4 pt-4">
              <div className="container text-center">
                <button
                  onClick={() => {
                    setTermsModalShow(true);
                  }}
                  className="btn btn-primary btn-block "
                >
                  Terms and Conditions
                </button>

                <Modal
                  style={{ overlay: { backgroundColor: "rgba(0,0,0,0.8)" } }}
                  id="modalPassword"
                  isOpen={termsModalShow}
                  onRequestClose={() => setTermsModalShow(false)}
                >
                  <h4>Terms and Conditions</h4>
                  <TermsConditions />
                  <button
                    className="btn btn-danger"
                    onClick={() => {
                      setTermsModalShow(false);
                    }}
                  >
                    I agrree
                  </button>
                </Modal>
              </div>
              <div className="container text-center mt-1 ">
                <button
                  className="btn btn-primary btn-block "
                  onClick={(e) => {
                    history.push("/privacyPolicy");
                  }}
                >
                  Privacy Policy{" "}
                </button>
              </div>
              <div className="container text-center mt-1 ">
                <button
                  className="btn btn-primary btn-block "
                  onClick={(e) => {
                    history.push("/disclaimer");
                  }}
                >
                  Liability Waiver
                </button>
              </div>
              {auth.user.isTrial && (
                <div className="container text-center mt-1 ">
                  <button
                    className="btn btn-primary btn-block "
                    onClick={redirectToSubscription}
                  >
                    Buy Subscription
                  </button>
                </div>
              )}
              {!auth.user.isTrial && (
                <>
                  <div className="container text-center mt-1">
                    <button
                      className="btn btn-primary btn-block "
                      onClick={cancelSubscription}
                    >
                      Cancel subscription
                    </button>
                  </div>
                  <div className="container text-center mt-1 ">
                    <button className="btn btn-primary btn-block "
                      onClick={redirectToSubscription}
                    >
                      Change Subscription
                    </button>
                  </div>
                </>
              )}

              <div className="container text-center mt-1 ">
                <button
                  className="btn btn-primary btn-block"
                  onClick={deleteUserAccount}
                >
                  Delete Account
                </button>
                <button
                  className="btn btn-primary btn-block  "
                  onClick={(e) => {
                    e.preventDefault();
                    localStorage.removeItem("token");
                    logoutUserAction();
                  }}
                >
                  Sign Out
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUserAction: () => {
      dispatch(logoutUserAction());
    },
  };
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
