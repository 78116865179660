import { UPDATE_NOTE } from '../actions/types'

const initialState = {
	note:
		'Accept responsibility for your life. Know that it is you who will get you where you want to go, no one else. - Les Brown',
}

export default function (state = initialState, action) {
	switch (action.type) {
		case UPDATE_NOTE:
			return {
				...state,
				note: action.payload,
			}
		default:
			return state
	}
}
