import React, { useState, useEffect } from 'react'
import TextFieldGroup from '../common/TextFieldGroup'
import { Formik } from 'formik'
import {
	loadClassroomsService,
	loadStudiosService,
	addEntry,
} from '../../dataServices/Services'
import { useParams } from 'react-router-dom'
import Select from 'react-select'
import { ListGroup } from 'react-bootstrap'
import { descriptionValidation, titleValidation } from '../../constants/Constants'
const yup = require('yup')
const validationSchema = yup.object().shape({
	title: yup.string().required('Title is required'),
	description: yup.string().required('Description is required'),
	daylabel: yup.string(),
})
function AddEnteries({ history }) {
	const { id } = useParams()
	const [loading, setLoading] = useState(false)
	const [classroomlist, setClassRoomList] = useState([])
	const [selectedClass, setSelectedClass] = useState([])
	const [studiolist, setStudioList] = useState([])
	const [selectedStudio, setSelectedStudio] = useState([])
	const [addeddays, setAddeddays] = useState([])
	const [valid, setValid] = useState(true)
	const [classRooms, setClassrooms] = useState([])
	const [studios, setStudios] = useState([])

	const fetchClassrooms = async () => {
		const r = await loadClassroomsService()

		let l = []
		for (const element of r.data.classrooms) {
			l.push({
				value: element._id,
				label: element.title,
			})
		}

		if (r.status === 200) {
			setClassRoomList(l)
		} else {
			alert('Network Error')
		}
	}
	const fetchStudios = async () => {
		const r = await loadStudiosService()
		console.log(r)
		let l = []
		for (const element of r.data.studios) {
			l.push({
				value: element._id,
				label: element.title,
			})
		}

		if (r.status === 200) {
			setStudioList(l)
		} else {
			alert('Network Error')
		}
	}

	useEffect(() => {
		fetchClassrooms()
		fetchStudios()
	}, [])

	const classhandler = (e) => {
		setSelectedClass(Array.isArray(e) ? e.map((x) => x.value) : [])
		setClassrooms(
			Array.isArray(e) ? e.map((x) => ({ id: x.value, label: x.label })) : []
		)
	}
	const studiohandler = (e) => {
		setSelectedStudio(Array.isArray(e) ? e.map((x) => x.value) : [])
		setStudios(
			Array.isArray(e) ? e.map((x) => ({ id: x.value, label: x.label })) : []
		)
	}

	return (
		<div>
			<div className="container">
				<div>
					<div className="col-md-6 m-auto"></div>{' '}
					<Formik
						validationSchema={validationSchema}
						initialValues={{ title: '', description: '', daylabel: '' }}
						onSubmit={async (values) => {
							setLoading(true)
							const days = [...addeddays]
							const { title, description } = values

							let r = await addEntry(id, {
								heading: title,
								enteryDescription: description,
								days,
							})
							console.log(r)
							if (r.status === 200) {
								alert(r.data.message)
								setLoading(false)
								values.title = ''
								values.description = ''
								setAddeddays([])
								setSelectedStudio([])
								setSelectedClass([])
								setClassrooms([])
								setStudios([])
							} else {
								setLoading(false)
								alert('Entery not added successfully')
							}
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
						}) => (
							<div>
								{loading ? (
									<div class="spinner-border text-primary" role="status">
										<span class="sr-only">Loading...</span>
									</div>
								) : (
									<div>
										<label tabIndex={0} htmlFor="title">
											Title
										</label>
										<TextFieldGroup
											name="title"
											id="title"
											type="text"
											value={values.title}
											onChange={handleChange}
											error={titleValidation(touched, errors)}
										/>
										<label tabIndex={0} htmlFor="description">
											Description
										</label>
										<textarea
											name="description"
											id="description"
											type="textArea"
											value={values.description}
											onChange={handleChange}
											className="form-control"
											style={
												descriptionValidation(touched, errors)
											}
										></textarea>
										<p style={{ fontSize: '13px', color: 'red' }}>
											{touched.description && errors.description}
										</p>

										<div>
											<label tabIndex={0} htmlFor="Daylabel">
												Day Label
											</label>

											<TextFieldGroup
												name="Day Label"
												id="Daylabel"
												type="text"
												value={values.daylabel}
												onChange={handleChange('daylabel')}
											/>

											<Select
												tabIndex={0}
												label="Select Classrooms"
												className="dropdown"
												placeholder="Select Class Rooms"
												value={classroomlist.filter((obj) =>
													selectedClass.includes(obj.value)
												)} // set selected values
												options={classroomlist} // set list of the data
												onChange={classhandler} // assign onChange function
												isMulti
												isClearable
											/>
											<br></br>
											<Select
												tabIndex={0}
												label="Select Studios"
												className="dropdown"
												placeholder="Select Class Rooms"
												value={studiolist.filter((obj) =>
													selectedStudio.includes(obj.value)
												)} // set selected values
												options={studiolist} // set list of the data
												onChange={studiohandler} // assign onChange function
												isMulti
												isClearable
											/>
											{!valid && (
												<p
													style={{
														color: 'red',
														textAlign: 'left',
														marginTop: 10,
													}}
												>
													Day Label is required
												</p>
											)}
											<button
												type="submit"
												onClick={(e) => {
													if (values.daylabel === '') {
														setValid(false)
													} else {
														let ar = []
														ar = [...addeddays]
														setValid(true)
														ar.push({
															label: values.daylabel,
															attachedClassRoom: classRooms,
															attachedStudio: studios,
														})
														setAddeddays(ar)
														values.daylabel = ''
														setSelectedClass([])
														setSelectedStudio([])
														setClassrooms([])
														setStudios([])
													}
												}}
												className="btn btn-primary m-2"
											>
												Add Day In Entery
											</button>
											{addeddays.length > 0 && (
												<div>
													<label tabIndex={0}>Added Days in List</label>
													{addeddays.map((days) => (
														<ListGroup tabIndex={0} horizontal>
															<ListGroup.Item tabIndex={0}>
																{'Day label:' + days.label}
															</ListGroup.Item>
															<ListGroup.Item tabIndex={0}>
																{'Added Classes:' +
																	days.attachedClassRoom.map((cl) =>
																		classroomlist
																			.filter((c) => cl.id === c.value)
																			.map((ti) => ti.label + ',')
																	)}
															</ListGroup.Item>
															<ListGroup.Item tabIndex={0}>
																{'Added Studios:' +
																	days.attachedStudio.map((st) =>
																		studiolist
																			.filter((s) => s.value === st.id)
																			.map((ti) => ti.label + ',')
																	)}
															</ListGroup.Item>
														</ListGroup>
													))}
												</div>
											)}
										</div>

										<button
											type="submit"
											onClick={(e) => {
												handleSubmit()
											}}
											className="btn btn-primary m-2"
										>
											Create Entery
										</button>
										<button
											className="btn btn-primary m-2"
											onClick={(e) => {
												history.push(`/single-plan/${id}`)
											}}
										>
											Cancel{' '}
										</button>
									</div>
								)}
							</div>
						)}
					</Formik>
				</div>
			</div>
		</div>
	)
}

export default AddEnteries
