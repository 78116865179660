import { GET_FAQ, GET_FAQ_CATEGORY } from "../actions/types";
const initialState = {
    faq: [],
    faqCategorie: {},
    faqQuestion: []
};
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_FAQ:
            return {
                ...state,
                faq: action.payload,
            };
        case GET_FAQ_CATEGORY:
            return {
                ...state,
                faqCategorie: action.payload.faqCategory,
                faqQuestion: action.payload.faqQuestionData
            }
        default:
            return state;
    }
}
