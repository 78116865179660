import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
const PrivateRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>{ 
      if(!auth?.user?.isTrainer) {
      if(auth.isAuth === true && auth?.user?.isSubscribed) {
        return <Component {...props} /> 
      } else if(auth.isAuth === true && !auth?.user?.isSubscribed) {
        return <Redirect to="/not-subscribed" />
      } else {
        return <Redirect to="/login" />
      }
      // auth.isAuth === true ? <Component {...props} /> : <Redirect to="/login" />
    } else {
      return <Component {...props} />
    }
  }
    }
  />
);

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(PrivateRoute);
