import { LOAD_USER_SUCCESS, LOGOUT_USER } from '../../constants/Constants'
import { SIGNUP_SUCCESS, LOGIN_SUCCESS } from './types'

export const signupUser = (user) => (dispatch) => {
	dispatch({
		type: SIGNUP_SUCCESS,
		payload: user,
	})
}
export const loginUser = (user) => (dispatch) => {
	dispatch({
		type: LOGIN_SUCCESS,
		payload: user,
	})
}
export const loadUserAction = (user) => (dispatch) => {
	dispatch({
		type: LOAD_USER_SUCCESS,
		payload: user,
	})
}
export const logoutUserAction = () => (dispatch) => {
	dispatch({
		type: LOGOUT_USER,
		payload: {},
	})
}
