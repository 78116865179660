import React, { useState, useCallback, useEffect } from 'react';
import { Form, Input, Select, Checkbox, DatePicker } from 'antd';
import moment from 'moment';
import { useLocation, useHistory } from "react-router-dom";
import { updatePushNotication } from "../../dataServices/Services";
import { useToasts } from 'react-toast-notifications';
import { selectPlatform, requiredField, requiredLength, placeHolder, status, errorConstants, platformNames, successConstants } from "../../constants/Constants";
import "../../assets/css/pushNotification.css";

const EditPushNotification = () => {
    const location = useLocation();
    const { state } = location;
    const { title, message, platform, dateAndTime, _id } = state.record;
    const [showDatePicker, setShowDatePicker] = useState(dateAndTime ? true : false);
    const history = useHistory();
    const { addToast } = useToasts();


    const disabledDate = current => current && current.valueOf() < moment(dateAndTime).valueOf();

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.keyCode === 13) {
                e.preventDefault();
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const onFinish = async (values) => {
        try {
            let requestData = {};

            const changedFields = Object.keys(values).filter(key => {
                if (key === 'platforms' && Array.isArray(values[key]) && Array.isArray(initialValues[key])) {
                    return values[key].sort().toString() !== initialValues[key].sort().toString();
                }
                return values[key] !== initialValues[key];
            });

            requestData = changedFields.reduce((obj, key) => {
                obj[key] = values[key];
                return obj;
            }, {});
            if (changedFields.includes('scheduleChecked')) {
                if (!values['scheduleChecked']) {
                    requestData['dateAndTime'] = moment.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                    delete requestData['scheduleChecked'];
                }
            }
            await updatePushNotication(requestData, _id);
            addToast(successConstants.pushNotification.update, {
                appearance: status.success,
                autoDismiss: true,
            });
            history.goBack();
        } catch (error) {
            addToast(errorConstants.pushNotificationError, {
                appearance: status.error,
                autoDismiss: true,
            });
        }
    };

    const handleCheckboxChange = useCallback((e) => {
        setShowDatePicker(e.target.checked);
    }, []);

    const formItemLayout = {
        labelCol: {
            span: 24,
            style: { textAlign: 'center' },
        },
        wrapperCol: {
            span: 24,
        },
    };

    const initialValues = {
        title: title,
        message: message,
        platforms: platform.length > 1 ? platformNames.both : platform,
        dateAndTime: dateAndTime ? moment(dateAndTime) : null,
        scheduleChecked: dateAndTime ? true : false
    };

    return (
        <>
            <h2 tabIndex={0}>Edit Push Notification</h2>
            <Form onFinish={onFinish} initialValues={initialValues} layout="vertical" className='container' {...formItemLayout}>
                <Form.Item label={<span tabIndex={0}>Title</span>} name="title"
                    rules={[{ required: true, message: requiredField.inputTitleRequired },
                    { max: 50, message: requiredLength.titleLength }]}
                >
                    <Input placeholder={placeHolder.inputTitle} tabIndex={0} />
                </Form.Item>

                <Form.Item label={<span tabIndex={0}>Message</span>} name="message"
                    rules={[{ required: true, message: requiredField.inputMessageRequired },
                    { max: 1000, message: requiredLength.messageLength }
                    ]}>
                    <Input.TextArea placeholder={placeHolder.inputMessage} tabIndex={0} />
                </Form.Item>

                <Form.Item label={<span tabIndex={0}>Platform</span>} name="platforms" rules={[{ required: true, message: requiredField.inputPlatformRequired }]}>
                    <Select maxCount={1} placeholder={placeHolder.inputPlatform} tabIndex={0} options={selectPlatform} />
                </Form.Item>
                <Form.Item valuePropName="checked" name="scheduleChecked">
                    <Checkbox onClick={handleCheckboxChange} tabIndex={0} onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                            e.preventDefault();
                            e.target.focus();
                            e.target.click();
                        }
                    }}>Scheduled Date and Time</Checkbox>
                </Form.Item>


                {showDatePicker && (
                    <Form.Item label={<span tabIndex={0}>Date</span>} name="dateAndTime" rules={[{ required: true, message: requiredField.inputDateRequired }]}>
                        <DatePicker showTime placeholder={placeHolder.inputDate} tabIndex={0} disabledDate={disabledDate} />
                    </Form.Item>
                )}
                <Form.Item>
                    <button type="submit" className="btn btn-primary" tabIndex={0}>
                        Update Push Notification
                    </button>

                    <button type="button" className="btn btn-primary m-2" onClick={() => history.goBack()} tabIndex={0} onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                            e.preventDefault();
                            e.target.focus();
                            e.target.click();
                        }
                    }}>
                        Cancel
                    </button>
                </Form.Item>
            </Form>
        </>
    );
};

export default EditPushNotification;
