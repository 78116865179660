/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import '../../assets/css/classrooms.css'
import { deleteFaqQuestion, loadSingleFaqCategory, reorderFaqQuestion } from '../../dataServices/Services'
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons"
import { getFaqCategory } from '../../redux/actions/getFaq'

function FaqCategory({ getFaqCategory, faqCategories, faqQuestions, auth }) {

    const history = useHistory()
    const [ loading, setLoading ] = useState(false)
    const [ , setResources ] = useState([])
    const { addToast } = useToasts()
    const { id } = useParams()

    const toastRef = useRef();

    const fetchFaqCategoryDetails = async () => {
        setLoading(true)
        let token = localStorage.getItem('token')
        if (token !== null) {
            let r = await loadSingleFaqCategory(id)
            if (r && r.data) {
                getFaqCategory(r.data)
                setResources(r.data)
                setLoading(false)
            }
        } else {
            setLoading(false)
            addToast('Faq not loaded', { appearance: 'error' })
        }
    }
    const deleteFaq = async (questionId) => {
        try {
            const response = await deleteFaqQuestion(questionId)
            alert(response.data.success)
            fetchFaqCategoryDetails()
        } catch (error) {
            addToast(<span ref={toastRef}
                aria-label={error.message}
                tabIndex={0}>{error.message}</span>, {
                appearance: 'error',
                autoDismiss: true,
            })
            toastRef.current.focus()
        }
    }

    useEffect(() => {
        fetchFaqCategoryDetails()
    }, [])

    const reorderFaqQuestions = async (currentOrder, newOrder) => {
        const data = {
            currentOrder: faqQuestions[ currentOrder ].order,
            newOrder: faqQuestions[ newOrder ].order
        }
        await reorderFaqQuestion(data, id).then((data) => {
            fetchFaqCategoryDetails()
        })
    }

    return (
        <div>

            <div className="m-4 p-4">
                <h1 tabIndex={0} aria-label={faqCategories.title} style={{ display: 'block' }}>
                    {faqCategories.title}
                </h1>{' '}
                <p tabIndex={0} aria-label={faqCategories.description}>
                    {faqCategories.description}
                </p>
                {auth.user.isTrainer && (
                    <button
                        onClick={(e) => {
                            history.push(`${id}/add-question`)
                        }}
                        className="btn btn-primary"
                    >
                        Add new FAQ
                    </button>
                )}
                <button
                    onClick={(e) => {
                        history.push(`/faq`)
                    }}
                    className="btn btn-primary"
                    style={{ marginLeft: "10px" }}
                >
                    Go back to FAQ category list
                </button>
                {faqQuestions && faqQuestions.length ? (
                    <div className="p-4 m-2">
                        <div
                            tabIndex={0}
                            aria-label="List of Faq"
                            className="list-group"
                        >
                            {loading && (
                                <div
                                    class="spinner-border text-primary ml-auto mr-auto"
                                    role="status"
                                >
                                    <span class="sr-only">Loading...</span>
                                </div>
                            )}
                            {!loading &&
                                faqQuestions &&
                                !auth.user.isTrainer &&
                                faqQuestions.map((c) => {
                                    return (
                                        <button
                                            key={c._id}
                                            onClick={() => {
                                                history.push({
                                                    pathname: `/faq-category/${id}/question`,
                                                    state: { question: c.question, answer: c.answer }
                                                })
                                            }}
                                            className="btn btn-primary m-2"
                                            style={{
                                                marginTop: '5px',
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                            }}
                                            tabIndex={0}
                                        >
                                            {c.question}
                                        </button>
                                    )
                                })}
                            {faqQuestions
                                &&
                                auth.user.isTrainer &&
                                faqQuestions
                                    .map((c, index) => {
                                        return (
                                            <div
                                                className="text-align-center"
                                                style={{ display: 'flex' }}
                                            >
                                                {' '}
                                                <button
                                                    key={c._id}
                                                    onClick={() => {
                                                        history.push({
                                                            pathname: `/faq-category/${id}/question`,
                                                            state: { question: c.question, answer: c.answer }
                                                        })
                                                    }}
                                                    className="btn btn-primary m-2"
                                                    aria-label={`${c.question}-position ${index + 1}`}
                                                    style={{
                                                        marginTop: '5px',
                                                        width: '90%',
                                                    }}
                                                    tabIndex={0}
                                                >
                                                    {c.question}
                                                </button>
                                                {auth.user.isTrainer && (
                                                    <>
                                                        {index !== 0 && (
                                                            <button
                                                                aria-label="Move Up The Faq"
                                                                className="btn btn-danger m-2"
                                                                onClick={() => reorderFaqQuestions(index, index - 1)}
                                                            >
                                                                <ArrowUpOutlined />
                                                            </button>
                                                        )}
                                                        {index !== (faqQuestions.length - 1) && (
                                                            <button
                                                                aria-label="Move Down The Faq"
                                                                className="btn btn-danger m-2"
                                                                onClick={() => reorderFaqQuestions(index, index + 1)}
                                                            >
                                                                <ArrowDownOutlined />
                                                            </button>
                                                        )}
                                                        <button
                                                            aria-label="delete Faq"
                                                            className="btn btn-danger m-2"
                                                            onClick={() => deleteFaq(c._id)}
                                                        >
                                                            <i className="fa fa-trash"></i>
                                                        </button>
                                                    </>
                                                )}
                                                {auth.user.isTrainer && (
                                                    <button
                                                        aria-label="Edit Faq"
                                                        className="btn btn-danger m-2"
                                                        onClick={(e) => {
                                                            history.push(`/faq-category/${id}/edit-question/${c._id}`)
                                                        }}
                                                    >
                                                        <i className="fa fa-pencil"></i>
                                                    </button>
                                                )}
                                            </div>
                                        )
                                    })}
                        </div>
                    </div>
                ) : ""}

            </div>
        </div>
    )
}
const mapDispatchToProps = (dispatch) => {
    return {
        getFaqCategory: (faq) => {
            dispatch(getFaqCategory(faq))
        },
    }
}
const mapStateToProps = (state) => {
    return {
        faqCategories: state.faq.faqCategorie,
        faqQuestions: state.faq.faqQuestion,
        auth: state.auth,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(FaqCategory)
