import React, { useState, useEffect, useCallback } from "react";
import { Tooltip, Pagination, Empty } from 'antd';
import { useHistory } from "react-router-dom";
import "../../assets/css/pushNotification.css";
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from "antd";
import { useToasts } from "react-toast-notifications";

import {
    loadAllpushNotification,
    deletePushNotication
} from "../../dataServices/Services";
import { deleteBody, errorConstants, status, successConstants } from "../../constants/Constants";
import Routes from "../../constants/routes";

const PushNotification = () => {
    const history = useHistory();
    const [dataSource, setDataSource] = useState({
        scheduleData: [], scheduleCount: 0
    });
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const { addToast } = useToasts();
    const { confirm } = Modal;

    const fetchData = useCallback(async () => {
        try {
            const res = await loadAllpushNotification(page, pageSize);
            const { scheduleData, scheduleCount } = res.data;
            setDataSource({
                scheduleData: scheduleData, scheduleCount: scheduleCount
            });
        } catch (error) {
            addToast(errorConstants.pushNotificationError, {
                appearance: status.error,
                autoDismiss: true,
            });
        }
    }, [page, pageSize, addToast]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (dataSource.scheduleCount !== 0) {
                fetchData();
            }
        }, 20000);

        return () => clearInterval(interval);
    }, [dataSource.scheduleCount, fetchData]);

    const handlePageChange = (newPage, newPageSize) => {
        setPage(newPage);
        setPageSize(newPageSize);
    };

    const navigateToData = (record, type) => {
        history.push({
            pathname: `/${type}-push-notification`,
            state: { record: record }
        });
    };

    const showDeleteConfirm = (id) => {
        confirm({
            title: deleteBody.title,
            icon: <ExclamationCircleFilled />,
            content: deleteBody.message,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deletePushNotication(id).then(() => {
                    fetchData()
                    addToast(successConstants.pushNotification.delete, {
                        appearance: status.success,
                        autoDismiss: true,
                    });
                });
            },
        });
    };


    return (
        <div className="p-4 m-2">
            <h1 tabIndex={0}>Push Notifications</h1>
            <button
                onClick={() => history.push(Routes.addPushNotification)}
                className="btn btn-primary"
            >
                Add Push Notification
            </button>

                <h4 tabIndex={0} className="text-left">Schedule</h4>
                {dataSource.scheduleCount !== 0 ? (
                    <>
                        <div className="dataContent">
                            {dataSource.scheduleData.map(item => (
                                <div key={item._id} className="titleBlock">
                                    <Tooltip title={item.title}>
                                        <button
                                            onClick={() => navigateToData(item, 'view')}
                                            className="btn btn-primary m-2 titleButton"
                                        >
                                            {item.title}
                                        </button>
                                    </Tooltip>
                                    {!item.isSent &&
                                        <>
                                            <button aria-label="delete pushNotification" className="btn btn-danger m-2"
                                                onClick={() => showDeleteConfirm(item._id)}>
                                                <i className="fa fa-trash" aria-hidden="true"></i>
                                            </button>
                                            <button aria-label="Edit pushNotification" className="btn btn-danger m-2"
                                                onClick={() => navigateToData(item, 'edit')}>
                                                <i className="fa fa-pencil"></i>
                                            </button>
                                        </>
                                    }
                                </div>
                            ))}
                        </div>
                        <div className="rev-pagination-wrap">
                            <Pagination
                                tabIndex={0}
                                current={page}
                                pageSize={pageSize}
                                total={dataSource.scheduleCount}
                                showSizeChanger={true}
                                onChange={(newPage, newPageSize) => handlePageChange(newPage, newPageSize)}
                                onShowSizeChange={(newPage, newPageSize) => handlePageChange(newPage, newPageSize)}
                                pageSizeOptions={["10", "20", "30", "50", "100"]}
                            />
                        </div>
                    </>
                ) : (<>
                    <Empty />
                </>)}
        </div>

    );
};

export default PushNotification;