import React, { useState, useEffect, useCallback } from "react";
import { Tooltip, Pagination, Empty } from 'antd';
import { useHistory } from "react-router-dom";
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from "antd";
import { useToasts } from "react-toast-notifications";
import Routes from "../../constants/routes"
import "../../assets/css/credits.css";
import { connect } from 'react-redux';

import {
    deleteCredits,
    loadAllCredits
} from "../../dataServices/Services";
import { deleteBody, successConstants, status, errorConstants } from "../../constants/Constants";

const Credits = ({ auth }) => {
    const history = useHistory();
    const [dataSource, setDataSource] = useState({
        data: [], count: 0
    });
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const { addToast } = useToasts();
    const { confirm } = Modal;

    const fetchData = useCallback(async () => {
        try {
            const res = await loadAllCredits(page, pageSize);
            const { data, count } = res.data;
            setDataSource({
                data: data, count: count
            });
        } catch (error) {
            addToast(errorConstants.creditError, {
                appearance: status.error,
                autoDismiss: true,
            });
        }
    }, [page, pageSize, addToast]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);


    const handlePageChange = (newPage, newPageSize) => {
        setPage(newPage);
        setPageSize(newPageSize);
    };

    const navigateToData = (record, type) => {
        history.push({
            pathname: `/${type}-credits`,
            state: { record: record }
        });
    };

    const showDeleteConfirm = (id) => {
        confirm({
            title: deleteBody.title,
            icon: <ExclamationCircleFilled />,
            content: deleteBody.message,
            okText: <span aria-label="Delete Confirmation. Are you sure. yes" tabIndex={0}>
                Yes
            </span>,
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                await deleteCredits(id);
                fetchData();
                const toastRef = React.createRef();
            addToast(
            <span
                ref={toastRef}
                aria-live="polite"
            >
                {successConstants.credit.delete}
            </span>,
            {
                appearance: 'success',
                autoDismiss: true,
                onDismiss: () => {
                    toastRef.current.focus();
                }
            }
        );
            },
        });
    };


    return (
        <div className="p-4 m-2">
            <h1 tabIndex={0}>List of Credits</h1>
            {auth.user.isTrainer &&
                <button
                    onClick={() => history.push(Routes.addCredits)}
                    className="btn btn-primary mb-2"
                >
                    Add New Credit
                </button>}
            <div>
                {dataSource.count !== 0 ? (
                    <>
                        <div className="dataContent">
                            {dataSource.data.map(item => (
                                <div key={item._id} className="titleBlock">
                                    <Tooltip title={item.title}>
                                        <button
                                            onClick={() => navigateToData(item, 'view')}
                                            className="btn btn-primary m-2 titleButton"
                                        >
                                            {item.title}
                                        </button>
                                    </Tooltip>
                                    {!item.isSent && auth.user.isTrainer &&
                                        <>
                                            <button aria-label="delete pushNotification" className="btn btn-danger m-2"
                                                onClick={() => showDeleteConfirm(item._id)}>
                                                <i className="fa fa-trash" aria-hidden="true"></i>
                                            </button>
                                            <button aria-label="Edit pushNotification" className="btn btn-danger m-2"
                                                onClick={() => navigateToData(item, 'edit')}>
                                                <i className="fa fa-pencil"></i>
                                            </button>
                                        </>
                                    }
                                </div>
                            ))}
                        </div>
                        <div>

                        <Pagination
                            tabIndex={0}
                            current={page}
                            pageSize={pageSize}
                            total={dataSource.count}
                            showSizeChanger={true}
                            onChange={(newPage, newPageSize) => handlePageChange(newPage, newPageSize)}
                            onShowSizeChange={(newPage, newPageSize) => handlePageChange(newPage, newPageSize)}
                            pageSizeOptions={["10", "20", "30", "50", "100"]}
                            />
                            </div>
                    </>
                ) : (<>
                    <Empty />
                </>)}
            </div>
        </div>

    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
})
export default connect(mapStateToProps)(Credits)