/* eslint-disable react-hooks/exhaustive-deps */
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons"
import React, { useEffect, useRef, useState } from 'react'
import FilterResults from 'react-filter-search'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import '../../assets/css/classrooms.css'
import { deleteHealthResource, getHealthAndFitnessService, reorderHealthAndFitness } from '../../dataServices/Services'
import { getHealthAndFitness } from '../../redux/actions/healthAndFitnessActions'

function HealthAndFitness({ getResource, resource, auth }) {

    const history = useHistory()
    const [ searchValue, setsearchValue ] = useState('')
    const [ searchData, setSearchData ] = useState([])
    const [ loading, setLoading ] = useState(false)
    const [ resources, setResources ] = useState([])
    const [ categories, setCategories ] = useState([])
    const { addToast } = useToasts()
    const toastRef = useRef();


    const fetchHealthResource = async () => {
        setLoading(true)
        let token = localStorage.getItem('token')
        if (token !== null) {
            let r = await getHealthAndFitnessService()

            if (r.data && r.data.healthResources) {
                getResource(r.data.healthResources)
                setResources(r.data.healthResources)
                setLoading(false)
            }
        } else {
            setLoading(false)
            addToast('Resources not loaded', { appearance: 'error' })
        }
    }

    const deleteResource = async (resourceId, index) => {
        try {
            const response = await deleteHealthResource(resourceId)
            alert(response.data.success)
            fetchHealthResource()
        } catch (error) {
            addToast(<span ref={toastRef}
                aria-label={error.message}
                tabIndex={0}>{error.message}</span>, {
                appearance: 'error',
                autoDismiss: true,
            })
            toastRef.current.focus()
        }
    }

    const getCategory = () => {
        let cat = []
        for (const element of resources) {
            if (resources.length > 0) {
                if (cat.indexOf(element.category.toLowerCase()) === -1) {
                    cat.push(element.category.toLowerCase())
                }
            }
        }

        setCategories(cat)
    }
    useEffect(() => {
        fetchHealthResource()
    }, [])

    useEffect(() => {
        getCategory()
    }, [ resources ])

    const reorderHealthResource = async (currentOrder, newOrder) => {
        const data = {
            currentOrder: resource.healthResource[ currentOrder ].order,
            newOrder: resource.healthResource[ newOrder ].order
        }
        await reorderHealthAndFitness(data).then((data) => {
            fetchHealthResource()
        })
    }

    return (
        <div>
            {' '}
            <div className="top-bar">
                {' '}
                <div className="top-bar-search">
                    {' '}
                    <input
                        type="text"
                        name=""
                        id=""
                        value={searchValue}
                        placeholder="Search"
                        aria-label="Search Health resource name"
                        onChange={(e) => {
                            setsearchValue(e.target.value)
                            setSearchData(resource.healthResource)
                        }}
                    />{' '}
                    <select
                        name=""
                        id=""
                        aria-label="Search health resource by category "
                        value={searchValue}
                        onChange={(e) => {
                            setsearchValue(e.target.value)
                            setSearchData(resource.healthResource)
                        }}
                        style={{ height: 30 }}
                    >
                        {categories &&
                            categories.map((cat) => <option value={cat}>{cat}</option>)}
                    </select>
                    {searchData && (
                        <FilterResults
                            value={searchValue}
                            data={searchData}
                            renderResults={(results) => (
                                <div>
                                    {results.map((el) => (
                                        <div
                                            onClick={(e) => {
                                                history.push(`/health-resource/${el._id}`)
                                                setSearchData([])
                                            }}
                                            onKeyPress={(e) => {
                                                history.push(`/health-resource/${el._id}`)
                                                setSearchData([])
                                            }}
                                        >
                                            <div>
                                                <p
                                                    tabIndex={0}
                                                    style={{
                                                        border: '1px solid ',
                                                        textAlign: 'center',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    {el.title} : Category {el.category}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        />
                    )}
                </div>
            </div>
            <div className="m-4 p-4">
                <h1 tabIndex={0} style={{ display: 'block' }}>
                    Welcome into Health and fitness guides
                </h1>{' '}
                <p tabIndex={0}>
                    Here you can read supplemental information to help you learn and grow in your health and fitness.
                </p>
                {auth.user.isTrainer && (
                    <button
                        onClick={(e) => {
                            history.push('/add-health-and-fitness')
                        }}
                        className="btn btn-primary"
                    >
                        Add New Health and Fitness Guide
                    </button>
                )}
                <h2 tabIndex={0}>Health And Fitness Guides</h2>
                {resource.healthResource.length ? (
                    <div className="p-4 m-2">
                        <div
                            tabIndex={0}
                            aria-label="List of Health and Fitness Guides"
                            className="list-group"
                        >
                            {loading && (
                                <div
                                    class="spinner-border text-primary ml-auto mr-auto"
                                    role="status"
                                >
                                    <span class="sr-only">Loading...</span>
                                </div>
                            )}
                            {!loading &&
                                resource &&
                                !auth.user.isTrainer &&
                                resource.healthResource.map((c) => {
                                    return (
                                        <button
                                            key={c._id}
                                            onClick={() => {
                                                history.push(`/health-resource/${c._id}`)
                                            }}
                                            className="btn btn-primary m-2"
                                            style={{
                                                marginTop: '5px',
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                            }}
                                            tabIndex={0}
                                        >
                                            {c.title}
                                        </button>
                                    )
                                })}
                            {resource &&
                                auth.user.isTrainer &&
                                resource.healthResource.map((c, index) => {
                                    return (
                                        <div

                                            className="text-align-center"
                                            style={{ display: 'flex' }}
                                        >
                                            {' '}
                                            <button
                                                key={c._id}
                                                id={`newFavorite-${index}`}
                                                onClick={() => {
                                                    history.push(`/health-resource/${c._id}`)
                                                }}
                                                className="btn btn-primary m-2"
                                                aria-label={`${c.title}-position ${index + 1}`}
                                                style={{
                                                    marginTop: '5px',
                                                    width: '90%',
                                                }}
                                                tabIndex={0}
                                            >
                                                {c.title}
                                            </button>
                                            {auth.user.isTrainer && (
                                                <>
                                                    {index !== 0 && (
                                                        <button
                                                            aria-label="Move Up The Health and fitness Guide"
                                                            className="btn btn-danger m-2"
                                                            onClick={() => reorderHealthResource(index, index - 1)}
                                                        >
                                                            <ArrowUpOutlined />
                                                        </button>
                                                    )}
                                                    {index !== (resource.healthResource.length - 1) && (
                                                        <button
                                                            aria-label="Move Down The Health and fitness guide"
                                                            className="btn btn-danger m-2"
                                                            onClick={() => reorderHealthResource(index, index + 1)}
                                                        >
                                                            <ArrowDownOutlined />
                                                        </button>
                                                    )}
                                                    <button
                                                        aria-label="delete Health and Fitness guide"
                                                        className="btn btn-danger m-2"
                                                        onClick={() => deleteResource(c._id, index)}
                                                    >
                                                        <i className="fa fa-trash"></i>
                                                    </button>
                                                </>
                                            )}
                                            {auth.user.isTrainer && (
                                                <button
                                                    aria-label="Edit Health and Fitness guide"
                                                    className="btn btn-danger m-2"
                                                    onClick={(e) => {
                                                        history.push(`/editHealthResource/${c._id}`)
                                                    }}
                                                >
                                                    <i className="fa fa-pencil"></i>
                                                </button>
                                            )}
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                ) : ""}

            </div>
        </div>
    )
}
const mapDispatchToProps = (dispatch) => {
    return {
        getResource: (healthResource) => {
            dispatch(getHealthAndFitness(healthResource))
        },
    }
}
const mapStateToProps = (state) => ({
    resource: state.healthResource,
    auth: state.auth,
})
export default connect(mapStateToProps, mapDispatchToProps)(HealthAndFitness)
