import { GET_RECOMMENDED_EQUIPMENTS } from "../actions/types";
const initialState = {
    equipments: [],
};
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_RECOMMENDED_EQUIPMENTS:
            return {
                ...state,
                equipments: action.payload,
            };
        default:
            return state;
    }
}
