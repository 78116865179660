import React, { useMemo, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import "../../assets/css/dropzone.css";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  width: "80%",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export default function DropzoneInput(props) {
  const { onDropComplete } = props;
  const onDrop = useCallback((acceptedFiles) => {
    onDropComplete(acceptedFiles[0]);
  }, []);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({ onDrop });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const files = acceptedFiles.map((file) => (
    <p className="nospace" key={file.path}>
      {file.path} - {file.size} bytes
    </p>
  ));

  return (
    <div className="dropzone_container">
      <div {...getRootProps({ style })}>
        <input tabIndex={0} {...getInputProps()} />
        <p
          tabIndex={0}
          aria-label="Click to Drag 'n' drop your audio file here..."
        >
          Drag 'n' drop your audio file here...
        </p>
      </div>
      <aside>{files}</aside>
    </div>
  );
}
