const Routes = {
  pushNotification: '/push-notification',
  addPushNotification: '/add-push-notification',
  editPushNotification: '/edit-push-notification',
  viewPushNotification: '/view-push-notification',
  addCredits: '/add-credits',
  viewCredits: '/view-credits',
  editCredits: '/edit-credits',
  credits: '/credits'
}

export default Routes;