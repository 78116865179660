/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react'
import { getPlans } from '../../redux/actions/planActions'
import { connect } from 'react-redux'
import FilterResults from 'react-filter-search'
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";


import { getAllPlans, deleteplanByid, reorderFitnessPlans } from '../../dataServices/Services'
function FitnessPlan({ history, plans, getPlans, auth }) {
	const [ loading, setLoading ] = useState(false)
	const [ searchValue, setsearchValue ] = useState('')
	const [ searchData, setSearchData ] = useState([])
	const fetchplans = useCallback(async () => {
		setLoading(true)
		const r = await getAllPlans()
		if (r.status === 200) {
			setLoading(false)

			getPlans(r.data.plans)
		} else {
			setLoading(false)
			alert('Network Error')
		}
	},[getPlans])
	useEffect(() => {
		fetchplans()
	}, [])


	const reorderPlans = async (currentOrder, newOrder) => {

		const data = {
			currentOrder:plans.plans[currentOrder].order,
			newOrder:plans.plans[newOrder].order,
		}
		await reorderFitnessPlans(data).then((data)=>{
			fetchplans()
		})
	}

	return (
		<div>
			<div className="top-bar">
				{' '}
				<div className="top-bar-search">
					{' '}
					<input
						type="text"
						name=""
						id=""
						placeholder="Search"
						aria-label="Search Fitness Plan by Title name"
						value={searchValue}
						onChange={(e) => {
							setsearchValue(e.target.value)
							setSearchData(plans.plans)
						}}
					/>
					{searchData && (
						<FilterResults
							value={searchValue}
							data={searchData}
							renderResults={(results) => (
								<div>
									{results.map((el) => (
										<div
											onClick={(e) => {
												history.push(`/single-plan/${el._id}`)
												setSearchData([])
											}}
											onKeyPress={(e) => {
												history.push(`/single-plan/${el._id}`)
												setSearchData([])
											}}
										>
											<div>
												<p
													tabIndex={0}
													style={{
														border: '1px solid ',
														textAlign: 'center',
														cursor: 'pointer',
													}}
												>
													{el.title} : Category {el.category}
												</p>
											</div>
										</div>
									))}
								</div>
							)}
						/>
					)}
				</div>
			</div>
				<div className="m-4 p-4">
					<h1 tabIndex={0} style={{ display: 'block' }} aria-label="Classrooms">
						Welcome to Fitness Plans
					</h1>{' '}
					<p tabIndex={0}>
						Each fitness plan is designed with a primary objective in mind, and
						around that main goal is built a well rounded routine. Use these
						plans as a guide on your fitness journey
					</p>
					{auth.user.isTrainer && (
						<button
							onClick={(e) => {
								e.preventDefault()
								history.push('/add-fitness-plan')
							}}
							className="btn btn-primary"
						>
							Add Fitness Plan
						</button>
					)}
					<h2 tabIndex={0}>List of Fitness Plans</h2>
					{loading && (
							<div
								class="spinner-border text-primary ml-auto mr-auto"
								role="status"
							>
								<span class="sr-only">Loading...</span>
							</div>
						)}
					<div>
						{plans &&
							plans.plans.map((plan, index) => (
								<div
									className="text-align-center"
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									{' '}
									<button
										onClick={() => {
											history.push(`/single-plan/${plan._id}`)
										}}
										className="btn btn-primary m-2"
										aria-label={`${plan.title}-position ${index+1}`}
										style={{
											marginTop: '5px',
											width: '90%',
										}}
										tabIndex={0}
									>
										{plan.title}
									</button>
									{auth.user.isTrainer && (
										<>{index !== 0 && (
											<button
												aria-label="Move Up The Plan"
												className="btn btn-danger m-2"
												onClick={() => reorderPlans(index, index - 1)}
											>
												<ArrowUpOutlined />
											</button>
										)}
											{index !== (plans.plans.length - 1) && (
												<button
													aria-label="Move Down The Plan"
													className="btn btn-danger m-2"
													onClick={() => reorderPlans(index, index + 1)}
												>
													<ArrowDownOutlined />
												</button>
											)}
											<button
												aria-label="delete plan"
												className="btn btn-danger m-2"
												onClick={async (e) => {
													setLoading(true)
													e.preventDefault()
													const r = await deleteplanByid(plan._id)

													if (r.status === 200) {
														setLoading(false)
														alert(r.data.message)
														fetchplans()
													} else {
														alert('Network Error')
													}
												}}
											>
												<i className="fa fa-trash"></i>
											</button>
										</>
									)}
									{auth.user.isTrainer && (
										<button
											aria-label="Edit plan"
											className="btn btn-danger m-2"
											onClick={(e) => {
												history.push(`/edit-plan/${plan._id}`)
											}}
										>
											<i className="fa fa-pencil"></i>
										</button>
									)}
								</div>
							))}
						{plans && plans.plans.length === 0 && (
							<div style={{ marginTop: 30, fontSize: 30 }} tabIndex={0}>
								No Fitness Plan Found
							</div>
						)}
					</div>
				</div>
		</div>
	)
}

const mapDispatchToProps = (dispatch) => {
	return {
		getPlans: (plans) => {
			dispatch(getPlans(plans))
		},
	}
}

const mapStateToProps = (state) => {
	return {
		plans: state.plans,
		auth: state.auth,
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FitnessPlan)
